import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, timeout, map, tap } from 'rxjs/operators';
import { url } from '../../config/http';
import { UsersService } from './users.service';

@Injectable({
  providedIn: 'root',
})
export class DoctorsService {
  private lightOnFirst = new BehaviorSubject<any>(false);
  public lightOn: Observable<boolean>;

  constructor(private httpClient: HttpClient) {
    this.lightOn = this.lightOnFirst.asObservable();
  }

  setLightOn(v: boolean) {
    this.lightOnFirst.next(v);
  }

  getDoctors() {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      observe: 'response' as 'response',
    };

    return this.httpClient.get(url + `/doctors?skip=0&limit=100`, options);
  }

  getAvailableDoctor(doctorId) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      observe: 'response' as 'response',
    };

    return this.httpClient.get(
      url + `/doctors?doctorUserId=${doctorId}`,
      options
    );
  }

  getDoctor(doctorId) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      observe: 'response' as 'response',
    };

    return this.httpClient.get(url + `/doctors/${doctorId}`, options);
  }

  getCandidate(doctorId) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      observe: 'response' as 'response',
    };

    return this.httpClient.get(
      url + `/doctors/get-candidate/${doctorId}`,
      options
    );
  }

  getDoctorOfAdmin(id) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      observe: 'response' as 'response',
    };

    return this.httpClient.get(url + `/doctors/admin/${id}`, options);
  }

  makeMeAvailable(status) {
    console.log('url', url);
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-auth': localStorage.getItem('xauth'),
      }),
      observe: 'response' as 'response',
    };
    return this.httpClient.get(
      url + `/doctors/change-status?status=${status}`,
      options
    );
  }

  getFilteredDoctors(
    prof = '',
    gen = '',
    lang = '',
    online = '',
    skip = 0,
    limit = 50
  ) {
    console.log(prof, gen, lang, online, skip, limit);

    let a = `?${prof && 'professionId='}${prof || ''}${
      gen.length ? '&gender=' : ''
    }${gen.length ? gen : ''}${lang && '&consultationLanguages='}${lang || ''}${
      online && '&status='
    }${online || ''}`;

    let skiplimit;
    if (a) {
      skiplimit = `&skip=${skip}&limit=${limit}`;
    } else {
      skiplimit = `?skip=${skip}&limit=${limit}`;
    }
    return this.httpClient.get(url + `/doctors/${a}${skiplimit}`);
  }

  getPatients() {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      observe: 'response' as 'response',
    };

    return this.httpClient.get(url + `/doctors/my-patients`, options);
  }

  getPatient(id) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      observe: 'response' as 'response',
    };

    return this.httpClient.get(url + `/users/${id}`, options);
  }

  changeStatus(body) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      observe: 'response' as 'response',
    };

    return this.httpClient.post(url + `/doctors/change-role`, body, options);
  }

  getAdminDoctors() {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-auth': localStorage.getItem('xauth'),
      }),
      observe: 'response' as 'response',
    };
    return this.httpClient.get(url + '/doctors/admin', options);
  }

  deleteDoctor(id) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-auth': localStorage.getItem('xauth'),
      }),
      observe: 'response' as 'response',
    };
    return this.httpClient.delete(url + '/doctors/' + id, options);
  }

  updatePatient(id, body) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-auth': localStorage.getItem('xauth'),
      }),
      observe: 'response' as 'response',
    };
    return this.httpClient.patch(
      url + '/doctors/update-my-patient/' + id,
      body,
      options
    );
  }

  updateBodyTemperature(body) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-auth': localStorage.getItem('xauth'),
      }),
      observe: 'response' as 'response',
    };

    return this.httpClient.post(url + '/temperature', body, options);
  }

  drTotalEarnings(id) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-auth': localStorage.getItem('xauth'),
      }),
      observe: 'response' as 'response',
    };
    return this.httpClient.get(url + '/doctors/earnings/' + id, options);
  }

  showDoctorsOn(id, body) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-auth': localStorage.getItem('xauth'),
      }),
      observe: 'response' as 'response',
    };
    return this.httpClient.patch(url + '/doctors/' + id, body, options);
  }

  getFeaturedDoctors() {
    return this.httpClient.get(url + '/doctors/featured-doctors');
  }

  getDovizKuru() {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-auth': localStorage.getItem('xauth'),
      }),
      observe: 'response' as 'response',
    };
    return this.httpClient.get(url + '/appointments/currency', options);
  }

  getDoctorsByPagination(skip, limit) {
    return this.httpClient.get(url + `/doctors?skip=${skip}&limit=${limit}`);
  }
}
