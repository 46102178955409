import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { LanguageService } from 'src/app/services/language.service';
import { SendMailService } from 'src/app/services/send-mail.service';

@Component({
  selector: 'app-become-provider',
  templateUrl: './become-provider.component.html',
  styleUrls: ['./become-provider.component.css'],
})
export class BecomeProviderComponent implements OnInit {
  allLangs;
  langcode;
  langSelect;
  listShow: boolean;
  localLang: string = localStorage.getItem('language');

  constructor(
    private fb: FormBuilder,
    private sendMail: SendMailService,
    private toastr: ToastrService,
    private languageSer: LanguageService,
    public translate: TranslateService
  ) {
    this.languageSer.getSelectedLanguage().subscribe((res) => {
      this.langSelect = res;
      console.log(this.langSelect);
    });
  }

  localLanguage = this.localLang || window.navigator.language.split('-')[0]; // exp: tr-TR
  seletOptionLanguage: string;
  onChangeLanguage(event: string) {
    this.translate.use(event);
    this.languageSer.putSelectedLanguage(event);
  }

  form: FormGroup = this.fb.group({
    firstName: ['', Validators.required],
    lastName: ['', Validators.required],
    phoneNumber: ['', Validators.required],
    mail: ['', Validators.required],
    country: ['', Validators.required],
    npi: ['', Validators.required],
    providerType: ['', Validators.required],
  });

  ngOnInit(): void {
    window.scrollTo(0, 0);
    this.seletOptionLanguage = this.localLanguage;
  }

  submit() {
    const content = `
                  Full Name: ${this.form.controls['firstName'].value} ${this.form.controls['lastName'].value}'\n'
                  Email: ${this.form.controls['mail'].value}'\n'
                  Phone Number: ${this.form.controls['phoneNumber'].value} '\n'
                  Country: ${this.form.controls['country'].value}'\n'
                  NPI: ${this.form.controls['npi'].value}
                  Provider Type: ${this.form.controls['providerType'].value}
                  `;

    // const content = `
    //               Full Name: ${this.form.value?.firstName} ${this.form.controls['lastName'].value}'\n'
    //               Email: ${this.form.value?.mail}'\n'
    //               Phone Number: ${this.form.value?.phoneNumber} '\n'
    //               Country: ${this.form.value?.country}'\n'
    //               NPI: ${this.form.value?.npi}'\n'
    //               Provider Type: ${this.form.value?.providerType}
    //               `
    const sendedForm = {
      bodyContent: content,
      contentType: 'text',
      emailToSend: 'info@veradoctors.com',
      subject: 'Become a Provider Form',
    };

    this.sendMail.requestAMeeting(sendedForm).subscribe(
      (res) => {
        this.toastr.success('', 'Request Mail Sent');
      },
      (err) =>
        this.toastr.error('please try again later', 'something went wrong')
    );
  }
}
