// src/app/auth/role-guard.service.ts
import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { AuthService } from './auth.service';
import jwt_decode from 'jwt-decode';

@Injectable()
export class RoleGuardService implements CanActivate {
  constructor(public auth: AuthService, public router: Router) {}
  canActivate(route: ActivatedRouteSnapshot): boolean {
    let expectedRole = '';

    //const expectedRole = route.data.expectedRole;

    let token = '',
      tokenPayload = '';

    if (localStorage.getItem('xauth')) {
      token = localStorage.getItem('xauth');
      // tokenPayload = jwt_decode(token);

      let expectedRoleArray = route.data;
      expectedRoleArray = expectedRoleArray.expectedRole;

      //  console.log("expectedRoleArray", expectedRoleArray, expectedRoleArray.expectedRole)

      for (let i = 0; i < expectedRoleArray.length; i++) {
        if (expectedRoleArray[i] == localStorage.getItem('role')) {
          // console.log("Roles Matched");
          expectedRole = localStorage.getItem('role');
        }
      }

      tokenPayload = localStorage.getItem('role');
    }

    // console.log("role-guard.service.module"+token)
    // console.log('tokenPayload',tokenPayload)
    // console.log("expectedRole", expectedRole)
    //   console.log('expectedRole',expectedRole)
    if (
      (token == '' && !this.auth.isAuthenticated()) ||
      tokenPayload !== expectedRole
    ) {
      this.router.navigate(['/login-page']);
      return false;
    }

    return true;
  }
}
