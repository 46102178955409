import { environment } from 'src/environments/environment';

// export const url = environment.BE_TEST
export const url = environment.developmentUrl; // doc-call ar-ge

export const frontendUrl = environment.siteUrl;

export const agoraUrl = environment.agoraUrl;

export const yahUrl = environment.yahUrl;
