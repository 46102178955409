import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-what-we-treat',
  templateUrl: './what-we-treat.component.html',
  styleUrls: ['./what-we-treat.component.css']
})
export class WhatWeTreatComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    window.scrollTo(0, 0);
  }

}
