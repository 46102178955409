import { Component, OnInit } from '@angular/core';
import { LanguageService } from 'src/app/services/language.service';

@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.css']
})
export class PrivacyComponent implements OnInit {

  constructor(private languageSer: LanguageService) { }
  languageSelect;
  ngOnInit(): void {
    window.scrollTo(0, 0);
    this.languageSer.getSelectedLanguage().subscribe(res =>{
      this.languageSelect = res
    })
  }

}
