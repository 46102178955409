import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { BehaviorSubject, Observable, ReplaySubject, Subject } from 'rxjs';
import { url } from '../../config/http';

@Injectable({
  providedIn: 'root',
})
export class MessagingService {
  messageLast = new BehaviorSubject(null);
  allMessages = new BehaviorSubject(null);

  constructor(
    private afMessaging: AngularFireMessaging,
    private http: HttpClient
  ) {
    this.initialize();
    this.getMessagesFromDB().subscribe((res) => {
      this.allMessages.next(res.body);
    });
  }

  initialize() {
    // this.askForPermission();
    this.receiveMessage();
  }

  askForPermission() {
    this.afMessaging.requestPermission.subscribe(
      (res) => {
        this.afMessaging.requestToken.subscribe(
          (res) => {
            this.sendFcmToken({ fcmToken: res }).subscribe(
              (res) => {
                console.log('Permission granted');
              },
              (error) => {
                console.log('Permission denied', error);
              }
            );
          },
          (err) => console.log(err)
        );
      },
      (error) => {
        console.error(error);
      }
    );
  }
  askForNewPermission(oldToken: string) {
    this.afMessaging.requestPermission.subscribe(
      (res) => {
        this.afMessaging.requestToken.subscribe(
          (res) => {
            if (res !== oldToken) {
              this.sendFcmToken({ fcmToken: res }).subscribe(
                (res) => {
                  console.log('Permission granted');
                },
                (error) => {
                  console.log('Permission denied', error);
                }
              );
            }
          },
          (err) => console.log(err)
        );
      },
      (error) => {
        console.error(error);
      }
    );
  }

  receiveMessage() {
    this.afMessaging.messages.subscribe((payload) => {
      this.messageLast.next(payload);
    });
    this.getMessagesFromDB().subscribe((res) => {
      this.allMessages.next(res.body);
    });
  }

  sendFcmToken(body) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      observe: 'response' as 'response',
    };

    return this.http.post(url + '/utilities/register-fcm-token', body, options);
  }

  getMessagesFromDB() {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      observe: 'response' as 'response',
    };
    return this.http.get(url + '/notifications', options);
  }

  sendMessages(title, body, id, role) {
    const lastPart = (id && `email=${id}`) || (role && `userRole=${role}`);
    const options = {
      headers: new HttpHeaders({
        'x-auth': localStorage.getItem('xauth'),
      }),
      observe: 'response' as 'response',
    };
    return this.http.get(
      url +
        `/utilities/send-notification?title=${title}&body=${body}&${lastPart}`,
      options
    );
  }

  deleteMessage(id) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      observe: 'response' as 'response',
    };
    return this.http.delete(url + `/notifications/${id}`, options);
  }

  patchMessage(id, body) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      observe: 'response' as 'response',
    };
    return this.http.patch(url + `/notifications/${id}`, body, options);
  }
}
