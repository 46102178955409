<div class="container">
  <div class="row">
    <div class="card mt-5 w-100">
      <div class="card-body px-5 col-md-12 col-sm-12 w-100" style="color: #000; text-align: justify;">
        <div class="card-title text-center">
          <h2 style="font-weight: 900;">{{"Work In Progress" | translate}}</h2>
          <h5 style="font-weight: 900;">{{"Page under construction" | translate}}</h5>
        </div>
        <div class="row d-flex justify-content-center">
          <img src="assets/header-blog-photos/33 - User  Interface.gif" alt="">
        </div>

      </div>
    </div>
  </div>
</div>
