import { Injectable } from '@angular/core';
import { url } from '../../config/http'
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class LocationService {

  constructor(
    private httpClient: HttpClient,

  ) { }

  getCountries() {
    return this.httpClient.get(url + '/countries');
  }

  getStates(id) {
    return this.httpClient.get(url + `/countries/${id}/states`);
  }

  getCities(country, state) {
    return this.httpClient.get(url + `/countries/${country}/states/${state}/cities`);
  }

  getHospitals(){
    return this.httpClient.get(url + `/hospitals`);

  }
}
