import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pharmacies-labs',
  templateUrl: './pharmacies-labs.component.html',
  styleUrls: ['./pharmacies-labs.component.css']
})
export class PharmaciesLabsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    window.scrollTo(0, 0);
  }

}
