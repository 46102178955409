import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

import { ObesityFormService } from 'src/app/services/obesity-form.service';

@Component({
  selector: 'app-obesity-form',
  templateUrl: './obesity-form.component.html',
  styleUrls: ['./obesity-form.component.css'],
})
export class ObesityFormComponent implements OnInit {
  obesityForm: FormGroup;
  localLang = localStorage.getItem('language');
  submitted: boolean = false;
  isUser = localStorage.getItem('user');
  userRole = localStorage.getItem('role');
  isCheckBoxValid: boolean = true;
  allModules: boolean = false;
  checkBoxModuleList = [];

  modules = [
    {
      key: 'allModules',
      label: 'Obesity System ALL MODULES PACKAGE I want to buy',
    },
    {
      key: 'm1SetResistanceAndFactors',
      label: 'Module 1, Identifying Resistance and Factors for Obesity',
    },
    { key: 'm2HealthScan', label: 'Module 2, Obesity Health Screening' },
    {
      key: 'm3Trainings',
      label: 'Module 3, Training and Consciousness Change for Obesity',
    },
    {
      key: 'm4Individual',
      label:
        'Module 4, Individual or Group Sessions and Subconscious Change for Obesity',
    },
    { key: 'm5Detox', label: 'Module 5, Detox Treatments for Obesity' },
    {
      key: 'm5Special',
      label: 'Module 5, Special Diet Treatments for Obesity',
    },
    { key: 'm5Drug', label: 'Module 5, Drug Treatments for Obesity' },
    { key: 'm5Needle', label: 'Module 5, Needle Treatments for Obesity' },
    { key: 'm5Herbal', label: 'Module 5, Herbal Solutions for Obesity' },
    { key: 'm5GastricBalloon', label: 'Module 5, Gastric Balloon for Obesity' },
    { key: 'm5GastricBotox', label: 'Module 5, Gastric Botox for Obesity' },
    { key: 'm5Hypnosis', label: 'Module 5, Hypnosis for Obesity' },
    { key: 'm5Acupuncture', label: 'Module 5, Acupuncture for Obesity' },
    {
      key: 'm5GastricSleeveSurgery',
      label: 'Module 5, Obesity Surgeries (Gastric Sleeve)',
    },
    {
      key: 'm5GastricBypass',
      label: 'Module 5, Obesity Surgeries (Gastric Bypass)',
    },
    {
      key: 'm5PostSurgeryPersonal',
      label:
        'Module 6, Personal Adaptation and Follow-up after Treatments or Surgeries',
    },
    {
      key: 'm5PostSurgeryEnvironmental',
      label:
        'Module 6, Environmental Change Applications after Treatments or Surgeries',
    },
  ];

  constructor(
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private translate: TranslateService,
    private router: Router,
    private obesity: ObesityFormService
  ) {}

  ngOnInit(): void {
    this.obesityForm = this.formBuilder.group({
      height: [
        '',
        [
          Validators.required,
          Validators.min(1),
          Validators.max(300),
          Validators.pattern(/^\d+([\.,]\d{1})?$/),
        ],
      ],
      weight: [
        '',
        [
          Validators.required,
          Validators.min(1),
          Validators.max(500),
          Validators.pattern(/^\d+([\.,]\d{1})?$/),
        ],
      ],
      modules: this.formBuilder.group({ ...this.createModulesControls() }),
    });
  }

  createModulesControls() {
    const controls = {};
    this.modules.forEach((moduleItem) => {
      controls[moduleItem.key] = [false];
    });
    return controls;
  }

  onCheckboxChange(value: string) {
    this.isCheckBoxValid = true;

    if (value === 'allModules') {
      this.allModules = !this.allModules;
      this.modules.forEach((moduleItem) => {
        this.obesityForm
          .get('modules')
          .get(moduleItem.key)
          .setValue(this.allModules);
      });

      if (this.allModules) {
        this.checkBoxModuleList = this.modules.map((module) => module.key);
      } else {
        this.checkBoxModuleList = [];
      }
    } else {
      const indexValue = this.checkBoxModuleList.indexOf(value);

      if (indexValue !== -1) {
        this.checkBoxModuleList.splice(indexValue, 1);
      } else {
        this.checkBoxModuleList.push(value);
      }
    }
  }

  onSubmit() {
    if (this.isUser) {
      if (this.userRole !== 'user') {
        return this.toastr.error(this.translate.instant('only patient form'));
      }
      this.submitted = true;

      if (!this.obesityForm.valid || !this.checkBoxModuleList.length) {
        if (!this.checkBoxModuleList.length) {
          this.isCheckBoxValid = false;
        }
      } else {
        if (this.obesityForm.valid) {
          const formRequestBody = this.obesityForm.value;
          delete formRequestBody.modules.allModules;
          this.sendForm(formRequestBody);
        } else {
          this.router.navigate(['login-page']);
          this.toastr.info(this.translate.instant('please login first'));
        }
      }
    }
  }

  sendForm(body) {
    this.obesity.sendObesityForm(body).subscribe(
      (res) => {
        this.toastr.success('', this.translate.instant('form submitted'));
        this.submitted = false;
        this.obesityForm.reset();
        location.reload();
      },
      () => this.toastr.error('', this.translate.instant('form dont submit'))
    );
  }
}
