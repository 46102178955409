import { Injectable } from '@angular/core';
import { url } from '../../config/http';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  private langs = new BehaviorSubject<string>(
    window.navigator.language.split('-')[0]
  );

  constructor(private httpClient: HttpClient) {}

  getLanguages() {
    return this.httpClient.get(url + '/languages');
  }

  putSelectedLanguage(lang: string) {
    this.langs.next(lang);
    localStorage.setItem('language', lang);
  }

  getSelectedLanguage(): Observable<string> {
    return this.langs.asObservable();
  }

  getConsultationLanguages() {
    return this.httpClient.get(url + '/languages/consultation');
  }
}
