<header class="thyHeader mb-6">
  <div class="container-fluid thyHeaderWrapper d-flex flex-column align-items-start justify-content-center">
    <div class="thyHeaderImg" style="z-index: 1;">
      <img class="w-25" src="/assets/img/img/thy/turkish-airline.png" alt="">
    </div>
    <div class="thyHeaderTextWrapper" style="z-index: 1;">
      <h2 id="orange" class="monts">{{"IT DOESN'T MATTER WHERE YOU ARE." | translate}}</h2>
      <h2 id="soft" class="monts">{{"YOUR DOCTOR IS ONLY A CLICK AWAY." | translate}}</h2>
    </div>
  </div>
</header>

<div class="d-flex justify-content-center w-100">
  <section class="container mb-6 w-100">
    <div class="d-flex flex-column">
      <h3 class="monts" style="margin-bottom: 12px !important">{{"VERADoctors Proudly announces our partnership with Turkish Airlines" | translate}}</h3>
      <p class="monts" style="margin-bottom: 12px !important">{{"As we re-imagine how we live, work and travel in an increasingly remote/hybrid environment, our health stays as the main determinant of our freedom. If you live with a chronic condition, connecting with your own healthcare provider from anywhere in the world may change how you think about travel. Depending on where we’re traveling, seeking healthcare can pose certain challenges." | translate}}</p>
      <div style="width: 100%;" class="videoModal position-relative">
        <img class="w-100 h-100" src="/assets/img/img/thy/videoModalImg.jpeg" alt="">
        <button type="button" class="modalBtn position-absolute" data-bs-toggle="modal" data-bs-target="#videoModal">
          <i class="fa fa-play-circle fa-solid fa-circle-play fa-8x "></i>
        </button>

      </div>
    </div>
  </section>
</div>
<section class="pageMiddle mb-6">
  <div class="d-flex flex-column gap-3 align-items-center">
    <span class="text-center mb-3">{{"Easy access to quality care options, wherever you travel" | translate}}</span>
    <button>Turkish Airlines</button>
  </div>
</section>

<div class="d-flex justify-content-center">
  <section class="container d-flex flex-column align-items-center gap-5 mb-6 veraInformation">
    <div class="d-flex veraInformationWrapper gap-5 mb-4">
      <div class="qualityCare d-flex flex-column gap-4 w-50 p-1 p-lg-3">
        <h2 style="margin-bottom: 12px !important;">Quality Care</h2>
        <div style="height: 350px" class="qualityCareImg w-100">
          <img style="object-fit: cover" class="w-100 h-100" src="/assets/img/img/thy/qualityCare.jpeg" alt="">
        </div>
        <p style="margin-top: 12px !important">{{"Care optimization through virtual visits with Specialists and specialized nurses 24/7 and local bedside assistance and care available at your request." | translate}}</p>
        <p>{{"Increase in the scope of services without a
          language barrier and uncertainty of
          quality of care." | translate}}</p>
      </div>
      <div class="simpleAccess d-flex flex-column gap-4 w-50 p-1 p-lg-3">
        <h2 style="margin-bottom: 12px !important;">Simple Access</h2>
        <div style="height: 350px" class="simpleAccessImg w-100">
          <img style="object-fit: cover" class="w-100 h-100" src="/assets/img/img/thy/simpleAccess.jpeg" alt="">
        </div>
        <p style="margin-top: 12px !important">{{"Worldwide acces to healthcare
          proffesionals in all of your destinations." | translate}}</p>
        <p>{{"Care coordination with a local physician or hospital. Prescriptions and Medical History is relayed while diagnostics are managed." | translate}}</p>
      </div>
    </div>
    <h1 class="m-auto">{{"A TRAVEL EXPERIENCE
      EXACTLY HOW YOU WANT
      IT, WITHOUT WORRYING
      ABOUT YOUR HEALTH" | translate}}</h1>

  </section>
</div>


<div class="modal fade" id="videoModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-xl">
    <div class="modal-content videoModalContent">
      <div class="modal-header videoModalHeader">
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <iframe width="100%" height="500" src="https://www.youtube.com/embed/lpVQY_xlERc" title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen></iframe>
      </div>
    </div>
  </div>
</div>
