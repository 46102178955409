<div class="container">
  <div class="row">
    <div class="card mt-5 w-100">
      <div class="card-body px-5 col-md-12 col-sm-12 w-100" style="color: #000; text-align: justify;">
        <div class="card-title text-center">
          <h4 style="font-weight: 900;">{{"Our Partners" | translate}}</h4>
        </div>
        <div class="row w-100 d-flex justify-content-around align-middle">

          <img src="assets/partnerlogo/PsiTechLab.png" alt="psitechlab_logo" style="height: 100px;" class="my-auto">
          <img src="assets/partnerlogo/SavRo_Logo_2020.webp" alt="savro_logo" class="my-auto">
        </div>

      </div>
    </div>
  </div>
</div>
