<div class="container mb-5">
  <div class="how-it-works mx-2">
    <h1 class="text-center hiw-title">
      {{"WHAT do we Provide?" | translate}}
    </h1>
    <div class="gy-md-0 gy-4 hiw-bar d-flex justify-content-around flex-wrap">
      <div class="col-sm-6 col-md-4 text-center">
        <div class="card img-wrapper">
          <img src="/assets/img/we-provide-icons/light.png" alt="">
        </div>
        <h3 class="font-weight-bold">{{"Urgent Care" | translate}}</h3>
        <h3>{{"Anyone can be treated for routine health issues 24/7" | translate}}</h3>
      </div>
      <div class="col-sm-6 col-md-4 text-center">
        <div class="card img-wrapper">
          <img src="/assets/img/we-provide-icons/virus.png" alt="">
        </div>
        <h3 class="font-weight-bold">{{"COVID-19" | translate}}</h3>
        <h3>{{"Get tested, ask questions, and return to work safely" | translate}}</h3>
      </div>
      <div class="col-sm-6 col-md-4 text-center">
        <div class="card img-wrapper">
          <img src="/assets/img/we-provide-icons/strescope.png" alt="">
        </div>
        <h3 class="font-weight-bold">{{"Primary Care" | translate}}</h3>
        <h3>{{"Get the primary services you or your team need" | translate}}</h3>
      </div>

      <div class="col-sm-6 col-md-4 text-center">
        <div class="card img-wrapper">
          <img src="/assets/img/we-provide-icons/femail.png" alt="">
        </div>
        <h3 class="font-weight-bold">{{"Women’s Health" | translate}}</h3>
        <h3>{{"A simple way to privately discuss female health 24/7" | translate}}</h3>
      </div>
      <div class="col-sm-6 col-md-4 text-center">
        <div class="card img-wrapper">
          <img src="/assets/img/we-provide-icons/male.png" alt="">
        </div>
        <h3 class="font-weight-bold">{{"Men’s Health" | translate}}</h3>
        <h3>{{"An easy way to discretely discuss male health 24/7" | translate}}</h3>
      </div>
      <div class="col-sm-6 col-md-4 text-center">
        <div class="card img-wrapper">
          <img src="/assets/img/we-provide-icons/communication.png" alt="">
        </div>
        <h3 class="font-weight-bold">{{"Talk Therapy" | translate}}</h3>
        <h3>{{"Adults can speak with a licensed therapist" | translate}}</h3>
      </div>

      <div class="col-sm-6 col-md-4 text-center">
        <div class="card img-wrapper">
          <img src="/assets/img/we-provide-icons/dialog.png" alt="">
        </div>
        <h3 class="font-weight-bold">{{"Teen Therapy" | translate}}</h3>
        <h3>{{"Ages 10 -17 can speak with a licensed therapist" | translate}}</h3>
      </div>
      <div class="col-sm-6 col-md-4 text-center">
        <div class="card img-wrapper">
          <img src="/assets/img/we-provide-icons/brain.png" alt="">
        </div>
        <h3 class="font-weight-bold">{{"Psychiatry" | translate}}</h3>
        <h3>{{"Talk therapy, medication, and more" | translate}}</h3>
      </div>
      <div class="col-sm-6 col-md-4 text-center">
        <div class="card img-wrapper">
          <img src="/assets/img/we-provide-icons/home.png" alt="">
        </div>
        <h3 class="font-weight-bold">{{"In-Home Nurse" | translate}}</h3>
        <h3>{{"Request to be cared for in your own home" | translate}}</h3>
      </div>

      <div class="col-sm-6 col-md-4 text-center">
        <div class="card img-wrapper">
          <img src="/assets/img/we-provide-icons/bed.png" alt="">
        </div>
        <h3 class="font-weight-bold">{{"Bedside Care" | translate}}</h3>
        <h3>{{"Virtual bedside care 24/7" | translate}}</h3>
      </div>
      <div class="col-sm-6 col-md-4 text-center">
        <div class="card img-wrapper">
          <img src="/assets/img/we-provide-icons/smile-hand.png" alt="">
        </div>
        <h3 class="font-weight-bold">{{"Wellness" | translate}}</h3>
        <h3>{{"Talk to someone about your overall wellness" | translate}}</h3>
      </div>
      <div class="col-sm-6 col-md-4 text-center">
        <div class="card img-wrapper">
          <img src="/assets/img/we-provide-icons/heart.png" alt="">
        </div>
        <h3 class="font-weight-bold">{{"Monitoring" | translate}}</h3>
        <h3>{{"Monitor your health through in-home indicators" | translate}}</h3>
      </div>

  </div>
</div>
