<div class="container">
  <div class="card my-5 mx-2 w-100">
    <div class="card-header text-center">
      <h1>{{"Hybrid Healthcare You Can Provide" | translate}}</h1>
      <h2>{{"Pharmacies and Labs" | translate}}</h2>
    </div>
    <div class="card-body px-5 col-md-12 col-sm-12 w-100 text-center" style="color: #000; text-align: justify;">
      <p class="desc">
        {{"With VERADoctors, you can push your patient care services to the next level." | translate}}
      </p>
      <div class="row mt-3">
        <div class="col-md-6 col-sm-12">
          <img src="/assets/images/hospital-and-clinics/surgeon_using_digita_uLOaI.jpg" alt="">
        </div>
        <div class="col-md-6 col-sm-12" style="align-items: center;">
          <div class="blue text-center">
            <h3>
              {{"Pharmacies" | translate}}
            </h3>
          </div>
          <div class="text-left mt-3">
            <ul>
              <li>{{"Manage medications and monitor ongoing patient care" | translate}}</li>
              <li>{{"Quickly provide refills and get in contact with doctors and specialists within our community" | translate}}</li>
              <li>{{"Widen the scope of care you can provide your patients in-person and online" | translate}}</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-md-6 col-sm-12">
          <img src="/assets/images/hospital-and-clinics/my_daughter_isn_t_af_q5MAV.jpg" alt="">
        </div>
        <div class="col-md-6 col-sm-12" style="align-items: center;">
          <div class="blue text-center">
            <h3>
              {{"Laboratories" | translate}}
            </h3>
          </div>
          <div class="text-left mt-3">
            <ul>
              <li>{{"Access patient lab results in real-time" | translate}} </li>
              <li>{{"Share lab results with patients, specialists, and specialized nurses all on one platform" | translate}}</li>
              <li>{{"Provide specialty lab tests for patients across the globe" | translate}}</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="row mt-5 mb-3 desc">
        <h3 style="color: #4365ef;"><i class="fa fa-th-list" aria-hidden="true"></i> {{"Features" | translate}}</h3>
        <div class="row">
          <div class="col-sm-12 col-md-6 text-left">
            <ul>
              <li>{{"Diagnostic Capabilities" | translate}}</li>
              <li>{{"Incorporating file sharing" | translate}}</li>
              <li>{{"SMS Messaging" | translate}}</li>
              <li>{{"Whiteboard" | translate}}</li>
              <li>{{"On-Demand Recording" | translate}}</li>
            </ul>
          </div>
          <div class="col-sm-12 col-md-6 text-left">
            <ul>
              <li>{{"API to the Hospitals electronic health records" | translate}}</li>
              <li>{{"Patient portal with direct access to labs" | translate}}</li>
              <li>{{"Pharmacies and others allow seamless integration throughout the care spectrum" | translate}}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
