<div class="container">
  <div class="card my-5 mx-2 w-100">
    <div class="card-header text-center">
      <h1>{{"Hybrid Healthcare With A Purpose" | translate}}</h1>
      <h2>{{"Hospitals and Physicians Around the Globe" | translate}}</h2>
    </div>
    <div class="card-body px-5 col-md-12 col-sm-12 w-100 text-center" style="color: #000; text-align: justify;">
      <p class="desc">
        {{"Wheather you’re a part of a large hospital in a big city or a sole physician working in remote areas, VERADoctors has you covered." | translate}}
      </p>
      <div class="row mt-3">
        <div class="col-md-6 col-sm-12">
          <img src="/assets/images/hospital-and-clinics/surgeon_using_digita_uLOaI.jpg" alt="">
        </div>
        <div class="col-md-6 col-sm-12" style="align-items: center;">
          <div class="blue text-center">
            <h3>
              {{"Hospitals" | translate}}
            </h3>
          </div>
          <div class="text-left mt-3">
            <ul>
              <li>{{"Optimize your care through virtual (bedside) visits with specialists and specialized nurses" | translate}}</li>
              <li>{{"Your patients will enjoy a shorter length of stay in hospitals" | translate}}</li>
              <li>{{"You will be able to increase the scope of your services without creating physical infrastructure in remote hospitals" | translate}}</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-md-6 col-sm-12">
          <img src="/assets/images/hospital-and-clinics/my_daughter_isn_t_af_q5MAV.jpg" alt="">
        </div>
        <div class="col-md-6 col-sm-12" style="align-items: center;">
          <div class="blue text-center">
            <h3>
              {{"Physicians" | translate}}
            </h3>
          </div>
          <div class="text-left mt-3">
            <ul>
              <li>{{"Improved diagnosis and better treatment management with quick and timely follow-ups after your patients are discharged" | translate}}</li>
              <li>{{"You’ll have access to comprehensive, digitized data of patients both offline and in real time" | translate}}</li>
              <li>{{"Continuing education or training through webinars and video coaching periodically" | translate}}</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-md-6 col-sm-12">
          <img src="/assets/images/hospital-and-clinics/image.jpg" alt="">
        </div>
        <div class="col-md-6 col-sm-12" style="align-items: center;">
          <div class="blue text-center">
            <h3>
              {{"Possibilities" | translate}}
            </h3>
          </div>
          <div class="text-left mt-3">
            <div style="padding-left: 25px;">
              <p>{{"with VERADoctors" | translate}};</p>
            </div>
            <ul>
              <li>{{"Push the limits of traditional telehealth across the globe with our integrated software" | translate}}</li>
              <li>{{"Open medical opportunity in underserved areas" | translate}}</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="row mt-5 mb-3 desc">
        <h3 style="color: #4365ef;"><i class="fa fa-th-list" aria-hidden="true"></i> {{"Features" | translate}}</h3>
        <div class="row">
          <div class="col-sm-12 col-md-6 text-left">
            <ul>
              <li>{{"Diagnostic Capabilities" | translate}}</li>
              <li>{{"Incorporating file sharing" | translate}}</li>
              <li>{{"SMS Messaging" | translate}}</li>
              <li>{{"Whiteboard" | translate}}</li>
              <li>{{"On-Demand Recording" | translate}}</li>
            </ul>
          </div>
          <div class="col-sm-12 col-md-6 text-left">
            <ul>
              <li>{{"API to the Hospitals electronic health records" | translate}}</li>
              <li>{{"Patient portal with direct access to labs" | translate}}</li>
              <li>{{"Pharmacies and others allow seamless integration throughout the care spectrum" | translate}}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
