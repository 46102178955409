<div class="container">
  <div class="row">
    <div class="card my-5 w-100 mx-2">
      <div class="card-body col-md-12 col-sm-12 w-100" style="color: #000; text-align: justify;">
        <h1 class="text-center hiw-title">
          {{"Frequently Asked Questions" | translate}}
        </h1>
        <div class="accordion mt-5" id="accordionExample">
          <div class="card new">
            <div class="card-header" id="1">
              <h2 class="mb-0">
                <button class="btn btn-link btn-block text-left" type="button" data-toggle="collapse" data-target="#c1"
                  aria-expanded="true" aria-controls="collapseOne">
                  {{"How much does VERADoctors cost?" | translate}}
                </button>
              </h2>
            </div>
            <div id="c1" class="collapse show" aria-labelledby="1" data-parent="#accordionExample">
              <div class="card-body">
                <div class="card-body-new">
                  <p>{{"Setting up your account is free!" | translate}}</p>
                  <p>{{"We then have different membership and non-membership pricing options which can be found on our"
                    | translate}}
                    <a href="javascript:void(0);" [routerLink]="'/membership'" target="_blank">
                      {{"Membership Page" | translate}}
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="card new">
            <div class="card-header" id="2">
              <h2 class="mb-0">
                <button class="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse"
                  data-target="#c2" aria-expanded="false" aria-controls="collapseTwo">
                  {{"Will my results be sent to my primary physician?" | translate}}
                </button>
              </h2>
            </div>
            <div id="c2" class="collapse" aria-labelledby="2" data-parent="#accordionExample">
              <div class="card-body">
                <div class="card-body-new">
                  <p>
                    {{"We are KVKK, HIPAA and GDPR compliant and have policies in place to prevent unauthorized
                    disclosure of your
                    protected health information. If you would like us to send or disclose your medical records on your
                    behalf
                    to another person or entity, we require written authorization and signed consent. Additionally,
                    if you would like to prevent specific disclosure of your protected health information to a specified
                    person or entity, please let us know." | translate}}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="card new">
            <div class="card-header" id="3">
              <h2 class="mb-0">
                <button class="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse"
                  data-target="#c3" aria-expanded="false" aria-controls="collapseThree">
                  {{"Is all of my information private and secure?" | translate}}
                </button>
              </h2>
            </div>
            <div id="c3" class="collapse" aria-labelledby="3" data-parent="#accordionExample">
              <div class="card-body">
                <div class="card-body-new">
                  <!-- <p>
                    {{"Yes! Our systems are built with top-of-the-line security and are KVKK, HIPAA and GDPR compliant.
                    All interactions and communications through our systems are encrypted." | translate}}
                  </p> -->

                  <p>
                    {{"VERA Health and Education B.V. is committed to protecting the privacy of every individual who uses
                    our services. We comply with KVKK, HIPAA and GDPR, with all relevant state, national and international
                    laws and regulations." | translate}}
                  </p>
                  <p>
                    {{"The Health Insurance Portability and Accountability Act of 1996 (HIPAA) is a federal law that
                    required the creation of national standards to protect sensitive patient health information from being
                    disclosed without the patient's consent or knowledge." | translate}}
                  </p>
                  <p>
                    {{"The General Data Protection Regulation (GDPR) is a legal framework that sets guidelines for the
                    collection and processing of personal information from individuals who live in and outside of the
                    European Union (EU). Approved in 2016." | translate}}
                  </p>
                  <p>
                  {{"Unless permitted or required by law, your medical information will not be shared without your
                  consent. On occasion, we may use de-identified information to help us improve our clinical programs." |
                  translate}}
                  </p>


                </div>
              </div>
            </div>
          </div>

          <div class="card new">
            <div class="card-header" id="4">
              <h2 class="mb-0">
                <button class="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse"
                  data-target="#c4" aria-expanded="false" aria-controls="collapseThree">
                  {{"Do I need to set up an account to use VERADoctors?" | translate}}
                </button>
              </h2>
            </div>
            <div id="c4" class="collapse" aria-labelledby="4" data-parent="#accordionExample">
              <div class="card-body">
                <div class="card-body-new">
                  <p>
                    {{"Yes, you will need to set up an account and profile before utilizing VERADoctors." | translate}}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div class="card new">
            <div class="card-header" id="5">
              <h2 class="mb-0">
                <button class="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse"
                  data-target="#c5" aria-expanded="false" aria-controls="collapseThree">
                  {{"What does VERADoctors treat?" | translate}}
                </button>
              </h2>
            </div>
            <div id="c5" class="collapse" aria-labelledby="5" data-parent="#accordionExample">
              <div class="card-body">
                <div class="card-body-new">
                  <p>
                    {{"We provide care for many of the world's most common health conditions. Here is a list of the
                    different medical and mental health conditions we treat on our" | translate}}
                    <a href="javascript:void(0);" [routerLink]="'/what-we-treat'" target="_blank"> {{"platform." |
                      translate}}</a>
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div class="card new">
            <div class="card-header" id="6">
              <h2 class="mb-0">
                <button class="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse"
                  data-target="#c6" aria-expanded="false" aria-controls="collapseThree">
                  {{"Does VERADoctors accept my insurance?" | translate}}
                </button>
              </h2>
            </div>

            <div id="c6" class="collapse" aria-labelledby="6" data-parent="#accordionExample">
              <div class="card-body">
                <div class="card-body-new">
                  <p>
                    {{"We will work with your insurance company where and when possible." | translate}}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div class="card new">
            <div class="card-header" id="7">
              <h2 class="mb-0">
                <button class="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse"
                  data-target="#c7" aria-expanded="false" aria-controls="collapseThree">
                  {{"Where can I use VERADoctors?" | translate}}
                </button>
              </h2>
            </div>
            <div id="c7" class="collapse" aria-labelledby="7" data-parent="#accordionExample">
              <div class="card-body">
                <div class="card-body-new">
                  <p>
                    {{"You can use VERADoctors worldwide. We aim to connect everyone with top-of-the-line specialists
                    and
                    specialized nurses, no matter where they are." | translate}}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div class="card new">
            <div class="card-header" id="8">
              <h2 class="mb-0">
                <button class="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse"
                  data-target="#c8" aria-expanded="false" aria-controls="collapseThree">
                  {{"Is there a time limit to each virtual visit?" | translate}}
                </button>
              </h2>
            </div>
            <div id="c8" class="collapse" aria-labelledby="8" data-parent="#accordionExample">
              <div class="card-body">
                <div class="card-body-new">
                  <p>
                    {{"The duration of each call is 15 minutes. The doctor can add 5 minutes if needed." | translate}}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <!-- <div class="card new">
            <div class="card-header" id="8">
              <h2 class="mb-0">
                <button class="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse"
                  data-target="#c8" aria-expanded="false" aria-controls="collapseThree">
                  {{"Can I visit a specialist in person?" | translate}}
                </button>
              </h2>
            </div>
            <div id="c8" class="collapse" aria-labelledby="8" data-parent="#accordionExample">
              <div class="card-body">
                <div class="card-body-new">
                  <p>
                    {{"If you wish, our specialist can direct you to the nearest contracted hospital after your virtual meeting." | translate}}
                  </p>
                </div>
              </div>
            </div>
          </div> -->

          <!-- <div class="card new">
            <div class="card-header" id="9">
              <h2 class="mb-0">
                <button class="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse"
                  data-target="#c9" aria-expanded="false" aria-controls="collapseThree">
                  {{"Do I need to go to a hospital for testing?" | translate}}
                </button>
              </h2>
            </div>
            <div id="c9" class="collapse" aria-labelledby="9" data-parent="#accordionExample">
              <div class="card-body">
                <div class="card-body-new">
                  <p>
                    {{"No. For common tests, you can opt for a specialized nurse to come to your home, hotel, or office.
                    For extensive tests and x-rays, VERADoctors will locate the nearest offices and hospitals that can
                    provide those services for you. You can easily book an appointment at any of our partners’ locations through
                    the VERADoctors portal." | translate}}
                  </p>
                </div>
              </div>
            </div>
          </div> -->

          <!-- <div class="card new">
            <div class="card-header" id="10">
              <h2 class="mb-0">
                <button class="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse"
                  data-target="#c10" aria-expanded="false" aria-controls="collapseThree">
                  {{"Can I get a prescription?" | translate}}
                </button>
              </h2>
            </div>
            <div id="c10" class="collapse" aria-labelledby="10" data-parent="#accordionExample">
              <div class="card-body">
                <div class="card-body-new">
                  <p>
                    {{"Yes. VERADoctors makes it easy and affordable to get prescription medication sent to your local pharmacy." | translate}}
                  </p>
                  <p>
                    {{"Book and complete an appointment with a physician or specialist. If it results in a prescription,
                    your local pharmacy of choice will be notified. You may pay and pick up your prescription when it is ready." | translate}}
                  </p>
                  <p>
                    {{"VERADoctors is not a pharmacy." | translate}}
                  </p>
                </div>
              </div>
            </div>
          </div> -->

          <!-- <div class="card new">
            <div class="card-header" id="11">
              <h2 class="mb-0">
                <button class="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse"
                  data-target="#c11" aria-expanded="false" aria-controls="collapseThree">
                  {{"How are prescriptions sent to the pharmacy?" | translate}}
                </button>
              </h2>
            </div>
            <div id="c11" class="collapse" aria-labelledby="11" data-parent="#accordionExample">
              <div class="card-body">
                <div class="card-body-new">
                  <p>
                    {{"Your VERADoctor provider will electronically send the prescription to your pharmacy of choice." | translate}}
                  </p>
                </div>
              </div>
            </div>
          </div> -->

          <!-- <div class="card new">
            <div class="card-header" id="12">
              <h2 class="mb-0">
                <button class="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse"
                  data-target="#c12" aria-expanded="false" aria-controls="collapseThree">
                  {{"Do I have to travel or collect my own medical records?" | translate}}
                </button>
              </h2>
            </div>
            <div id="c12" class="collapse" aria-labelledby="12" data-parent="#accordionExample">
              <div class="card-body">
                <div class="card-body-new">
                  <p>
                    {{"No. With your authorization, we can request medical information from your primary care physician." | translate}}
                  </p>
                  <p>
                    {{"VERADoctors is HIPAA and GDPR compliant." | translate}}
                  </p>
                </div>
              </div>
            </div>
          </div> -->

          <div class="card new">
            <div class="card-header" id="13">
              <h2 class="mb-0">
                <button class="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse"
                  data-target="#c13" aria-expanded="false" aria-controls="collapseThree">
                  {{"How do I start my video visit?" | translate}}
                </button>
              </h2>
            </div>
            <div id="c13" class="collapse" aria-labelledby="13" data-parent="#accordionExample">
              <div class="card-body">
                <div class="card-body-new">
                  <p>
                    {{"After you book a video visit, we will send you a reminder through notification and sms about your
                    appointment." | translate}}
                  </p>
                  <p>
                    {{"You can join your virtual visit by:" | translate}}
                  </p>
                  <ul *ngIf="languageSelect == 'en'">
                    <li>
                      {{"selecting “Join Video Visit” in the confirmation email" | translate}}
                    </li>
                    <li>
                      {{"going to your profile and selecting “My appointments”, then selecting “Join Video Visit”
                      clicking the link in the reminder email" | translate}}
                    </li>
                  </ul>
                  <p>
                    {{"Make sure your wifi connection is solid and your browser is up to date." | translate}}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div class="card new">
            <div class="card-header" id="14">
              <h2 class="mb-0">
                <button class="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse"
                  data-target="#c14" aria-expanded="false" aria-controls="collapseThree">
                  {{"How do I set up my account?" | translate}}
                </button>
              </h2>
            </div>
            <div id="c14" class="collapse" aria-labelledby="14" data-parent="#accordionExample">
              <div class="card-body">
                <div class="card-body-new">
                  <p>
                    {{"Register a new account by going to VERADoctors home page and clicking the link “Register” in the
                    upper
                    right corner. Continue to fill out your information and confirm your email." | translate}}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div class="card new">
            <div class="card-header" id="15">
              <h2 class="mb-0">
                <button class="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse"
                  data-target="#c15" aria-expanded="false" aria-controls="collapseThree">
                  {{"How do I change or update billing information?" | translate}}
                </button>
              </h2>
            </div>
            <div id="c15" class="collapse" aria-labelledby="15" data-parent="#accordionExample">
              <div class="card-body">
                <div class="card-body-new">
                  <p>
                    {{"Head to your profile and click on “account information”, then select “Update billing
                    information”" | translate}}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="card new">
            <div class="card-header" id="16">
              <h2 class="mb-0">
                <button class="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse"
                  data-target="#c16" aria-expanded="false" aria-controls="collapseThree">
                  {{"How do I schedule a visit?" | translate}}
                </button>
              </h2>
            </div>
            <div id="c16" class="collapse" aria-labelledby="16" data-parent="#accordionExample">
              <div class="card-body">
                <div class="card-body-new">
                  <p>
                    {{"Browse through the VERADoctors providers in your area and search by category, specialty, or location.
                    You can view each doctor's profile or click the “Book now” button and set up an in-person appointment ASAP." | translate}}
                  </p>
                </div>
              </div>
            </div>
          </div> -->
          <div class="card new">
            <div class="card-header" id="17">
              <h2 class="mb-0">
                <button class="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse"
                  data-target="#c17" aria-expanded="false" aria-controls="collapseThree">
                  {{"How do I schedule a virtual visit?" | translate}}
                </button>
              </h2>
            </div>
            <div id="c17" class="collapse" aria-labelledby="17" data-parent="#accordionExample">
              <div class="card-body">
                <div class="card-body-new">
                  <p>
                    {{"Browse through the VERADoctors global providers and search by category, specialty, or location.
                    You
                    can view each doctor's profile or click the “Book now” button and set up a virtual appointment
                    ASAP." | translate}}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="card new">
          <div class="card-header" id="18">
            <h2 class="mb-0">
              <button class="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse"
                data-target="#c18" aria-expanded="false" aria-controls="collapseThree">
                {{"What will happen if I cancel my appointment?" | translate}}
              </button>
            </h2>
          </div>
          <div id="c18" class="collapse" aria-labelledby="18" data-parent="#accordionExample">
            <div class="card-body">
              <div class="card-body-new">
                <p>
                  {{"Cancellation requests are free or charge when you cancel your service up to 24 hours in advance." |
                  translate}}
                </p>
                <p>
                  {{"Your deposit will be added as a credit to your account." | translate}}
                </p>
                <p>
                  {{"If you cancel your service within 24 hours, no refund will be made. " | translate}}
                </p>
              </div>
            </div>
          </div>
        </div>

        

    </div>
  </div>
</div>
</div>