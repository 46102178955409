import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-agora-login',
  templateUrl: './agora-login.component.html',
  styleUrls: ['./agora-login.component.css']
})
export class AgoraLoginComponent implements OnInit {

  constructor(private router: Router, private route: ActivatedRoute) { }

  params='';
  queryParams='';
  userName=''
  ngOnInit(): void {
    this.route.params.subscribe(res => {
      this.params = res?.id

      if(localStorage.getItem('role')=='user'||localStorage.getItem('role')=='doctor'){
        this.router.navigate(['/agora-calls/'+this.params])
      }
    })
  }

  joinRoom(){
    this.router.navigate(['/agora-calls/'+this.params], { queryParams: { name: this.userName }})
  }

}
