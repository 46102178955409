import { Component, OnInit, ElementRef } from '@angular/core';

@Component({
  selector: 'app-avicerna',
  templateUrl: './avicerna.component.html',
  styleUrls: ['./avicerna.component.css'],
})
export class AvicernaComponent implements OnInit {
  constructor(private elementRef: ElementRef) {}

  ngOnInit(): void {}

  scrollTo(id: string): void {
    const element = this.elementRef.nativeElement.querySelector('#' + id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }
}
