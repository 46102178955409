<div class="container">
  <div class="row">
    <div class="card my-5 mx-2">
      <div class="card-title text-center">
        <div class="card-header text-center">
          <h1>{{"Become a Provider" | translate}}</h1>
          <h2>{{"So glad you are here!" | translate}}</h2>
        </div>
        <h1 class="text-center hiw-title my-5">

        </h1>
      </div>
      <div class="card-body mb-5 desc text-center" style="color: #000;">
        <p>
          {{"Thank you for your interest in joining VERADoctors’ network of providers! VERADoctors’ dedication to
          advancing
          hybrid telemedicine solutions paired with our acquisitions of the top new tech creates the possibility to
          improve healthcare as a whole, reduce costs, and increase access both virtually and in person." | translate}}
        </p>
        <p>
          {{"Joining our network means you’ll provide telehealth consultations and recommendations for our growing panel
          of patients
          from our partnerships with health insurance companies, airlines, hotels, cruise ships, and other employers." |
          translate}}
        </p>
        <ul class="text-left">
          <li>{{"Physicians must be certified by the top health agency / authority in their perspective country. They
            must be in good standing and hold an impeccable record within their medical practice." | translate}}</li>
          <!-- <li>{{"Physicians must be certified by the American Board of Internal Medicine, Emergency Medicine, Family Medicine, Pediatrics, Dermatology, or Psychiatry" | translate}}</li> -->
          <li>{{"Physicians/Psychiatrists must be at least 4 years post-residency and Behavioral Health
            Psychologist/Therapists must be independently licensed for at least four years" | translate}}</li>
          <li *ngIf="langSelect == 'en'">{{"Physicians must have an active and unrestricted DEA." | translate}}</li>

          <!-- <li>{{"Please note that we do not onboard nurse practitioners, physician assistants, occupational/physical/speech therapists, behavioral analysts or dieticians on our network" | translate}}</li> -->
        </ul>
        <div class="w-100 d-flex justify-content-center">
          <form class="w-100" [formGroup]="form">
            <div class="text-center">
              <h2 style="color: #4365ef;">{{"FORM" | translate}}</h2>
            </div>
            <div class="form-group">
              <input type="text" class="form-control" id="name" name="name" placeholder="{{'First Name' | translate}}"
                maxlength="50" formControlName="firstName" pattern="[a-zA-ZıçöğşüÇÖĞŞÜİ ]*" minlength="2"
                [style.border-color]="form.get('firstName')?.valid ? 'blue' : 'red'">
            </div>
            <div class="form-group">
              <input type="text" class="form-control" id="surname" name="surname"
                placeholder="{{'Last Name' | translate}}" maxlength="50" formControlName="lastName"
                pattern="[a-zA-ZıçöğşüÇÖĞŞÜİ ]*" minlength="2"
                [style.border-color]="form.get('lastName')?.valid ? 'blue' : 'red'">
            </div>
            <div class="form-group">
              <input type="email" class="form-control" id="mail" name="mail" placeholder="{{'Email' | translate}}"
                maxlength="50" email formControlName="mail" minlength="5" [style.border-color]="form.get('mail')?.valid && form.get('mail')?.touched ? 'blue' : 'red'">
            </div>
            <div class="form-group">
              <input type="phone" class="form-control" id="phone" name="phone" placeholder="{{'Phone' | translate}}"
                maxlength="50" formControlName="phoneNumber" minlength="10" pattern="[0-9]*" [style.border-color]="form.get('phoneNumber')?.valid ? 'blue' : 'red'">
            </div>
            <div class="form-group">
              <input type="text" class="form-control" id="practice" name="practice"
                placeholder="{{'Country of Practice' | translate}}" maxlength="50" formControlName="country" pattern="[a-zA-ZıçöğşüÇÖĞŞÜİ ]*" 
                [style.border-color]="form.get('country')?.valid ? 'blue' : 'red'">
            </div>
            <div class="form-group">
              <input type="text" class="form-control" id="npi" name="npi" placeholder="{{'NPI' | translate}}"
                maxlength="50" formControlName="npi" pattern="^(\d+(\.\d{0,6})?|\.?\d{1,2})$" [style.border-color]="form.get('npi')?.valid ? 'blue' : 'red'">
            </div>
            <div class="form-group">
              <input type="text" class="form-control" id="type" name="type"
                placeholder="{{'Type of Provider' | translate}}" maxlength="100" formControlName="providerType"
                pattern="^[a-zA-ZıçöğşüÇÖĞŞÜİ ]*" [style.border-color]="form.get('providerType')?.valid ? 'blue' : 'red'">
            </div>

            <div class="text-center">
              <button class="btn login-btn" type="button" (click)="submit()" [disabled]="!form.valid">{{"Submit" |
                translate}}</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>