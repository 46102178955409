import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { CountryService } from 'src/app/services/country.service';
import { SendMailService } from 'src/app/services/send-mail.service';

@Component({
  selector: 'app-government',
  templateUrl: './government.component.html',
  styleUrls: ['./government.component.css']
})
export class GovernmentComponent implements OnInit {

  constructor(private fb: FormBuilder,
    private countryService: CountryService,
    private sendMail: SendMailService,
    private toastr: ToastrService) { }

  form: FormGroup = this.fb.group({
    firstName: [''],
    lastName: [''],
    phoneNumber: [''],
    mail: [''],
    country: [''],
    date: null,
    description: [''],
  })

  countries = [];
  isForm: boolean = false;

  ngOnInit(): void {

    this.countryService.getCountries().subscribe(res => {
      this.countries = res as unknown as Array<any>
    })
    window.scrollTo(0, 0);
  }

  openForm(){
    this.isForm = !this.isForm
  }

  submit(){
    const sendedForm = {
      text: `${this.form.controls['description'].value} // Phone Number: ${this.form.controls['phoneNumber'].value} // Country: ${this.form.controls['country'].value} // Date: ${this.form.controls['date'].value}`,
      subject: "Request Meeting Form",
      senderEmail: this.form.controls['mail'].value,
      senderName: `${this.form.controls['firstName'].value} ${this.form.controls['lastName'].value}`
    }
    this.sendMail.requestAMeeting(sendedForm).subscribe(res => {
      this.toastr.success('', 'Request Mail Sent')
    }, err => this.toastr.error('please try again later', 'something went wrong'))
  }

}
