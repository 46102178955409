<form [formGroup]="avicernaForm" (ngSubmit)="onSubmit()">
  <!-- General Information -->
  <div class="info-header-avicerna">
    <h3 class="info-title-avicerna">{{ "General Information" | translate }}</h3>
  </div>
  <div class="row">
    <div class="form-group col-12 col-sm-6">
      <label
        >{{ "Phone Number" | translate }}
        <span class="text-danger"> *</span></label
      >
      <ngx-intl-tel-input
        class="inttel form-control"
        [enableAutoCountrySelect]="true"
        [enablePlaceholder]="true"
        [searchCountryFlag]="true"
        [searchCountryField]="[
          SearchCountryField.Iso2,
          SearchCountryField.Name
        ]"
        [selectFirstCountry]="false"
        [selectedCountryISO]="CountryISO.Turkey"
        [maxLength]="15"
        [phoneValidation]="true"
        [separateDialCode]="separateDialCode"
        [numberFormat]="PhoneNumberFormat.National"
        formControlName="phone"
        (ngModelChange)="onChange($event)"
        [ngClass]="{
          'has-error': submitted && avicernaForm.controls.phone.errors
        }"
      >
      </ngx-intl-tel-input>
      <p
        class="mt-1"
        *ngIf="submitted && avicernaForm.controls.phone.errors?.required"
      >
        <span class="has-error-text">{{
          "Phone Number Required" | translate
        }}</span>
      </p>
      <p
        class="mt-1"
        *ngIf="
          submitted && avicernaForm.controls.phone.errors?.validatePhoneNumber
        "
      >
        <span class="has-error-text">{{
          "Invalid Phone Number" | translate
        }}</span>
      </p>
    </div>
    <div class="form-group col-12 col-sm-6">
      <label
        >{{ "Date of Birth" | translate }}
        <span class="text-danger"> *</span></label
      >
      <input
        class="form-control"
        (selected)="selectDate($event)"
        name="dateOfBirth"
        id="dateOfBirth"
        daterangepicker
        placeholder=""
        [options]="singlePicker[localLang]"
        [ngClass]="{
          'has-error': submitted && avicernaForm.controls.dateOfBirth.errors
        }"
      />
      <p class="mt-1">
        <span
          *ngIf="
            submitted && avicernaForm.controls.dateOfBirth.errors?.required
          "
          class="has-error-text"
          >{{ "Date of Birth is required" | translate }}</span
        >
      </p>
    </div>
    <div class="form-group col-12 col-sm-6">
      <label for="country"
        >{{ "Country" | translate }} <span class="text-danger"> *</span></label
      >
      <select
        id="country"
        class="form-control"
        formControlName="country"
        (change)="onCountryChange($event)"
        [ngClass]="{
          'has-error': submitted && avicernaForm.controls.country.errors
        }"
      >
        <option value="" disabled selected>
          {{ "Select Country" | translate }}
        </option>
        <option *ngFor="let country of countries" [value]="country.id">
          {{ country.name }}
        </option>
      </select>
      <p class="mt-1">
        <span
          *ngIf="submitted && avicernaForm.controls.country.errors?.required"
          class="has-error-text"
          >{{ "Country is required" | translate }}</span
        >
      </p>
    </div>
    <div class="form-group col-12 col-sm-6">
      <label for="state"
        >{{ "State" | translate }}
        <span *ngIf="isStateRequired" class="text-danger"> *</span></label
      >
      <select
        id="state"
        class="form-control"
        formControlName="state"
        [ngClass]="{
          'has-error': submitted && avicernaForm.controls.state.errors
        }"
      >
        <option value="" disabled selected>
          {{ "Select State" | translate }}
        </option>
        <option *ngFor="let state of states" [value]="state.id">
          {{ state.name }}
        </option>
      </select>
      <p class="mt-1">
        <span
          *ngIf="submitted && avicernaForm.controls.state.errors?.required"
          class="has-error-text"
          >{{ "State is required" | translate }}</span
        >
      </p>
    </div>
    <div class="form-group col-12">
      <label for="gender"
        >{{ "Gender" | translate }} <span class="text-danger"> *</span></label
      >
      <select
        id="gender"
        class="form-control"
        formControlName="gender"
        (change)="onGenderChange($event)"
        [ngClass]="{
          'has-error': submitted && avicernaForm.controls.gender.errors
        }"
      >
        <option value="" disabled selected>Cinsiyet Seçiniz</option>
        <option *ngFor="let gender of genders" [value]="gender">
          {{ gender | translate }}
        </option>
      </select>
      <p class="mt-1">
        <span
          *ngIf="submitted && avicernaForm.controls.gender.errors?.required"
          class="has-error-text"npm cac
          >{{ "Gender is required" | translate }}</span
        >
      </p>
    </div>
  </div>

  <!-- Treatment -->

  <div class="row">
    <div class="info-header-avicerna col-12">
      <h3 class="info-title-avicerna">
        {{ "Condition to be Treated with Stem Cells" | translate }}
        <span class="text-danger"> *</span>
      </h3>
      <p class="treatment-error">
        <span *ngIf="submitted && !isCheckBoxValid" class="has-error-section">{{
          "Please select at least one" | translate
        }}</span>
      </p>
    </div>
    <div
      class="form-group col-12 avicerna-treatment-body"
      *ngFor="let treatmentItem of treatments"
      formGroupName="treatment"
    >
      <label class="checkbox-avicerna" [for]="treatmentItem.key">
        <input
          type="checkbox"
          [formControlName]="treatmentItem.key"
          id="{{ treatmentItem.key }}"
          name="treatmentItem.key"
          (change)="onCheckboxChange(treatmentItem.key)"
        />
        <div class="checkbox__checkmark"></div>
        <div class="checkbox__body">{{ treatmentItem.label | translate }}</div>
      </label>
    </div>
    <div class="form-group col-12 mt-3">
      <label
        >{{ "Other" | translate }}
        <span *ngIf="!isOtherTreatmentDisabled" class="text-danger">
          *</span
        ></label
      >
      <input
        type="text"
        class="form-control"
        name="otherTreatmentInput"
        formControlName="otherTreatmentInput"
        id="otherTreatmentInput"
        placeholder=""
        [ngClass]="isOtherTreatmentDisabled ? 'isOtherDisabled' : ''"
      />
      <p class="mt-1">
        <span
          *ngIf="
            submitted &&
            avicernaForm.controls.otherTreatmentInput.errors?.required
          "
          class="has-error-text"
          >{{ "Description is required" | translate }}</span
        >
      </p>
    </div>
  </div>

  <!-- Where did you hear about us -->
  <div class="row">
    <div class="info-header-avicerna col-12">
      <h3 class="info-title-avicerna">
        {{ "Where did you hear about us" | translate }}
        <span class="text-danger"> *</span>
      </h3>
      <p class="where-error">
        <span *ngIf="submitted && !isRadioValid" class="has-error-section">{{
          "Please select one" | translate
        }}</span>
      </p>
    </div>
    <div class="col-12 avicerna-where-body">
      <div
        *ngFor="let source of whereDidYouHearAboutUsList"
        formGroupName="whereDidYouHearAboutUs"
      >
        <label class="radio-avicerna" [for]="source.key">
          <input
            id="{{ source.key }}"
            type="radio"
            [formControlName]="source.key"
            name="source.key"
            (change)="onRadioChange(source.key)"
          />
          <div class="radio__checkmark"></div>
          <div class="radio__body">{{ source.label | translate }}</div>
        </label>
      </div>
    </div>
    <div class="form-group col-12 mt-3">
      <label
        >{{
          "Name of the organization / business / person / website where you heard about us"
            | translate
        }}
        <span *ngIf="!isOtherWhereDisabled" class="text-danger"> *</span>
      </label>
      <input
        type="text"
        class="form-control"
        name="otherWhereInput"
        formControlName="otherWhereInput"
        id="otherWhereInput"
        placeholder=""
        [ngClass]="isOtherWhereDisabled ? 'isOtherDisabled' : ''"
      />
      <p class="mt-1">
        <span
          *ngIf="
            submitted && avicernaForm.controls.otherWhereInput.errors?.required
          "
          class="has-error-text"
          >{{ "Description is required" | translate }}</span
        >
      </p>
    </div>
  </div>
  <div class="w-100 text-center">
    <button type="submit" class="btn avicerna-submit-btn">
      {{ "Submit" | translate }}
    </button>
  </div>
</form>
