import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,
} from '@angular/forms';
import { LocationService } from 'src/app/services/location.service';
import {
  SearchCountryField,
  CountryISO,
  PhoneNumberFormat,
} from 'ngx-intl-tel-input';

import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { AvicernaFormService } from 'src/app/services/avicerna-form.service';

@Component({
  selector: 'app-avicerna-form',
  templateUrl: './avicerna-form.component.html',
  styleUrls: ['./avicerna-form.component.css'],
})
export class AvicernaFormComponent implements OnInit {
  separateDialCode = false;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  public singlePicker = {
    tr: {
      singleDatePicker: true,
      autoUpdateInput: true,
      showDropdowns: true,
      autoApply: true,
      drops: 'down',
      maxDate: new Date(),
      locale: {
        format: 'DD-MM-YYYY',
        separator: ' - ',
        applyLabel: 'Uygula',
        cancelLabel: 'Vazgeç',
        fromLabel: 'Başlangıç',
        toLabel: 'Bitiş',
        customRangeLabel: 'Özel Aralık',
        weekLabel: 'H',
        daysOfWeek: ['Pz', 'Pzt', 'Sal', 'Çar', 'Per', 'Cum', 'Cts'],
        monthNames: [
          'Oca',
          'Şub',
          'Mar',
          'Nis',
          'May',
          'Haz',
          'Tem',
          'Ağu',
          'Eyl',
          'Eki',
          'Kas',
          'Ara',
        ],
        firstDay: 1,
      },
    },
    en: {
      singleDatePicker: true,
      autoUpdateInput: true,
      showDropdowns: true,
      autoApply: true,
      drops: 'down',
      maxDate: new Date(),
      locale: {
        format: 'DD-MM-YYYY',
        separator: ' - ',
        weekLabel: 'H',
        firstDay: 1,
      },
    },
  };
  avicernaForm: FormGroup;
  countries;
  states;
  cities;
  genders: any = ['prefer not to say', 'female', 'male'];
  localLang = localStorage.getItem('language');
  phone;
  mobile = '';
  checkBoxTreatmentList = [];
  radioWhere = '';
  isStateRequired: boolean = false;
  submitted: boolean = false;
  isOtherWhereDisabled = true;
  isOtherTreatmentDisabled = true;
  isCheckBoxValid: boolean = true;
  isRadioValid: boolean = true;

  isUser = localStorage.getItem('user');
  userRole = localStorage.getItem('role');

  treatments = [
    { key: 'gastrointestinal', label: 'Gastrointestinal Diseases' },
    { key: 'neurological', label: 'Neurological Diseases' },
    { key: 'urologic', label: 'Urologic Diseases' },
    { key: 'gynecologic', label: 'Gynecologic Diseases' },
    { key: 'orthopedic', label: 'Orthopedic Diseases' },
    { key: 'dermatological', label: 'Dermatological Diseases' },
    { key: 'eye', label: 'Eye Diseases' },
    { key: 'beautyHair', label: 'Beauty / Hair' },
    {
      key: 'plasticAndReconstructive',
      label: 'Plastic and Reconstructive Surgery',
    },
    { key: 'generalSurgery', label: 'General Surgery Diseases' },
    { key: 'cardiovascularSurgery', label: 'Cardiovascular Surgery Diseases' },
    { key: 'hematologic', label: 'Hematologic Diseases' },
    {
      key: 'chestDiseasesAndChestSurgery',
      label: 'Chest Diseases and Chest Surgery',
    },
    { key: 'oncological', label: 'Oncological Diseases' },
    { key: 'endocrinology', label: 'Endocrinology Diseases' },
    { key: 'earNoseThroat', label: 'Ear-Nose-Throat Disorders' },
    { key: 'otherTreatment', label: 'Other' },
  ];

  whereDidYouHearAboutUsList = [
    { key: 'hospital', label: 'Hospital' },
    { key: 'physician', label: 'Doctor' },
    { key: 'pharmacy', label: 'Pharmacy' },
    { key: 'beautyCenter', label: 'Beauty Center' },
    { key: 'person', label: 'Familiar / Friend' },
    { key: 'hotel', label: 'Hotel' },
    { key: 'website', label: 'Website / Social Media' },
    { key: 'otherWhere', label: 'Other' },
  ];

  constructor(
    private formBuilder: FormBuilder,
    private locationService: LocationService,
    private toastr: ToastrService,
    private translate: TranslateService,
    private router: Router,
    private avicerna: AvicernaFormService
  ) {
    this.avicernaForm = this.formBuilder.group({
      otherWhereInput: [{ value: '', disabled: true }],
      otherTreatmentInput: [{ value: '', disabled: true }],
      gender: [{ value: '', disabled: false }, Validators.required],
      dateOfBirth: ['', Validators.required],
      state: [{ value: '', disabled: false }, Validators.required],
      country: [{ value: '', disabled: false }, Validators.required],
      phone: ['', Validators.required],
      treatment: this.formBuilder.group({ ...this.createTreatmentControls() }),
      whereDidYouHearAboutUs: this.formBuilder.group({
        ...this.createWhereDidYouHearAboutUsControls(),
      }),
    });
  }

  ngOnInit(): void {
    this.getCountries();
    this.getStates();
  }

  createTreatmentControls() {
    const controls = {};
    this.treatments.forEach((treatment) => {
      controls[treatment.key] = [false];
    });
    return controls;
  }
  createWhereDidYouHearAboutUsControls() {
    const controls = {};
    this.whereDidYouHearAboutUsList.forEach((source) => {
      controls[source.key] = [false];
    });
    return controls;
  }

  getCountries() {
    const defaultCountry = {
      id: '225',
      name: 'Türkiye',
    };
    this.locationService.getCountries().subscribe((data) => {
      this.countries = data;
    });

    this.avicernaForm.get('country').setValue(defaultCountry.id);
  }

  getStates(countryId = '225') {
    this.locationService.getStates(countryId).subscribe((res: any) => {
      this.states = res;
      if (res.length > 0) {
        this.avicernaForm.get('state').setValidators([Validators.required]);
        this.avicernaForm.get('state').setValue('');
        this.isStateRequired = true;
      } else {
        this.avicernaForm.get('state').clearValidators();
        this.isStateRequired = false;
      }
      this.avicernaForm.get('state').updateValueAndValidity();
      this.avicernaForm.get('state').enable();
    });
  }

  getCities(countryId, stateId) {
    this.locationService.getCities(countryId, stateId).subscribe((res) => {
      this.cities = res;
      this.avicernaForm.get('city').enable();
    });
  }

  onCountryChange(event) {
    const selectedCountry = event.target.value;
    if (selectedCountry) {
      this.getStates(selectedCountry);
      this.avicernaForm.get('state').reset();
    } else {
      this.avicernaForm.get('state').reset();
      this.avicernaForm.get('state').disable();
    }
  }

  onGenderChange(event) {}

  selectDate(value: any) {
    var date = value.start.format('YYYY-MM-DD');

    this.avicernaForm.get('dateOfBirth').setValue(date + 'T21:00:00.000Z');
  }

  onChange(event) {
    this.phone = event?.e164Number;
    this.mobile = event?.e164Number;
    // if (this.avicernaForm.controls['phone'].value === '') {
    //   this.avicernaForm.controls['phone'].setErrors({ required: true });
    // } else {
    //   this.avicernaForm.controls['phone'].setErrors(null);
    // }
  }

  onRadioChange(value: string) {
    this.isRadioValid = true;

    this.radioWhere = value;

    if (value === 'otherWhere') {
      this.isOtherWhereDisabled = false;
      this.avicernaForm.controls['otherWhereInput'].enable();
      this.avicernaForm
        .get('otherWhereInput')
        .setValidators(Validators.required);
      this.avicernaForm.get('otherWhereInput').updateValueAndValidity();
    } else {
      this.isOtherWhereDisabled = true;
      this.avicernaForm.controls['otherWhereInput'].disable();
      this.avicernaForm.get('otherWhereInput').setValue('');
      this.avicernaForm.get('otherWhereInput').clearValidators();
      this.avicernaForm.get('otherWhereInput').updateValueAndValidity();
    }
  }
  onCheckboxChange(value: string) {
    this.isCheckBoxValid = true;

    const indexValue = this.checkBoxTreatmentList.indexOf(value);
    const indexValueOther =
      this.checkBoxTreatmentList.indexOf('otherTreatment');

    if (indexValue !== -1) {
      this.checkBoxTreatmentList.splice(indexValue, 1);
    } else {
      this.checkBoxTreatmentList.push(value);
    }

    if (value === 'otherTreatment') {
      if (indexValueOther !== -1) {
        this.isOtherTreatmentDisabled = true;
        this.avicernaForm.controls['otherTreatmentInput'].disable();
        this.avicernaForm.get('otherTreatmentInput').setValue('');
        this.avicernaForm.get('otherTreatmentInput').clearValidators();
        this.avicernaForm.get('otherTreatmentInput').updateValueAndValidity();
      } else {
        this.isOtherTreatmentDisabled = false;
        this.avicernaForm.controls['otherTreatmentInput'].enable();
        this.avicernaForm
          .get('otherTreatmentInput')
          .setValidators(Validators.required);
        this.avicernaForm.get('otherTreatmentInput').updateValueAndValidity();
      }
    }
  }

  onSubmit() {
    if (this.isUser) {
      if (this.userRole !== 'user') {
        return this.toastr.error(this.translate.instant('only patient form'));
      }
      this.submitted = true;

      if (
        !this.avicernaForm.valid ||
        !this.checkBoxTreatmentList.length ||
        !this.radioWhere
      ) {
        if (!this.checkBoxTreatmentList.length) {
          this.isCheckBoxValid = false;
        }

        if (!this.radioWhere) {
          this.isRadioValid = false;
        }
      } else {
        // ** Submit Form Section
        const formData = {
          ...this.avicernaForm.value,
          whereDidYouHearAboutUs: {
            ...this.avicernaForm.value.whereDidYouHearAboutUs,
            [this.radioWhere]: true,
          },
        };

        this.checkBoxTreatmentList.forEach((treatment) => {
          if (formData.treatment.hasOwnProperty(treatment)) {
            formData.treatment[treatment] = true;
          }
        });

        const otherWhere = formData?.otherWhereInput || '';
        const otherTreatment = formData?.otherTreatmentInput || '';

        delete formData.whereDidYouHearAboutUs.otherWhere;
        formData.whereDidYouHearAboutUs['other'] = otherWhere;
        delete formData.otherWhereInput;

        delete formData.treatment.otherTreatment;
        formData.treatment['other'] = otherTreatment;
        delete formData.otherTreatmentInput;

        const countryName = (id) => {
          const selectedCountry = this.countries.find(
            (country) => country.id == id
          );
          return selectedCountry ? selectedCountry.name : '';
        };
        const cityName = (id) => {
          const selectedCity = this.states.find((state) => state.id == id);
          return selectedCity ? selectedCity.name : '';
        };

        const formRequestBody = {
          ...formData,
          phone: formData.phone.e164Number,
          country: countryName(formData.country),
          city: cityName(formData.state),
          wDYHAboutUs: formData.whereDidYouHearAboutUs,
        };

        delete formRequestBody.state;
        delete formRequestBody.whereDidYouHearAboutUs;

        // console.log(formRequestBody);
        this.sendForm(formRequestBody);
      }
    } else {
      this.router.navigate(['login-page']);
      this.toastr.info(this.translate.instant('please login first'));
    }
  }

  sendForm(body) {
    this.avicerna.sendAvicernaForm(body).subscribe(
      (res) => {
        this.toastr.success('', this.translate.instant('form submitted'));
        this.submitted = false;
        this.avicernaForm.reset();
        location.reload();
      },
      () => this.toastr.error('', this.translate.instant('form dont submit'))
    );
  }
}
