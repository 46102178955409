<header class="obesityHeader position-relative">
  <div
    class="container-fluid veraMonitoringHeaderWrapper d-flex flex-column align-items-start justify-content-center"
  >
    <div class="veraMonitoringHeaderTextWrapper d-flex flex-column gap-3">
      <small>{{ "Obesity" | translate }}</small>
      <h2 class="text-blue">
        {{ "Obesity is Not Your Fate" | translate }}
      </h2>
      <h3 class="text-white">
        {{
          "Choose The Obesity System for Lasting Obesity Solutions" | translate
        }}
      </h3>
    </div>
  </div>
  <div
    class="d-flex flex-column align-items-center position-absolute button-position"
  >
    <a class="routerLink" (click)="scrollTo('obesity-application-form')"
      >{{ "Application Form" | translate }} ></a
    >
  </div>
</header>

<div class="headerResponsive">
  <div
    class="container-fluid veraMonitoringHeaderWrapper d-flex flex-column align-items-start justify-content-center"
  >
    <div class="veraMonitoringHeaderTextWrapper d-flex flex-column gap-3">
      <small>{{ "Obesity" | translate }}</small>
      <h2 class="text-blue">
        {{ "Biotechnology Based Health Solutions" | translate }}
      </h2>
    </div>
  </div>
  <div class="responsiveImg">
    <img
      class="w-100 h-100"
      src="/assets/img/solutions/forms/obesity/obesity-header.jpg"
      alt=""
    />
  </div>
</div>
<div class="section-header">
  <h2>
    {{ "Obesity Preparation Modules" | translate }}
  </h2>
</div>
<div class="d-flex justify-content-center">
  <section class="container-fluid slogan-area-white">
    <div
      class="d-flex flex-wrap gap-4 flex-direction align-items-start justify-content-center"
    >
      <div
        class="icon-box d-flex align-items-center justify-content-center flex-column p-1"
      >
        <div class="icon">
          <img src="/assets/img/solutions/monitoring/icon-19.svg" alt="" />
        </div>
        <div class="icon-text">
          <h2 class="module-header-color">{{ "MODULE" | translate }} 1</h2>
          {{
            "Matrix Configuration with Scales, Finding Resistances and Factors"
              | translate
          }}
        </div>
      </div>
      <div
        class="icon-box d-flex align-items-center justify-content-center flex-column p-1"
      >
        <div class="icon">
          <img src="/assets/img/solutions/monitoring/icon-13.svg" alt="" />
        </div>
        <div class="icon-text">
          <h2 class="module-header-color">{{ "MODULE" | translate }} 2</h2>
          {{ "Health Scan" | translate }}
        </div>
      </div>
      <div
        class="icon-box d-flex align-items-center justify-content-center flex-column p-1"
      >
        <div class="icon">
          <img src="/assets/img/solutions/monitoring/icon-01.svg" alt="" />
        </div>
        <div class="icon-text">
          <h2 class="module-header-color">{{ "MODULE" | translate }} 3</h2>
          {{ "Online Trainings for Consciousness Change" | translate }}
        </div>
      </div>
      <div
        class="icon-box d-flex align-items-center justify-content-center flex-column p-1"
      >
        <div class="icon">
          <img src="/assets/img/solutions/veratele/icon-08.svg" alt="" />
        </div>
        <div class="icon-text">
          <h2 class="module-header-color">{{ "MODULE" | translate }} 4</h2>
          {{
            "Subconscious Change through Individual or Group Sessions"
              | translate
          }}
        </div>
      </div>
    </div>
  </section>
</div>
<div class="section-header">
  <h2>
    {{ "Obesity Solution Modules" | translate }}
  </h2>
</div>
<div class="d-flex justify-content-center">
  <section class="container-fluid list-area">
    <div class="d-flex flex-wrap text-gap flex-direction">
      <div
        class="icon-box d-flex align-items-center justify-content-start flex-column"
      >
        <div class="icon">
          <img src="/assets/img/solutions/hospitals/icon-13.svg" alt="" />
        </div>
        <div class="icon-text text-center">
          <h2 class="module-header-color">{{ "MODULE" | translate }} 5</h2>
          {{
            "Planning Treatments to Achieve Target Weight through Behavior Change"
              | translate
          }}
          <ul class="module-items-list">
            <li class="module-items-list-item">{{ "Detoxes" | translate }}</li>
            <li class="module-items-list-item">
              {{ "Special Diets" | translate }}
            </li>
            <li class="module-items-list-item">
              {{ "Medicines" | translate }}
            </li>
            <li class="module-items-list-item">{{ "Needles" | translate }}</li>
            <li class="module-items-list-item">
              {{ "Herbal Solutions" | translate }}
            </li>
            <li class="module-items-list-item">
              {{ "Balloon" | translate }}, {{ "Botox" | translate }}
            </li>
            <li class="module-items-list-item">
              {{ "Hypnosis" | translate }}, {{ "Acupuncture" | translate }}r
            </li>
            <li class="module-items-list-item">
              {{ "Obesity surgery in resilient patients" | translate }}
            </li>
          </ul>
        </div>
      </div>
      <div
        class="icon-box d-flex align-items-center justify-content-start flex-column"
      >
        <div class="icon">
          <img src="/assets/img/solutions/hospitals/icon-15.svg" alt="" />
        </div>
        <div class="icon-text text-center">
          <h2 class="module-header-color">{{ "MODULE" | translate }} 6</h2>
          {{ "Environment Change" | translate }}
        </div>
      </div>
      <div
        class="icon-box d-flex align-items-center justify-content-start flex-column"
      >
        <div class="icon">
          <img src="/assets/img/solutions/hospitals/icon-15.svg" alt="" />
        </div>
        <div class="icon-text text-center">
          <h2 class="module-header-color">{{ "MODULE" | translate }} 7</h2>
          {{ "Environment and Individual Protection" | translate }}
        </div>
      </div>
    </div>
  </section>
</div>
<div class="section-header-white">
  <h3>
    {{
      "Multidisciplinary Approach for Obesity Permanent Solution with Life Coach, Dietician, Psychologist, Physiotherapist and Doctors"
        | translate
    }}
  </h3>
  <h4 class="mt-3">
    {{
      "T.C. Sağlık Bakanlığının önerdiği Obezite Tedavi Yaklaşımı" | translate
    }}
  </h4>
  <h5 class="mt-3">
    {{ "Sistemin Özellikleri" | translate }}
  </h5>
</div>
<div class="d-flex justify-content-center">
  <section class="container-fluid slogan-area mb-1">
    <div class="d-flex flex-wrap text-gap flex-direction">
      <div
        class="icon-box d-flex align-items-center justify-content-start flex-column"
      >
        <h4 class="icon-box-inner-header pb-4">
          {{
            "Obesity is a personalized problem and a collection of disorders"
              | translate
          }}
        </h4>
        <div class="icon-img">
          <img src="/assets/img/solutions/forms/obesity/img-sec-1.svg" alt="" />
        </div>
        <div class="icon-text text-center">
          {{
            "All your features, history, factors, status, solutions and progress are tracked with real-time online and offline recording and algorithms."
              | translate
          }}
        </div>
      </div>
      <div
        class="icon-box d-flex align-items-center justify-content-start flex-column"
      >
        <h4 class="icon-box-inner-header pb-4">
          {{
            "Streamlined designs tailored to experts and clients" | translate
          }}
        </h4>
        <div class="icon-img">
          <img src="/assets/img/solutions/forms/obesity/img-sec-2.svg" alt="" />
        </div>
        <div class="icon-text text-center">
          {{
            "In the Obesity System, diets, exercises, sorrows and failures that force you are forbidden."
              | translate
          }}
        </div>
      </div>
      <div
        class="icon-box d-flex align-items-center justify-content-start flex-column"
      >
        <h4 class="icon-box-inner-header pb-4">
          {{
            "Permanent solution, not acute or temporary weight loss" | translate
          }}
        </h4>
        <div class="icon-img">
          <img src="/assets/img/solutions/forms/obesity/img-sec-3.svg" alt="" />
        </div>
        <div class="icon-text text-center">
          {{
            "The Obesity System is the world's most comprehensive and algorithmic lifestyle change program, developed for 100% success and a permanent solution."
              | translate
          }}
        </div>
      </div>
    </div>
  </section>
</div>
<div class="d-flex justify-content-center">
  <section class="container-fluid slogan-area mb-6">
    <div class="d-flex flex-wrap text-gap flex-direction">
      <div
        class="icon-box d-flex align-items-center justify-content-start flex-column"
      >
        <h4 class="icon-box-inner-header pb-4">
          {{ "If you want, you can get all modules in bundles" | translate }}
        </h4>
        <div class="icon-img pt-5">
          <img src="/assets/img/solutions/forms/obesity/img-sec-1.svg" alt="" />
        </div>
        <div class="icon-text text-center">
          {{
            "You can get a complete package solution with a life coach, dietician, psychologist, physiotherapist and doctors, and you can plan additional treatments such as medication, phytotherapy, needle treatments, botox, balloon, surgery and all interventional procedures either with us or elsewhere."
              | translate
          }}
        </div>
      </div>
      <div
        class="icon-box d-flex align-items-center justify-content-start flex-column"
      >
        <h4 class="icon-box-inner-header pb-4">
          {{
            "You can get only certain modules individually or several at once"
              | translate
          }}
        </h4>
        <div class="icon-img pt-4">
          <img src="/assets/img/solutions/forms/obesity/img-sec-2.svg" alt="" />
        </div>
        <div class="icon-text text-center">
          {{
            "You can receive the services of a life coach, dietitian, psychologist, physiotherapist or doctor one by one. Additional treatments such as medication, phytotherapy, injection therapies, Botox, balloons, surgery and all interventional procedures can be planned with us or elsewhere."
              | translate
          }}
        </div>
      </div>
      <div
        class="icon-box d-flex align-items-center justify-content-start flex-column"
      >
        <h4 class="icon-box-inner-header pb-4">
          {{
            "You can use all software that does not require specialized and additional treatment in the system free of charge for life"
              | translate
          }}
        </h4>
        <div class="icon-img">
          <img src="/assets/img/solutions/forms/obesity/img-sec-3.svg" alt="" />
        </div>
        <div class="icon-text text-center">
          {{
            "As ready-made modules within the software, health parameter tracking, use of scales, matrix assessments, free trainings and information can be used free of charge."
              | translate
          }}
        </div>
      </div>
    </div>
  </section>
</div>
<div class="section-header">
  <h2>
    {{ "7/24 Patient Call Line" | translate }}
  </h2>
</div>
<div class="d-flex justify-content-center">
  <section class="container list-area mb-6">
    <div class="row my-5">
      <div class="col-lg-6 d-flex justify-content-center flex-column px-3">
        <div class="list-title">
          {{ "ACCESSIBLE" | translate }}
        </div>
        <div class="blue-line"></div>
        <div class="row">
          <div class="col-1 d-flex align-items-center">
            <div class="blue-dot"></div>
          </div>
          <div class="col-10">
            <div class="list-desc">
              {{
                "Online consultation, call center, e-mail, whatsapp and software are always available for easy access and interactive treatment companionship."
                  | translate
              }}
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4 d-flex justify-content-center align-items-center">
        <img
          class="w-75 rounded-circle"
          src="/assets/img/solutions/hospitals/Solutions---53-.jpeg"
          alt=""
        />
      </div>
    </div>
    <div class="row my-5">
      <div class="col-lg-6 d-flex justify-content-center flex-column px-3">
        <div class="list-title">
          {{ "CORRECT" | translate }}
        </div>
        <div class="blue-line"></div>
        <div class="row">
          <div class="col-1 d-flex align-items-center">
            <div class="blue-dot"></div>
          </div>
          <div class="col-10">
            <div class="list-desc">
              {{
                "Obesity and weight problems are the world's most commented-upon area and full of misconceptions. In our system, you will always find the most up-to-date, evidence-based, proven effective and efficient treatments and recommendations, free from superstition and advertising."
                  | translate
              }}
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4 d-flex justify-content-center align-items-center">
        <img
          class="w-75 rounded-circle"
          src="/assets/img/solutions/monitoring/Solutions---50-.jpeg"
          alt=""
        />
      </div>
    </div>
    <div class="row my-5">
      <div class="col-lg-6 d-flex justify-content-center flex-column px-3">
        <div class="list-title">
          {{ "INTERACTIVE" | translate }}
        </div>
        <div class="blue-line"></div>
        <div class="row">
          <div class="col-1 d-flex align-items-center">
            <div class="blue-dot"></div>
          </div>
          <div class="col-10">
            <div class="list-desc">
              {{
                "With YOLDAŞ HEKIMLİK, the service model of the future in healthcare, you will receive advice, treatment and assistance that understands and analyzes you better than you do and goes one step ahead."
                  | translate
              }}
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4 d-flex justify-content-center align-items-center">
        <img
          class="w-75 rounded-circle"
          src="/assets/img/solutions/monitoring/Solutions---51-.jpeg"
          alt=""
        />
      </div>
    </div>
  </section>
</div>
<section
  class="container-fluid slogan-area mb-6 p-4"
  id="obesity-application-form"
>
  <div class="d-flex flex-column align-items-center justify-content-center">
    <div
      class="fw-bolder pt-3 pb-3 fs-4 text-center w-100"
      style="font-size: 1.5rem"
    >
      {{ "Application Form" | translate }}
    </div>
    <div class="w-75">
      <app-obesity-form></app-obesity-form>
    </div>
  </div>
</section>
