<div class="container">
  <div class="container">
    <div class="card my-5 mx-2 w-100">
      <div class="card-header text-center">
        <h1>{{"Hybrid Healthcare You Can Provide" | translate}}</h1>
        <h2>{{"VERADoctors for Government" | translate}}</h2>
      </div>
      <div class="card-body px-5 col-md-12 col-sm-12 w-100 text-center" style="color: #000; text-align: justify;">
        <p class="desc">
          {{"Virtual healthcare can guarantee agencies improved health outcomes, quicker response times, and low costs." | translate}}
        </p>
        <div class="row mt-3">
          <div class="col-md-6 col-sm-12">
            <img src="/assets/images/governments/government.jpg" alt="">
          </div>
          <div class="col-md-6 col-sm-12" style="align-items: center;">
            <div class="red text-center">
              <h3>
                {{"Full Spectrum" | translate}}
              </h3>
            </div>
            <div class="text-left mt-3">
              <ul>
                <li>{{"Our cases offer full spectrum usage across the continuum of healthcare" | translate}}</li>
                <li>{{"Create efficient workflow with our integrated systems and software. Test and get results in real time, wherever you are located." | translate}}</li>
                <li>{{"VERA’s system is built with top of the line security and is HIPAA and GDPR compliant. All interactions and communications through our systems are encrypted." | translate}}</li>
              </ul>
            </div>
          </div>
        </div>
        <div class="row mt-5 mb-3 desc">
          <h3 style="color: #4365ef;"><i class="fa fa-th-list" aria-hidden="true"></i> {{"Features" | translate}}</h3>
          <div class="row">
            <div class="col-sm-12 col-md-6 text-left">
              <ul>
                <li>{{"Diagnostic Capabilities" | translate}}</li>
                <li>{{"Incorporating file sharing" | translate}}</li>
                <li>{{"SMS Messaging" | translate}}</li>
                <li>{{"Whiteboard" | translate}}</li>
                <li>{{"On-Demand Recording" | translate}}</li>
              </ul>
            </div>
            <div class="col-sm-12 col-md-6 text-left">
              <ul>
                <li>{{"API to the Hospitals electronic health records" | translate}}</li>
                <li>{{"Patient portal with direct access to labs" | translate}}</li>
                <li>{{"Pharmacies and others allow seamless integration throughout the care spectrum" | translate}}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
