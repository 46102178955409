<div class="container">
  <div class="row">
    <div class="card my-5 mx-2 w-100">
      <div class="card-header text-center">
        <h1>{{"Hybrid Healthcare where it counts" | translate}}</h1>
        <h2>{{"Airlines, Cruise Ships, and private Transport Companies" | translate}}</h2>
      </div>
      <div class="card-body px-5 col-md-12 col-sm-12 w-100 text-center" style="color: #000; text-align: justify;">
        <p class="desc">{{"Whether you are in the sky, sea, or on the road, your guests and staff are always in safe
          hands with VERADoctors." | translate}}
        </p>
        <div class="row mt-1">
          <div class="col-md-6 col-sm-12">
            <img src="/assets/images/transport-companies/patient.jpg" alt="">
          </div>
          <div class="col-md-6 col-sm-12" style="align-items: center;">
            <div class="red text-center" style="margin-top: .25rem;">
              <h3>
                {{"YOUR STAFF" | translate}} <br><span>{{"will be equipped with the best virtual healthcare tech" | translate}}</span>
              </h3>
            </div>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-md-6 col-sm-12">
            <div class="red text-center">
              <h3>
                {{"OPTIMIZE CARE QUICKLY" | translate}} <br><span>{{"with in-flight virtual visits and live diagnostics" | translate}}</span>
              </h3>
            </div>
          </div>
          <div class="col-md-6 col-sm-12 mt-1">
            <img src="/assets/images/transport-companies/airplane.jpg" alt="">
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-md-6 col-sm-12">
            <img src="/assets/images/transport-companies/dr-writing.jpg" alt="">
          </div>
          <div class="col-md-6 col-sm-12">
            <div class="red text-center">
              <h3>
                {{"HAVE THE TOOLS" | translate}} <br><span>{{"to run tests and give specialists the results in real-time" | translate}}</span>
              </h3>
            </div>
          </div>
        </div>
        <div class="row mt-5 mb-3 desc">
          <h3 style="color: #4365ef;"><i class="fa fa-th-list" aria-hidden="true"></i> {{"Features" | translate}}</h3>
          <div class="row">
            <div class="col-sm-12 col-md-6 text-left">
              <ul>
                <li>{{"Diagnostic Capabilities" | translate}}</li>
                <li>{{"Incorporating file sharing" | translate}}</li>
                <li>{{"SMS Messaging" | translate}}</li>
                <li>{{"Whiteboard" | translate}}</li>
                <li>{{"On-Demand Recording" | translate}}</li>
              </ul>
            </div>
            <div class="col-sm-12 col-md-6 text-left">
              <ul>
                <li>{{"API to the Hospitals electronic health records" | translate}}</li>
                <li>{{"Patient portal with direct access to labs" | translate}}</li>
                <li>{{"Pharmacies and others allow seamless integration throughout the care spectrum" | translate}}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
