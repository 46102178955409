import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { url } from 'src/config/http';

@Injectable({ providedIn: 'root' })
export class headerService {
  private slanguage: BehaviorSubject<string>;
  selectedlanguage: Observable<string>;

  constructor(private httpclient: HttpClient) {
    this.slanguage = new BehaviorSubject<string>(
      window.navigator.language.split('-')[0]
    );

    this.selectedlanguage = this.slanguage.asObservable();

    localStorage.setItem('slanguage', 'en');
  }

  GetLanguages(): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    return this.httpclient.get(url + '/languages', httpOptions);
  }

  setLanguage(language: string) {
    localStorage.setItem('slanguage', language);
    this.slanguage.next(language);
  }
}
