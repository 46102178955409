import { Component, OnInit } from '@angular/core';
import { LanguageService } from 'src/app/services/language.service';

@Component({
  selector: 'app-informedconsent',
  templateUrl: './informedconsent.component.html',
  styleUrls: ['./informedconsent.component.css']
})
export class InformedconsentComponent implements OnInit {

  constructor(private languageSer: LanguageService) { }

  languageSelect;

  ngOnInit(): void {
    this.languageSer.getSelectedLanguage().subscribe(res =>{
      this.languageSelect = res
    })
  }

}
