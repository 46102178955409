import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { url } from 'src/config/http';

@Injectable({
  providedIn: 'root',
})
export class ObesityFormService {
  constructor(private httpClient: HttpClient) {}

  sendObesityForm = (body) => {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-auth': localStorage.getItem('xauth'),
      }),
      observe: 'response' as 'response',
    };

    return this.httpClient.post(url + `/yah-form`, body, options);
  };

  getForms = () => {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-auth': localStorage.getItem('xauth'),
      }),
      observe: 'response' as 'response',
    };

    return this.httpClient.get(url + `/yah-form`, options);
  };

  deleteForm(id) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-auth': localStorage.getItem('xauth'),
      }),
    };
    return this.httpClient.delete(url + `/yah-form/${id}`, options);
  }

  getDoctors = (hospitalId) => {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-auth': localStorage.getItem('xauth'),
      }),
      observe: 'response' as 'response',
    };

    return this.httpClient.get(
      url + `/doctors?skip=0&limit=100&hospitalId=${hospitalId || ''}`,
      options
    );
  };
}
