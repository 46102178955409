<div class="container">
  <div class="row">
    <div class="card my-5 w-100 mx-2">
      <div class="card-body col-md-12 col-sm-12 w-100" style="color: #000; text-align: justify;">
        <div class="card-title text-center">
          <h1 class="text-center hiw-title">
            {{"WHAT do we provide?" |translate}}
          </h1>
        </div>
        <div>
          <div class="row w-100">
          </div>
          <div class="row d-flex flex-row px-auto">
            <img src="assets/images/infoPages/medical_illus2.jpg" alt="">
            <div class="flex-1 mx-auto">
              <ul class="desc m-5">
                <li>{{"Urgent Care" |translate}}</li>
                <li>{{"COVID-19" |translate}}</li>
                <li>{{"Primary Care" |translate}}</li>
                <li>{{"Women’s Health" |translate}}</li>
                <li>{{"Men’s Health" |translate}}</li>
              </ul>
            </div>
            <div class="flex-1 mx-auto">
              <ul class="desc m-5">
                <li>{{"Talk Therapy" |translate}}</li>
                <li>{{"Teen Therapy" |translate}}</li>
                <li>{{"Psychiatry" |translate}}</li>
                <li>{{"In-Home Nurse" |translate}}</li>
                <li>{{"Bedside Care" |translate}}</li>
              </ul>
            </div>
          </div>
          <div class="row w-100">
            <img src="assets/images/infoPages/medical_illus.jpg" alt="">
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
