import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import {
  cerezPolitikasi,
  hastaAydinlatmaMetni,
  ilgiliKisiBasvuruFormu,
  kisiselVerileriImhaPolitikasi,
  kisiselVerilerinKorunmasiVeIslenmesiPolitikasi,
  ozelNitelikliVerilerBilgiNotu,
  veraIlgiliKisiAcikRizaMetniSablonuGoruntuVeSesKaydi,
  veraIlgiliKisiAcikRizaMetniSablonuSaglik,
} from './kvkk-pages.component';

@Component({
  selector: 'app-kvkk',
  templateUrl: './kvkk.component.html',
  styleUrls: ['./kvkk.component.css'],
})
export class KVKKComponent implements OnInit {
  constructor(private readonly sanitizer: DomSanitizer) {}

  activePage = null;

  // Get the active page from url
  getActivePage() {
    const url = window.location.href;
    const urlSplit = url.split('#');
    if (urlSplit.length > 1) {
      this.activePage = urlSplit[1];
    }
  }

  getPage(page: string) {
    return this.sanitizer.bypassSecurityTrustHtml(page);
  }

  links: object[] = [
    {
      title: 'Aydınlatma Metni',
      contentId: 'hasta-aydinlatma-metni',
      page: this.getPage(hastaAydinlatmaMetni),
    },
    {
      title: 'Kişisel Verilerin Korunması ve İşlenmesi Politikası',
      contentId: 'kisisel-verilerin-korunmasi-ve-islenmesi-politikasi',
      page: this.getPage(kisiselVerilerinKorunmasiVeIslenmesiPolitikasi),
    },
    {
      title: 'Özel Nitelikli Veriler Bilgi Notu',
      contentId: 'ozel-nitelikli-veriler-bilgi-notu',
      page: this.getPage(ozelNitelikliVerilerBilgiNotu),
    },
    {
      title: 'Kişisel Verileri İmha Politikası',
      contentId: 'kisisel-verileri-imha-politikasi',
      page: this.getPage(kisiselVerileriImhaPolitikasi),
    },
    {
      title: 'Çerez Politikası',
      contentId: 'cerez-politikasi',
      page: this.getPage(cerezPolitikasi),
    },
    {
      title: 'İlgili Kişi Başvuru Formu',
      contentId: 'ilgili-kisi-basvuru-formu',
      page: this.getPage(ilgiliKisiBasvuruFormu),
    },
  ];

  applyActiveClass(link: object) {
    if (link['contentId'] === this.activePage) {
      return 'active';
    }
    return '';
  }

  ngOnInit(): void {
    console.log(window.location.href);
    window.scrollTo(0, 0);
    this.getActivePage();
  }
}
