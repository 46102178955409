<header class="avicernaHeader position-relative">
  <div
    class="container-fluid veraMonitoringHeaderWrapper d-flex flex-column align-items-start justify-content-center"
  >
    <div class="veraMonitoringHeaderTextWrapper d-flex flex-column gap-3">
      <small>Avicerna</small>
      <h2 class="text-blue">
        {{ "Biotechnology Based Health Solutions" | translate }}
      </h2>
    </div>
  </div>
  <div
    class="d-flex flex-column align-items-center position-absolute button-position"
  >
    <!-- <button class="btnHeader">
      {{ "Application Form" | translate }}
    </button> -->
    <a class="routerLink" (click)="scrollTo('avicerna-application-form')"
      >{{ "Application Form" | translate }} ></a
    >
  </div>
</header>

<div class="headerResponsive">
  <div
    class="container-fluid veraMonitoringHeaderWrapper d-flex flex-column align-items-start justify-content-center"
  >
    <div class="veraMonitoringHeaderTextWrapper d-flex flex-column gap-3">
      <small>Avicerna</small>
      <h2 class="text-blue">
        {{ "Biotechnology Based Health Solutions" | translate }}
      </h2>
    </div>
  </div>
  <div class="responsiveImg">
    <img
      class="w-100 h-100"
      src="/assets/img/solutions/hospitals/verahospitals-header.jpeg"
      alt=""
    />
  </div>
</div>
<div class="d-flex justify-content-center">
  <section class="container-fluid slogan-area mb-6">
    <div class="d-flex flex-wrap text-gap flex-direction">
      <div
        class="icon-box d-flex align-items-center justify-content-start flex-column"
      >
        <div class="icon">
          <img src="/assets/img/solutions/hospitals/icon-13.svg" alt="" />
        </div>
        <div class="icon-text text-center">
          {{
            "Discover the transformative power of stem cell treatments. Our most advanced biotechnologies promise to not only alleviate symptoms, but also regenerate cells and rejuvenate damaged tissues."
              | translate
          }}
        </div>
      </div>
      <div
        class="icon-box d-flex align-items-center justify-content-start flex-column"
      >
        <div class="icon">
          <img src="/assets/img/solutions/hospitals/icon-15.svg" alt="" />
        </div>
        <div class="icon-text text-center">
          {{
            "We are pioneering solutions to a wide range of problems, from heart disease and diabetes to cancers such as leukemia, rheumatoid arthritis pain, stroke and post-injury."
              | translate
          }}
        </div>
      </div>
      <div
        class="icon-box d-flex align-items-center justify-content-start flex-column"
      >
        <div class="icon">
          <img src="/assets/img/solutions/hospitals/icon-16.svg" alt="" />
        </div>
        <div class="icon-text text-center">
          {{
            "Embrace a future where stem cell innovations bring you closer to a healthier and renewed life."
              | translate
          }}
        </div>
      </div>
    </div>
  </section>
</div>
<div class="d-flex justify-content-center">
  <section class="container list-area mb-6">
    <div class="row my-5">
      <div class="col-lg-6 d-flex justify-content-center flex-column px-3">
        <div class="list-title">
          {{ "Personalized Implementation Plans" | translate }}
        </div>
        <div class="blue-line"></div>
        <div class="row">
          <div class="col-1 d-flex align-items-center">
            <div class="blue-dot"></div>
          </div>
          <div class="col-10">
            <div class="list-desc">
              {{
                "Our specialists support you to achieve the best results by developing personalized treatment plans based on your health condition and aesthetic goals."
                  | translate
              }}
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4 d-flex justify-content-center align-items-center">
        <img
          class="w-75 rounded-circle"
          src="/assets/img/solutions/hospitals/Solutions---53-.jpeg"
          alt=""
        />
      </div>
    </div>
    <div class="row my-5">
      <div class="col-lg-6 d-flex justify-content-center flex-column px-3">
        <div class="list-title">
          {{ "Fight against Chronic Diseases" | translate }}
        </div>
        <div class="blue-line"></div>
        <div class="row">
          <div class="col-1 d-flex align-items-center">
            <div class="blue-dot"></div>
          </div>
          <div class="col-10">
            <div class="list-desc">
              {{
                "Stem cell treatment allows you to effectively fight chronic diseases and improve your quality of life."
                  | translate
              }}
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4 d-flex justify-content-center align-items-center">
        <img
          class="w-75 rounded-circle"
          src="/assets/img/solutions/hospitals/Solutions---54-.jpeg"
          alt=""
        />
      </div>
    </div>
    <div class="row my-5">
      <div class="col-lg-6 d-flex justify-content-center flex-column px-3">
        <div class="list-title">
          {{ "Long Term Benefits" | translate }}
        </div>
        <div class="blue-line"></div>
        <div class="row">
          <div class="col-1 d-flex align-items-center">
            <div class="blue-dot"></div>
          </div>
          <div class="col-10">
            <div class="list-desc">
              {{
                "Aesthetic stem cell applications offer permanent and long-lasting effects to rejuvenate and tighten your skin."
                  | translate
              }}
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4 d-flex justify-content-center align-items-center">
        <img
          class="w-75 rounded-circle"
          src="/assets/img/solutions/hospitals/Solutions---55-.jpeg"
          alt=""
        />
      </div>
    </div>
  </section>
</div>
<section
  class="container-fluid slogan-area mb-6 p-4"
  id="avicerna-application-form"
>
  <div class="d-flex flex-column align-items-center justify-content-center">
    <!-- <div
      class="fw-bolder pt-3 pb-3 fs-4 text-center w-100"
      style="font-size: 1.5rem"
    >
      www.avicerna.com
    </div> -->
    <div
      class="fw-bolder pt-3 pb-3 fs-4 text-center w-100"
      style="font-size: 1.5rem"
    >
      {{ "Application Form" | translate }}
    </div>
    <div class="w-75">
      <app-avicerna-form></app-avicerna-form>
    </div>
  </div>
</section>
