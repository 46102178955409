import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-kvkk',
  templateUrl: './kvkk.component.html',
  styleUrls: ['./kvkk.component.css']
})
export class KvkkComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
