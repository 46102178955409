<div class="container">
  <div class="card my-5 w-100">
    <div class="card-header text-center">
      <h1>{{"Hybrid Healthcare You Can Provide" | translate}}</h1>
      <h2>{{"Insurance Companies" | translate}}</h2>
    </div>
    <div class="card-body px-5 col-md-12 col-sm-12 w-100 text-center" style="color: #000; text-align: justify;">
      <p class="desc mt-5">
        {{"VERADoctors works alongside your insurance to give you the most affordable prices and makes sure that everyone has the opportunity to book the services they need without having to jump through insurance company hoops." | translate}}
      </p>
      <p class="desc mt-4 mb-4">
        {{"Depending on your coverage, your insurance may reimburse you for appointments and medication. VERADoctors will provide you with a receipt to give to your insurance for reimbursement." | translate}}
      </p>
      <p class="desc mb-5">
        {{"We also accept HSA and FSA payments for appointments and medication." | translate}}
      </p>
    </div>
  </div>
</div>
