<footer class="footer">
  <!-- Footer Top -->
  <div class="footer-top">
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-4 col-md-6">
          <!-- Footer Widget -->
          <div class="footer-widget footer-about h-100">
            <div class="footer-logo">
              <img
                src="assets/img/footer-logo.png"
                alt="footer logo"
                class="w-100"
              />
            </div>

            <div class="stores row mb-4">
              <div class="d-flex">
                <a
                  href="https://play.google.com/store/apps/details?id=com.app.veradoctors&hl=en_US&gl=US"
                  target="_blank"
                  class="mx-2"
                >
                  <img
                    width="150px"
                    src="/assets/images/google-app-store/google-play-badge.svg"
                    alt=""
                  />
                </a>
                <a
                  href="https://apps.apple.com/mt/app/vera-doctors/id1588552524"
                  target="_blank"
                  class="mx-2"
                >
                  <img
                    width="150px"
                    src="/assets/images/google-app-store/353410.svg"
                    alt=""
                  />
                </a>
              </div>
            </div>

            <div class="footer-about-content">
              <p>
                {{
                  "Our aim is to take away as many of your concerns as possible. Please do not hesitate to contact us
                if you have any questions." | translate
                }}
              </p>
            </div>
            <div class="footer-about-content py-3">
              <h4 class="text-white">Çağrı Merkezi</h4>
              <div class="d-flex py-1">
                <p class="m-0 p-0">Vera Doctors:</p>
                <p class="m-0 p-0 pl-1">0216 888 76 80</p>
              </div>
              <div class="d-flex py-1">
                <p class="m-0 p-0">Vera Nurses:</p>
                <p class="m-0 p-0 pl-1">0216 888 76 80</p>
              </div>
            </div>
            <div class="social-icon">
              <ul>
                <li>
                  <a
                    href="https://www.facebook.com/vera.doctors"
                    target="_blank"
                    ><i class="fab fa-facebook-f"></i>
                  </a>
                </li>
                <li>
                  <a href="https://twitter.com/doctorsvera" target="_blank"
                    ><i class="fab fa-twitter"></i>
                  </a>
                </li>
                <!-- <li>
                  <a><i class="fab fa-linkedin-in"></i></a>
                </li> -->
                <li>
                  <a
                    href="https://www.instagram.com/veradoctors/"
                    target="_blank"
                    ><i class="fab fa-instagram"></i
                  ></a>
                </li>
                <li>
                  <a
                    href="https://www.youtube.com/channel/UCMONbt0LqatcfbviOMvKAUQ"
                    target="_blank"
                    ><i class="fab fa-youtube"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <!-- /Footer Widget -->
        </div>

        <div class="col-lg-2 col-md-6">
          <div class="footer-widget footer-menu">
            <h6 class="footer-title">{{ "Company" | translate }}</h6>
            <ul>
              <li>
                <a
                  href="javascript:void(0);"
                  [routerLink]="'/executive-team'"
                  >{{ "Executive Team" | translate }}</a
                >
              </li>
              <li>
                <a href="javascript:void(0);" [routerLink]="'/who-we-are'">{{
                  "Who we are" | translate
                }}</a>
              </li>
              <li>
                <a href="javascript:void(0);" [routerLink]="'/blog'">{{
                  "Blog" | translate
                }}</a>
              </li>
              <li>
                <a
                  href="javascript:void(0);"
                  [routerLink]="'/work-in-progress'"
                  >{{ "News & Media" | translate }}</a
                >
              </li>
              <li>
                <a href="javascript:void(0);" [routerLink]="'/partners'">{{
                  "Partners" | translate
                }}</a>
              </li>
            </ul>
          </div>
        </div>

        <div class="col-lg-2 col-md-6">
          <div class="footer-widget footer-menu">
            <h6 class="footer-title">{{ "Legal" | translate }}</h6>
            <ul>
              <!-- <li><a href="javascript:void(0);" [routerLink]="'terms-of-use'">{{"Terms of use" | translate}}</a></li> -->
              <li>
                <a href="javascript:void(0);" (click)="openModal('terms')">{{
                  "Terms of Use" | translate
                }}</a>
              </li>
              <li>
                <a href="javascript:void(0);" (click)="openModal('privacy')">{{
                  "Privacy Policy" | translate
                }}</a>
              </li>
              <!-- <li><a href="javascript:void(0);" [routerLink]="'privacy-policy'">{{"Privacy Policy" | translate}}</a></li> -->
              <li>
                <a
                  href="javascript:void(0);"
                  (click)="openModal('informed-consent')"
                  >{{ "Vera Informed" | translate }}</a
                >
              </li>
              <!-- <li><a href="javascript:void(0);" [routerLink]="'informed-consent'">{{"Vera Informed" | translate}}</a></li> -->
              <li>
                <a
                  href="javascript:void(0);"
                  (click)="openModal('disclaimer')"
                  >{{ "Disclaimer" | translate }}</a
                >
              </li>

              <li>
                <a href="mailto:info@veradoctors.com?subject=Contact%Us">{{
                  "Contact Us" | translate
                }}</a>
              </li>
              <!-- <li><a href="javascript:void(0);" [routerLink]="'disclaimer'">{{"Disclaimer" | translate}}</a></li> -->
              <!-- <li *ngIf="languageSelect == 'tr'"><a href="javascript:void(0);" (click)="openModal('kvkk')">{{"KVKK" | translate}}</a></li>
              <li *ngIf="languageSelect == 'tr'"><a href="javascript:void(0);" (click)="openModal('hastaaydinlatma')">{{"KVKK Hasta Aydınlatma Metni" | translate}}</a></li>
              <li *ngIf="languageSelect == 'tr'"><a href="javascript:void(0);" (click)="openModal('acikrizametnisesvegoruntu')">{{"KVKK Açık Rıza Metni (Ses ve Görüntü Görüşme Verileri)" | translate}}</a></li>
              <li *ngIf="languageSelect == 'tr'"><a href="javascript:void(0);" (click)="openModal('acikrizametnisaglikverileri')">{{"KVKK Açık Rıza Metni (Sağlık Verileri)" | translate}}</a></li>
              <li *ngIf="languageSelect == 'tr'"><a href="javascript:void(0);" (click)="openModal('ozelnitelikliverilerbilginotu')">{{"Özel Nitelikli Veriler Bilgi Notu" | translate}}</a></li>
              <li *ngIf="languageSelect == 'tr'"><a href="javascript:void(0);" (click)="openModal('kisiselverilerinkorunmasiveislenmesi')">{{"Kişisel Verilerin Korunması ve İşlenmesi" | translate}}</a></li>
              <li *ngIf="languageSelect == 'tr'"><a href="javascript:void(0);" (click)="openModal('kisiselverisaklamaveimhapolitikasi')">{{"Kişisel Veri Saklama ve İmha Politikası" | translate}}</a></li>
              <li *ngIf="languageSelect == 'tr'"><a href="javascript:void(0);" (click)="openModal('kvkkcerezvegizlilikpolitikasi')">{{"KVKK Kapsamında Çerez ve Gizlilik Politikası" | translate}}</a></li>
              <li *ngIf="languageSelect == 'tr'"><a href="javascript:void(0);" (click)="openModal('kvkkilgilikisibasvuruformu')">{{"KVKK İlgili Kişi Başvuru Formu" | translate}}</a></li> -->

              <!-- <ngx-doc-viewer></ngx-doc-viewer> -->
            </ul>
          </div>
        </div>

        <div class="col-lg-4 col-md-6">
          <!-- Footer Widget -->
          <div class="footer-widget footer-contact">
            <h6 class="footer-title">{{ "Contact Us" | translate }}</h6>
            <div
              class="footerContact d-flex flex-column gap-3 align-items-center"
            >
              <div class="footerMap position-relative w-100">
                <div
                  class="svg w-100"
                  [innerHTML]="svg"
                  style="max-width: 500px"
                  (click)="openZendeskChatbot()"
                ></div>
              </div>
            </div>
          </div>
          <!-- /Footer Widget -->
        </div>
      </div>
    </div>
  </div>
  <!-- /Footer Top -->

  <!-- Footer Bottom -->
  <div class="footer-bottom">
    <div class="container-fluid">
      <!-- Copyright -->
      <div class="copyright">
        <div class="row">
          <div class="col-md-6 col-lg-6">
            <div class="copyright-text">
              <p class="mb-0">
                © 2021 VERADoctors. {{ "All rights reserved." | translate }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <!-- /Copyright -->
    </div>
  </div>

  <!-- /Footer Bottom -->
</footer>
<ng-template #termsprivacy let-modal>
  <div class="modal-header">
    <button
      type="button"
      class="close"
      data-dismiss="modal"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <app-privacy *ngIf="relatedString == 'privacy'"></app-privacy>
    <app-termsofuse *ngIf="relatedString == 'terms'"></app-termsofuse>
    <app-informedconsent
      *ngIf="relatedString == 'informed-consent'"
    ></app-informedconsent>
    <app-disclaimershare
      *ngIf="relatedString == 'disclaimer'"
    ></app-disclaimershare>
    <app-kvkk *ngIf="relatedString == 'kvkk'"></app-kvkk>
    <div
      class="hasta-aydınlatma"
      *ngIf="relatedString == 'hastaaydinlatma'"
      [innerHTML]="hastaaydinlatma"
    ></div>
    <div
      class="acikrizametni"
      *ngIf="relatedString == 'acikrizametnisesvegoruntu'"
      [innerHTML]="acikrizametnisesvegoruntu"
    ></div>
    <div
      class="acikrizametni"
      *ngIf="relatedString == 'acikrizametnisaglikverileri'"
      [innerHTML]="acikrizametnisaglikverileri"
    ></div>
    <div
      class="acikrizametni"
      *ngIf="relatedString == 'ozelnitelikliverilerbilginotu'"
      [innerHTML]="ozelnitelikliverilerbilginotu"
    ></div>
    <div
      class="acikrizametni"
      *ngIf="relatedString == 'kisiselverilerinkorunmasiveislenmesi'"
      [innerHTML]="kisiselverilerinkorunmasiveislenmesi"
    ></div>
    <div
      class="acikrizametni"
      *ngIf="relatedString == 'kisiselverisaklamaveimhapolitikasi'"
      [innerHTML]="kisiselverisaklamaveimhapolitikasi"
    ></div>
    <div
      class="acikrizametni"
      *ngIf="relatedString == 'kvkkcerezvegizlilikpolitikasi'"
      [innerHTML]="kvkkcerezvegizlilikpolitikasi"
    ></div>
    <div
      class="acikrizametni"
      *ngIf="relatedString == 'kvkkilgilikisibasvuruformu'"
      [innerHTML]="kvkkilgilikisibasvuruformu"
    ></div>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-light"
      (click)="modal.dismiss('Cross click')"
    >
      {{ "Close" | translate }}
    </button>
  </div>
</ng-template>
