import { Component, OnInit } from '@angular/core';
import { LanguageService } from 'src/app/services/language.service';

@Component({
  selector: 'app-frequently-asked-questions',
  templateUrl: './frequently-asked-questions.component.html',
  styleUrls: ['./frequently-asked-questions.component.css']
})
export class FrequentlyAskedQuestionsComponent implements OnInit {

  constructor(private languageSer: LanguageService) { }

  languageSelect;
  ngOnInit(): void {
    window.scrollTo(0, 0);
    this.languageSer.getSelectedLanguage().subscribe(res =>{
      this.languageSelect = res
    })
  }

}
