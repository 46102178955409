<form [formGroup]="obesityForm" (ngSubmit)="onSubmit()">
  <div class="w-100 flex row">
    <div class="form-group col-6">
      <label for="height">
        {{ "Height" | translate }} <span class="text-danger"> *</span>
      </label>
      <input
        type="number"
        step="0.1"
        min="1"
        max="300"
        class="form-control"
        formControlName="height"
        id="height"
        placeholder="{{ 'Enter your height' | translate }}"
      />
      <div
        *ngIf="submitted && obesityForm.controls.height.errors?.required"
        class="text-danger"
      >
        <div *ngIf="submitted && obesityForm.controls.height.errors?.required">
          {{ "Height is required" | translate }}
        </div>
      </div>
      <div
        *ngIf="
          submitted &&
          (obesityForm.controls.height.errors?.min?.min ||
            obesityForm.controls.height.errors?.max?.max)
        "
        class="text-danger"
      >
        <div
          *ngIf="
            submitted &&
            !obesityForm.controls.height.errors?.pattern &&
            (obesityForm.controls.height.errors?.min ||
              obesityForm.controls.height.errors?.max)
          "
        >
          {{ "Height must be between 0 and 300 cm" | translate }}
        </div>
      </div>
      <div
        *ngIf="submitted && obesityForm.controls.height.errors?.pattern"
        class="text-danger"
      >
        <div *ngIf="submitted && obesityForm.controls.height.errors?.pattern">
          {{
            "Height must be a valid number with up to 1 decimal places"
              | translate
          }}
        </div>
      </div>
    </div>
    <div class="form-group col-6">
      <label for="weight">
        {{ "Weight" | translate }} <span class="text-danger"> *</span>
      </label>
      <input
        type="number"
        step="0.1"
        min="1"
        max="500"
        class="form-control"
        formControlName="weight"
        id="weight"
        placeholder="{{ 'Enter your weight' | translate }}"
      />
      <div
        *ngIf="submitted && obesityForm.controls.weight.errors?.required"
        class="text-danger"
      >
        <div *ngIf="submitted && obesityForm.controls.weight.errors?.required">
          {{ "Weight is required" | translate }}
        </div>
      </div>
      <div
        *ngIf="
          submitted &&
          !obesityForm.controls.weight.errors?.pattern &&
          (obesityForm.controls.weight.errors?.min ||
            obesityForm.controls.weight.errors?.max)
        "
        class="text-danger"
      >
        <div
          *ngIf="
            submitted &&
            !obesityForm.controls.weight.errors?.pattern &&
            (obesityForm.controls.weight.errors?.min ||
              obesityForm.controls.weight.errors?.max)
          "
        >
          {{ "Weight must be between 1 and 500 kg" | translate }}
        </div>
      </div>
      <div
        *ngIf="submitted && obesityForm.controls.weight.errors?.pattern"
        class="text-danger"
      >
        <div *ngIf="submitted && obesityForm.controls.weight.errors?.pattern">
          {{
            "Weight must be a valid number with up to 1 decimal places"
              | translate
          }}
        </div>
      </div>
    </div>
  </div>

  <!-- Modules -->
  <div class="row relative">
    <div class="info-header-obesity col-12 absolute left-0 top-0 py-5">
      <h3 class="info-title-obesity">
        {{ "Please Select Your Request" | translate }}
        <span class="text-danger"> *</span>
      </h3>
    </div>
    <div
      class="form-group col-12 obesity-treatment-body"
      *ngFor="let modulesItem of modules"
      formGroupName="modules"
    >
      <label
        class="checkbox-obesity allModules mt-1"
        [for]="modulesItem.key"
        *ngIf="modulesItem.key === 'allModules'"
      >
        <input
          type="checkbox"
          [formControlName]="modulesItem.key"
          id="{{ modulesItem.key }}"
          name="modulesItem.key"
          (change)="onCheckboxChange(modulesItem.key)"
        />
        <div class="checkbox__checkmark"></div>
        <div class="checkbox__body">{{ modulesItem.label | translate }}</div>
      </label>
      <p class="treatment-error" *ngIf="modulesItem.key === 'allModules'">
        <span *ngIf="submitted && !isCheckBoxValid" class="has-error-section">{{
          "Please select at least one" | translate
        }}</span>
      </p>
      <div
        class="info-header-obesity col-12"
        *ngIf="modulesItem.key === 'allModules'"
      >
        <h4 class="info-title-obesity fixed-title">
          {{ "Or you can choose one or more of the following" | translate }}
          <span class="text-danger"> *</span>
        </h4>
      </div>
      <label
        class="checkbox-obesity"
        [for]="modulesItem.key"
        *ngIf="modulesItem.key !== 'allModules'"
      >
        <input
          type="checkbox"
          [formControlName]="modulesItem.key"
          id="{{ modulesItem.key }}"
          name="modulesItem.key"
          (change)="onCheckboxChange(modulesItem.key)"
        />
        <div class="checkbox__checkmark"></div>
        <div class="checkbox__body">{{ modulesItem.label | translate }}</div>
      </label>
    </div>
  </div>

  <div class="w-100 col-12 d-flex justify-content-end">
    <button
      type="submit"
      class="btn obesity-submit-btn"
      style="max-width: 150px"
    >
      {{ "Submit" | translate }}
    </button>
  </div>
</form>
