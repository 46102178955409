<div class="container">
  <div class="row">
    <div class="card my-5 mx-2">
      <div class="card-body desc">
        <div class="row mt-5 mb-4 top-area">
          <div class="col-lg-8 col-md-8 col-sm-12">
            <h1 class="text-center hiw-title">{{"OUR Company" | translate}}</h1>
            <p class="desc">{{"VERADoctors provides the most advanced hybrid telemedicine solutions in the world by
              conceptualizing and manufacturing unique, integrated hardware and software to curate the best experience
              for our patients and providers both virtually and on-site" | translate}}</p>
          </div>
          <div class="col-lg-4 col-md-4 col-sm-12 top-img">
            <img src="assets/images/infoPages/african_american_wom_VpOeF.jpg" id="img-top" alt="">
          </div>
        </div>
        <div class="row middle-area m-1 mb-4">
          <div class="col-lg-4 col-md-4 col-sm-12 p-0">
            <div class="m-0 p-0">
              <img src="assets/images/infoPages/young_doctor_support_wSVUr.jpg" alt="" id="middle-img" class="m-0">
            </div>
          </div>
          <div class="col-lg-8 col-md-8 col-sm-12 middle-p">
            <p class="m-0 p-0">
              {{"By creating hybrid “online/offline’ ecosystems focused on efficient work-flow integration we can enable
              providers with improved patient outcomes. VERADoctors know how important it is to build trust with your
              patients, so we offer white-label versions of our systems to enable the providers the ability to brand
              their virtual care services, further building upon the patient/provider relationship" |translate}}.
            </p>
          </div>
        </div>
        <div class="row text-center m-1 mb-4">
          <p>
            {{"VERADoctors goes beyond the “Tele-Medicine” convenience, focusing on innovations around virtual
            longitudinal care while also providing care at home through remote visits by specialists and specialized
            nurses. Through patient monitoring, self-diagnostics, and investments in digital front doors, we continue
            to help achieve healthcare’s triple aim" |translate}}:
          </p>
        </div>
        <div class="row" style="align-items: center;">
          <div class="col-sm-12 col-md-4 col-lg-4 my-2 mx-0 d-flex flex-column text-center">
            <h1 style="margin-bottom: -10px; z-index: 4; font-size: 45px;"><span style="font-weight: 600; background-color: #feb95d; padding: 0 35px; color: #fff; border-radius: 10px;">1</span></h1>
            <div id="yellow">
              <p><span style="font-weight: 600;">{{"Increasing Convenience" | translate}}</span><br> {{"to receive routine care" | translate}}</p>
            </div>
          </div>
          <div class="col-sm-12 col-md-4 col-lg-4 my-2 mx-0 d-flex flex-column text-center">
            <h1 style="margin-bottom: -10px; z-index: 4; font-size: 45px;"><span style="font-weight: 600; background-color: #5273fa; padding: 0 35px; color: #fff; border-radius: 10px;">2</span></h1>
            <div id="blue">
              <p><span style="font-weight: 600;">{{"Improving Heath Outcomes" | translate}}</span><br> {{"for those with chronic conditions or in need of post acute care support" | translate}}</p>
            </div>
          </div>
          <div class="col-sm-12 col-md-4 col-lg-4 my-2 mx-0 d-flex flex-column text-center">
            <h1 style="margin-bottom: -10px; z-index: 4; font-size: 45px;"><span style="font-weight: 600; background-color: #f15178; padding: 0 35px; color: #fff; border-radius: 10px;">3</span></h1>
            <div id="red">
              <p><span style="font-weight: 600;">{{"Boosting Access" | translate}}</span><br> {{"to specialty care" | translate}}</p>
            </div>
          </div>
        </div>
        <div class="row text-center my-4">
          <img src="assets/images/secure-icon.png" alt="secure-web" id="secure-img" class="mx-auto" />
        </div>
        <div class="row mb-5 d-flex justify-content-center">
          <div class="my-auto text-center mx-1">
            <p>{{"VERA’s system is HIPAA and GDPR compliant and all interactions and communications are encrypted" | translate}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
