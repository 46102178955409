import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { CountryService } from 'src/app/services/country.service';
import { SendMailService } from 'src/app/services/send-mail.service';

@Component({
  selector: 'app-executive-team',
  templateUrl: './executive-team.component.html',
  styleUrls: ['./executive-team.component.css']
})
export class ExecutiveTeamComponent implements OnInit {

  constructor(
    private fb: FormBuilder,
    private countryService: CountryService,
    private sendMail: SendMailService,
    private toastr: ToastrService) { }

  form: FormGroup = this.fb.group({
    firstName: ['', Validators.required],
    lastName: ['', Validators.required],
    phoneNumber: ['', Validators.required],
    mail: ['', Validators.required],
    country: ['', Validators.required],
    date: [null, Validators.required],
    description: [''],
  })
  countries = [];
  isForm: boolean = false;
  selectedCountry="";

  ngOnInit(): void {
    this.countryService.getCountries().subscribe(res => {
      this.countries = res as unknown as Array<any>
    })

    window.scrollTo(0, 0);
  }

  openForm(){
    this.isForm = !this.isForm
  }

  submit(){
    this.form.controls['country'].setValue(this.selectedCountry)
    const content = `
                  Full Name: ${this.form.controls['firstName'].value} ${this.form.controls['lastName'].value}'\n'
                  Email: ${this.form.controls['mail'].value}'\n'
                  Phone Number: ${this.form.controls['phoneNumber'].value} '\n'
                  Date: ${this.form.controls['date'].value} '\n'
                  Country: ${this.selectedCountry}'\n'
                  Description: ${this.form.controls['description'].value}
                  `
    const sendedForm = {
      bodyContent: content,
      contentType: 'text',
      emailToSend: 'info@veradoctors.com',
      subject: "Request Meeting Form",


    }
    this.sendMail.requestAMeeting(sendedForm).subscribe(res => {
      this.toastr.success('', 'Request Mail Sent')
    }, err => this.toastr.error('please try again later', 'something went wrong'))
  }

  onChangeDate(e){
    const today = Date.now()
    const incomingDate = new Date(e.target.value).getTime()
    if(today > incomingDate){
      this.toastr.error('date can not be older than today', '')
      return
    }
    this.form.controls['date'].setValue(e.target.value)
  }

}
