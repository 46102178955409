<div class="container">
  <div class="row">
    <div class="card my-5 mx-2 w-100">
      <div class="card-header text-center">
        <h1>{{"Hybrid Healthcare with convenience" | translate}}</h1>
        <h2>{{"Hotels, Private Rentals, and Tour Operators" | translate}}</h2>
      </div>
      <div class="card-body px-5 col-md-12 col-sm-12 w-100 text-center" style="color: #000; text-align: justify;">
        <p class="desc">
          {{"Your guests can enjoy peace of mind knowing they are booking with a VERADoctors partner" | translate}}
        </p>
        <div class="row mt-3">
          <div class="col-md-6 col-sm-12">
            <img src="/assets/images/hotels/hand_medical_glove_p_jFtF9.jpg" alt="">
          </div>
          <div class="col-md-6 col-sm-12" style="align-items: center;">
            <div class="yellow text-center">
              <h3>
                {{"FOR EMERGENCIES" | translate}} <br><span>{{"your staff will be equipped with the best virtual healthcare tech" | translate}}</span>
              </h3>
            </div>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-md-6 col-sm-12">
            <div class="yellow text-center">
              <h3>
                {{"OPTIMIZE CARE" | translate}} <br><span>{{"with in-person and virtual bedside visits" | translate}}</span>
              </h3>
            </div>
          </div>
          <div class="col-md-6 col-sm-12">
            <img src="/assets/images/hotels/nurse_retirement_hom_MDncp.jpg" alt="">
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-md-6 col-sm-12">
            <img src="/assets/images/hotels/attractive_young_dar_52KXw.jpg" alt="">
          </div>
          <div class="col-md-6 col-sm-12">
            <div class="yellow text-center">
              <h3>
                {{"ALLOW ACCESS" | translate}} <br><span>{{"to the geolocation of care in the area at low cost to you" | translate}}</span>
              </h3>
            </div>
          </div>
        </div>
        <div class="row mt-5 mb-3 desc">
          <h3 style="color: #4365ef;"><i class="fa fa-th-list" aria-hidden="true"></i> {{"Features" | translate}}</h3>
          <div class="row">
            <div class="col-sm-12 col-md-6 text-left">
              <ul>
                <li>{{"Diagnostic Capabilities" | translate}}</li>
                <li>{{"Incorporating file sharing" | translate}}</li>
                <li>{{"SMS Messaging" | translate}}</li>
                <li>{{"Whiteboard" | translate}}</li>
                <li>{{"On-Demand Recording" | translate}}</li>
              </ul>
            </div>
            <div class="col-sm-12 col-md-6 text-left">
              <ul>
                <li>{{"API to the Hospitals electronic health records" | translate}}</li>
                <li>{{"Patient portal with direct access to labs" | translate}}</li>
                <li>{{"Pharmacies and others allow seamless integration throughout the care spectrum" | translate}}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
