import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { url } from 'src/config/http';

@Injectable({
  providedIn: 'root',
})
export class UsersService {
  constructor(private httpClient: HttpClient) {}

  getUserMe() {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      observe: 'response' as 'response',
    };
    return this.httpClient.get(url + `/users/me`, options);
  }

  getUser(id) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      observe: 'response' as 'response',
    };
    return this.httpClient.get(url + `/users/${id}`, options);
  }

  getUserEmailAvailable(email) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      observe: 'response' as 'response',
    };
    return this.httpClient.get(
      url + `/users/email-available?email=${email}`,
      options
    );
  }

  changePassword = (oldPassword, newPassword) => {
    const body = {
      oldPassword,
      newPassword,
    };
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-auth': localStorage.getItem('xauth'),
      }),
      observe: 'response' as 'response',
    };

    return this.httpClient.post(url + `/users/change-password`, body, options);
  };

  resetPassword(email) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      observe: 'response' as 'response',
    };
    return this.httpClient.get(
      url + `/users/forgot-password/?email=${email}`,
      options
    );
  }

  updateProfile(id, body) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      observe: 'response' as 'response',
    };
    return this.httpClient.patch(url + `/users/${id}`, body, options);
  }

  updateMyProfile(body) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-auth': localStorage.getItem('xauth'),
      }),
      observe: 'response' as 'response',
    };

    return this.httpClient.patch(
      url + `/users/update-my-profile`,
      body,
      options
    );
  }

  getAllUsers() {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-auth': localStorage.getItem('xauth'),
      }),
      observe: 'response' as 'response',
    };
    return this.httpClient.get(url + `/users`, options);
  }

  getSpecificUsers(query) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-auth': localStorage.getItem('xauth'),
      }),
      observe: 'response' as 'response',
    };
    return this.httpClient.get(url + `/users?role=${query}`, options);
  }

  register(form) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        // 'x-auth':  localStorage.getItem('xauth')
      }),
      observe: 'response' as 'response',
    };
    return this.httpClient.post(url + `/users/register`, form, options);
  }

  deleteUser(id) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-auth': localStorage.getItem('xauth'),
      }),
      observe: 'response' as 'response',
    };
    return this.httpClient.delete(url + `/users/${id}`, options);
  }

  createUser(form) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-auth': localStorage.getItem('xauth'),
      }),
      observe: 'response' as 'response',
    };
    return this.httpClient.post(url + `/users`, form, options);
  }
  createDoctor(form) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-auth': localStorage.getItem('xauth'),
      }),
      observe: 'response' as 'response',
    };
    return this.httpClient.post(url + `/doctors`, form, options);
  }

  loginUser(form) {
    const options = {
      observe: 'response' as 'response',
    };
    return this.httpClient.post(url + `/users/login`, form, options);
  }

  socialLogin(token) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      observe: 'response' as 'response',
    };
    return this.httpClient.post(url + `/users/social`, token, options);
  }
}
