import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-travel',
  templateUrl: './travel.component.html',
  styleUrls: ['./travel.component.css']
})
export class TravelComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit(): void {
    
    window.scroll(0,0)
    window.document.body.scrollTop = 0;
    
      

  }
  
}
