<div class="container">
    <div class="row">
        <div class="card my-5 mx-2">

            <h1 class="text-center hiw-title my-5">
                {{"KİŞİSEL VERİLERİN KORUNMASI" | translate}}
            </h1>

            <div class="card-body desc" style="color: #000; text-align: justify;">
                <p>
                    {{"Kullanıcı’ya ait kişisel veriler, işbu Sözleşme’nin ayrılmaz bir parçası olan ve Bilgilendirme
                    (Kişisel Verilerin Toplanması ve İşlenmesine İlişkin Aydınlatma Metni) metninde yer alan
                    açıklamalarda belirtilen kapsam ve koşullarla sınırlı, belirtilen amaçlarla uyumlu olarak
                    işleyebilecektir. VERADoctors, 6698 sayılı Kişisel Verilerin Korunması Kanunu ve Kişisel Sağlık
                    Verilerinin İşlenmesi Ve Mahremiyetinin Sağlanması Hakkında Yönetmelik başta olmak üzere, İlgili
                    Mevzuat hükümlerine riayet edeceğini kabul ve beyan eder. Kullanıcı, işbu Sözleşme ile birlikte
                    VERADoctors’un 6698 Sayılı Kanunu, Kişisel Sağlık Verilerinin İşlenmesi Ve Mahremiyetinin Sağlanması
                    Hakkında Yönetmelik ve İlgili Mevzuat uyarınca yerine getirmesi gereken aydınlatma yükümlülüklerini
                    yerine getirdiğini kabul ve beyan eder." |
                    translate}}</p>
                <p>
                    {{"veradoctors.com olarak kişisel verilerinizin güvenliği, korunması noktasında azami dikkat ve
                    hassasiyet gösteriyoruz. Bu kapsamda websitemizi ziyaret eden ve üyelik kaydı oluşturarak
                    hizmetlerimizi kullanan kullanıcılarımızın kişisel verilerinin 6698 sayılı Kişisel Verilerin
                    Korunması Kanunu’na ve ilgili diğer mevzuat hükümleri ile Kişisel Verileri Koruma Kurulu kararlarına
                    uygun şekilde işlemeye ve muhafaza etmeye büyük önem veriyoruz. Kişisel verilerinizi işbu Kişisel
                    Veri Aydınlatma Metni’nde birazdan açıklanacak şekilde ve mevzuatta belirlenmiş sınırlar dahilinde
                    işlemekteyiz." | translate}}
                </p>
                <h2>{{"Veri Sorumlusu" | translate}}</h2>
                <p>
                    {{"VERA Premium Care Sağlık Hizmetleri A.Ş / Zerzavatçı mah. Şehit Cengiz Cad. No:41A Beykoz /
                    İSTANBUL " | translate}}
                </p>
                <h2>{{"Hangi Kişisel Verilerinizi İşliyoruz?" | translate}}</h2>
                <p>{{"Veradoctors.com internet sitemizi ziyaret ettiğinizde çerezler vasıtasıyla tarayıcı bilgileriniz,
                    website çerez kayıtları, IP adresiniz, erişim loglarınız işlenmektedir. Ayrıca siteye üye olmak
                    suretiyle başvuru yapan kullanıcılardan ad, soyad, email, telefon ve şifre bilgileri alınmaktadır.
                    Sitemiz aracılığı ile online sağlık hizmeti almak isteyen kullanıcıların sağlık bilgileri kapsamında
                    tıbbi şikayetlerine ilişkin beyanları alınmakta ve websitemiz aracılığı ile tıp alanındaki uzman ile
                    yapacağı görüşme esnasında sesli ve görüntülü kayıt alınmaktadır. Bu bilgi, metin, ses ve görselleri
                    paylaşan veri sahipleri tarafımızca işlenen ve saklanan tüm kişisel verilerinin kaldırılması için
                    veri sorumlusuna başvurabilirler. Veri sorumlusu bu durumda kişisel verileri mevzuata uygun şekilde
                    kaldırmakla yükümlüdür." | translate}}
                </p>

                <h2>{{"Kişisel Verilerin İşlenmesi Amaçları" | translate}}</h2>
                <p>{{"Veradoctors.com internet sitesinde üyelerin ad, soyad, email, telefon ve şifre bilgileri,
                    Faaliyetlerin Mevzuata Uygun Yürütülmesi, Firma / Ürün / Hizmetlere Bağlılık Süreçlerinin
                    Yürütülmesi, Hukuk İşlerinin Takibi Ve Yürütülmesi, İletişim Faaliyetlerinin Yürütülmesi, Mal /
                    Hizmet Satış Süreçlerinin Yürütülmesi, Mal / Hizmet Satış Sonrası Destek Hizmetlerinin Yürütülmesi,
                    Mal / Hizmet Üretim Ve Operasyon Süreçlerinin Yürütülmesi, Müşteri İlişkileri Yönetimi Süreçlerinin
                    Yürütülmesi, Müşteri Memnuniyetine Yönelik Aktivitelerin Yürütülmesi, Reklam / Kampanya / Promosyon
                    Süreçlerinin Yürütülmesi, Sözleşme Süreçlerinin Yürütülmesi, Üyenin profil sayfasının oluşturulması
                    ve almak istediği online sağlık hizmeti ile ilgili kendisi ile iletişime geçilebilmesi amacıyla
                    işlenmektedir. Kullanıcıların email, cep telefonu iletişim bilgileri ayrıca onay vermeleri halinde,
                    verilen onayın kaldırılmasına kadar ki süre için bilgilendirme, reklam ve promosyon amaçlı
                    içeriklerin gönderilebilmesi amacı ile işlenebilecektir." | translate}}
                </p>
                <p>{{"Kullanıcıların sağlık bilgileri Faaliyetlerin Mevzuata Uygun Yürütülmesi, Mal / Hizmet Satış
                    Sonrası Destek Hizmetlerinin Yürütülmesi, Mal / Hizmet Satış Süreçlerinin Yürütülmesi, Mal / Hizmet
                    Üretim Ve Operasyon Süreçlerinin Yürütülmesi, Talep / Şikayetlerin Takibi, internet sitesinin
                    verdiği online sağlık hizmetinin yürütülmesi kapsamında kullanıcının doğru uzman ile buluşturulması
                    için alınmakta ve bu bilgiler online sağlık hizmeti öncesi sadece kullanıcının görüşme yapmak
                    istediği tıp uzmanına iletilmektedir. Online sağlık hizmeti görüşmesi süresince ses ve görüntü
                    kaydının yapılması ise ileride ortaya çıkabilecek hukuki uyuşmazlıklarla ilgili adli mercilerin
                    talepleri halinde yasal mevzuat çerçevesinde ilgili adli mercilere iletmek amacıyla işlenmektedir."
                    | translate}}
                </p>
                <p>{{"Website vasıtası ile işlenen çerezler ile elde edilen tarayıcı bilgileriniz, web site çerez
                    kayıtları, IP adresiniz, erişim loglarınız ve diğer yukarıda sayılan, üyelik kaydı sırasında elde
                    edilen kişisel verileriniz veradoctors.com tarafından, yürütülen ticari faaliyetlerin mevzuata ve
                    politikalarımıza uygun olarak yerine getirilmesi için veradoctors.com iş birimleri tarafından
                    gerekli çalışmaların yapılması ve bu doğrultuda faaliyetlerin yürütülmesi, kısa, orta ve uzun vadede
                    ticari politikalarının tespit edilmesi, planlanması ve uygulanması, veradoctors.com’un ilgili
                    mevzuattan doğan yükümlülüklerin yerine getirilmesi, müşteri ilişkileri ve kurumsal iletişim
                    süreçlerinin yönetilmesi ve veradoctors.com'un iş ilişkisi içerisinde olduğu gerçek ve tüzel
                    kişilerin ticari ve hukuki emniyetinin sağlanması amaçlarıyla Kanun’da belirtilen sınırlar
                    çerçevesinde hukuka, dürüstlük kurallarına uygun ve işbu amaçlarla her daim bağlantılı, sınırlı ve
                    ölçülü şekilde işlenmektedir." | translate}}
                </p>
                <h2>{{"Kişisel Verilerin Aktarılması" | translate}}</h2>
                <p>{{"Yukarıda yer alan amaçlar dahilinde işlenen (sağlık bilgisi gibi özel nitelikli kişisel verileri
                    haricinde kalan kişisel veriler), Kişisel Verilerin Korunması Kanunu’nun 8. ve 9. maddesinde
                    belirtilen şartlara uygun olarak başta kanunen yetkili kamu kuruluşlarına, adli ve idari makamlara,
                    sair mevzuatta izin verilen özel hukuk tüzel ve gerçek kişilerine, veradoctors.com’un denetime
                    yetkili kurum ve kuruluşlara, ödemelerin ve mali yükümlülüklerin yerine getirilmesi adına anlaşmalı
                    olunan ödeme kuruluşlarına, veradoctors.com'un faaliyetlerinin yürütülmesi ve geliştirilmesi için
                    hizmet alınan veya işbirliği içerisinde olunan iş ortaklarına ve sadece gerektiğinde
                    veradoctors.com'un tedarikçilerine Kanun’da belirtilen veri güvenliği tedbirleri alınmak suretiyle
                    aktarılmaktadır. Özel nitelikli kişisel veri kategorisinde yer alan verilerden olan sağlık verileri
                    ve online sağlık hizmeti görüşmesi esnasında gerçekleştirilen sesli ve görüntülü kayıtlar sadece
                    kullanıcının hizmet almak istediği tıp uzmanı ile paylaşılmaktadır. Özel nitelikli kişisel veriler
                    kullanıcının hizmet almak ve görüşmek istediği tıp uzmanı haricinde başkaca üçüncü şahıslarla
                    paylaşılmamaktadır." | translate}}
                </p>
                <h2>{{"Kişisel Verilerin Toplanma Yöntemi ve Hukuki Sebebi" | translate}}</h2>
                <p>{{"Kişisel verileriniz, veradoctors.com'un yetkili birim ve çalışanları tarafından otomatik ve
                    otomatik olmayan yöntemlerle, sözlü, yazılı veya elektronik ortamda toplanmaktadır. Bu kapsamda
                    yukarıda yer alan kişisel veriler; Kanun’un 5. Maddesinin ikinci fıkrasında gösterilen bir
                    sözleşmenin kurulması veya ifasıyla doğrudan doğruya ilgili olmasından dolayı sözleşmenin
                    taraflarına ait kişisel verilerin işlenmesinin zorunlu olması, veri sorumlusunun hukuki
                    yükümlülüğünü yerine getirebilmesi için veri işlemenin zorunlu olması, ilgili kişinin kendisi
                    tarafından alenileştirilmiş olması ve ilgili kişinin temel hak ve özgürlüklerinize zarar vermemek
                    kaydıyla veradoctors.com'umn meşru menfaatleri için veri işlemenin zorunlu olması hukuki sebeplerine
                    dayalı olarak işlenmektedir." | translate}}
                </p>
                <h2>{{"Veri Sahiplerinin Kanun Kapsamındaki Hakları" | translate}}</h2>
                <p>{{"Veri sahiplerinin aşağıda yer alan iletişim formu ile veri sorumlusuna başvurmak suretiyle" | translate}}
                </p>
                <ul>
                    <li>{{"Kişisel verilerinizin işlenip işlenmediğini öğrenme," | translate}}</li>
                    <li>{{"Kişisel verileriniz işlenmişse buna ilişkin bilgi talep etme," | translate}}</li>
                    <li>{{"Kişisel verilerinizin işlenme amacını ve bunların amacına uygun kullanılıp kullanılmadığını öğrenme," | translate}}</li>
                    <li>{{"Yurt içinde veya yurt dışında kişisel verilerinizin aktarıldığı üçüncü kişileri bilme," | translate}}</li>
                    <li>{{"Kişisel verilerinizin eksik veya yanlış işlenmiş olması hâlinde bunların düzeltilmesini isteme," | translate}}</li>
                    <li>{{"Kanun’un 7. maddesinde öngörülen şartlar çerçevesinde kişisel verilerin silinmesini veya yok edilmesini isteme," | translate}}</li>
                    <li>{{"Kanun’un 11. maddesinin (d) ve (e) bentleri uyarınca yapılan işlemlerin, kişisel verilerinizin aktarıldığı üçüncü kişilere bildirilmesini isteme," | translate}}</li>
                    <li>{{"İşlenen verilerinizin münhasıran otomatik sistemler vasıtasıyla analiz edilmesi suretiyle kendiniz aleyhine bir sonucun ortaya çıkmasına itiraz etme," | translate}}</li>
                    <li>{{"Kişisel verilerinizin kanuna aykırı olarak işlenmesi sebebiyle zarara uğramanız hâlinde zararın giderilmesini talep etme, hakları bulunmaktadır." | translate}}</li>
                </ul>
                <p>{{"Veri sahibi olarak bu taleplerinizi dilediğiniz zaman, VERA Premium Care Sağlık Hizmetleri A.Ş / Zerzavatçı mah. Şehit Cengiz Cad. No:41A Beykoz / İSTANBUL." | translate}}</p>

        </div>
    </div>
</div>