import { Injectable } from '@angular/core';

import AgoraRTM, { RtmChannel, RtmClient, RtmImageMessage, RtmMessage } from "agora-rtm-sdk";
import { BehaviorSubject, Subject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class MessagingService {
  rtmclient!: RtmClient;
  channel!: RtmChannel;
  rtmToken: any;

  public receivedMessage = new Subject<number>()
  public resetMessage = new BehaviorSubject(false)

  constructor() { }

  public startCallFromService = new Subject<any>()
  public firstSended = new Subject<any>()
  public sendedChannelName = new Subject<any>()
  countMessage = 0

  // pass your appid in createInstance
  userClient;
  createRTMClient() {
    const client = AgoraRTM.createInstance('7eb53aa7d67f4323994da577c3227df9');
    this.userClient = client;
    return client;
  }

  async signalLogin(client: RtmClient, token: string, uid: string) {
    await client.login({ token, uid })
      .then(() => {
        // console.log('AgoraRTM client login success');
      }).catch(err => {
        // console.log('AgoraRTM client login failure', err);
      });
  }

  async signalLogout(client: RtmClient) {
    await client.logout()
  }

  RTMevents(client: RtmClient) {

    client.on('RemoteInvitationReceived', (remoteInvitation) => {
      // console.log("REMOTE INVITATION RECEIVED")
      // console.log(remoteInvitation)
      if (confirm('do you want to accept invitiation')) {
        remoteInvitation.accept()
      } else {
        remoteInvitation.refuse()
      }

      remoteInvitation.on('RemoteInvitationAccepted', () => {  // CAN BE ADDED CONTENT, THIS EXPLAINS HOW WE SEND CHANNEL NAME
        const cname = remoteInvitation.content
        this.startCallFromService.next(cname)
      })


      remoteInvitation.on('RemoteInvitationRefused', () => {
        return this.startCallFromService.next(false)
      })
    })

    client.on("ConnectionStateChanged", (newState, reason) => {
      // console.log(
      //   "on connection state changed to " + newState + " reason: " + reason
      // );
    });

    client.on('MessageFromPeer', (text, peerId, messagePros) => { // text: text of the received message; peerId: User ID of the sender.
      /* Your code for handling the event of receiving a peer-to-peer message. */

      this.recievedMessage(text, peerId, messagePros, client);
    });
    client.on('PeersOnlineStatusChanged', (status) => {
      // console.log('PeersOnlineStatusChanged ', status);
    });

    client.on('TokenExpired', () => {
      alert("your token has been expired please renew it, if you want to make a call")
    })

    client.on('PeersOnlineStatusChanged', peerStatus => {
      // console.log(peerStatus)
    })


  }

  async recievedMessage(text: RtmMessage, peerId: string, messagePros: any, client: RtmClient) {  // PEER TO PEER OLURSA DÜZENLEME GEREKİR
    // console.log("123123123123123123")
    const user = await client.getUserAttributes(peerId);
    if (text.messageType === "TEXT") {
      // this.setCurrentMessage({ message: text.text, user: peerId });
      // console.log("890890890890890890")
      const newPeerMessage = { message: text.text, user: user, date: messagePros.serverReceivedTs, type: 'TEXT', isPeer: true }
      this.messageIncome.next(newPeerMessage)

      // this.setCurrentMessage(newMessageData);
      if (this.resetMessage.value) {
        this.receivedMessage.next(0)
        this.countMessage = 0
      } else {
        this.countMessage++
        this.receivedMessage.next(this.countMessage)
      }
    }
    if (text.messageType === 'IMAGE') {
      const newMessageData = {
        user: user, message: text.mediaId, date: messagePros.serverReceivedTs, type: 'IMAGE',
        isImage: true, fileName: text.fileName, isPeer: true
      }
      this.messageIncome.next({ message: text.messageType, newMessageData })
      // Gets the Blob object
    }
    if (text.messageType === "FILE") {
      const newMessageData = {
        user: user, message: text.mediaId, date: messagePros.serverReceivedTs, type: 'FILE',
        isImage: true, fileName: text.fileName, isPeer: true
      }
      this.messageIncome.next({ message: text.messageType, newMessageData })
    }
  }

  receiveChannelMessage(channel: RtmChannel, client: RtmClient) {
    channel.on('ChannelMessage', (text, senderId, messagePros) => { // text: text of the received channel message; senderId: user ID of the sender.
      /* Your code for handling events, such as receiving a channel message. */
      this.handleMessageReceived(text, senderId, messagePros, client);
    });

    channel.on('MemberJoined', memberId => {
      // console.log(memberId, 'MemberJoined');
      // alert("message service 74. satır ve devamını kapat!!!")
    })

    channel.on('MemberLeft', memberId => {
      // console.log('MemberLeft', memberId);
      // alert("message service 79. satır ve devamını kapat!!!")
    });

  }
  // used to handle channel message
  async handleMessageReceived(text: RtmMessage | RtmImageMessage, senderId: string, messagePros: any, client: RtmClient) {

    // console.log(text, senderId, messagePros, client)
    const user = await client.getUserAttributes(senderId); // senderId means uid getUserInfo
    if ((text.messageType === "TEXT") && text.text) {
      const newMessageData = { user: user, message: text.text, date: messagePros.serverReceivedTs, type: 'TEXT', isImage: false, roomUUID: { token: '', uuid: '' } };
      // console.log(newMessageData);
      // this.setCurrentMessage(newMessageData);
      this.messageIncome.next(newMessageData)
      if (this.resetMessage.value) {
        this.receivedMessage.next(0)
        this.countMessage = 0
      } else {
        this.countMessage++
        this.receivedMessage.next(this.countMessage)
      }
    }
    if (text.messageType === "IMAGE") {
      const newMessageData = {
        user: user, message: text.mediaId, date: messagePros.serverReceivedTs, type: 'IMAGE',
        isImage: true, fileName: text.fileName
      }
      // console.log(newMessageData);
      this.messageIncome.next(newMessageData)
      if (this.resetMessage.value) {
        this.receivedMessage.next(0)
        this.countMessage = 0
      } else {
        this.countMessage++
        this.receivedMessage.next(this.countMessage)
      }
    }
    if (text.messageType === "FILE") {
      const newMessageData = {
        user: user, message: text.mediaId, date: messagePros.serverReceivedTs, type: 'FILE',
        isImage: true, fileName: text.fileName
      }
      // console.log(newMessageData);
      this.messageIncome.next(newMessageData)
      if (this.resetMessage.value) {
        this.receivedMessage.next(0)
        this.countMessage = 0
      } else {
        this.countMessage++
        this.receivedMessage.next(this.countMessage)
      }
    }

  }

  messageIncome = new Subject<any>();
  // setCurrentMessage(newMessageData: any) {
  //   console.log(newMessageData, 'setCurrentMessage');
  //   alert(newMessageData.message);
  // }


  sendOneToOneMessage(client: RtmClient, uid: any, message: string) {
    client
      .sendMessageToPeer(
        { text: message }, // An RtmMessage object.
        uid // The user ID of the remote user.
      )
      .then(sendResult => {

        if (sendResult.hasPeerReceived) {
          // console.log(sendResult, 'sendMessageToPeer');

          /* Your code for handling the event that the remote user receives the message. */
        } else {
          /* Your code for handling the event that the message is received by the server but the remote user cannot be reached. */
        }
      })
      .catch(error => {
        /* Your code for handling the event of a message send failure. */
      });

    }


  async sendImagesToPeer(client: RtmClient, peerId: any, blob, fileName: string) {
    let media;
    const mediaMessage = await client.createMediaMessageByUploading(blob, {
      messageType: 'IMAGE',
      fileName: fileName,
      description: 'send image',
      thumbnail: blob,
      width: 100,
      height: 200,
      thumbnailWidth: 50,
      thumbnailHeight: 200,
    }).then(a => {
      media = a;
    }).catch(e => console.log(e))

    const imageInstance = client.createMessage({
      mediaId: media.mediaId, // Your mediaId
      messageType: 'IMAGE',
      fileName: media.fileName,
      description: media.description,
      thumbnail: new Blob,
      width: 100,
      height: 200,
      thumbnailWidth: 50,
      thumbnailHeight: 200,
    })

    this.channel.sendMessage(imageInstance)
  }

  async sendImagesToChannel(client: RtmClient, channel: any, blob, fileName: string) {
    let media;
    const mediaMessage = await client.createMediaMessageByUploading(blob, {
      messageType: 'IMAGE',
      fileName: fileName,
      description: 'send image',
      thumbnail: blob,
      width: 100,
      height: 200,
      thumbnailWidth: 50,
      thumbnailHeight: 200,
    }).then(a => {
      media = a;
    }).catch(e => console.log(e))

    const imageInstance = client.createMessage({
      mediaId: media.mediaId, // Your mediaId
      messageType: 'IMAGE',
      fileName: media.fileName,
      description: media.description,
      thumbnail: new Blob,
      width: 100,
      height: 200,
      thumbnailWidth: 50,
      thumbnailHeight: 200,
    })

    this.channel.sendMessage(imageInstance)
  }


  async sendFilesToChannel(client: RtmClient, channel: any, blob, fileName: string) {
    let media;
    const mediaMessage = await client.createMediaMessageByUploading(blob, {
      messageType: 'FILE',
      fileName: fileName,
      description: 'send file'
    }).then(a => { media = a; console.log(a) }).catch(e => console.log(e))

    const fileInstance = client.createMessage({
      mediaId: media.mediaId, // Your mediaId
      messageType: 'FILE',
      fileName: media.fileName,
      description: 'send file'
    })

    this.channel.sendMessage(fileInstance)

  }

  createRtmChannel(client: RtmClient) {
    const channel = client.createChannel("test");
    return channel;
  }

  async joinchannel(channel: RtmChannel) {
    await channel
      .join()
    // .then(() => {
    //   /* Your code for handling the event of a join-channel success. */
    // })
    // .catch(error => {
    //   /* Your code for handling the event of a join-channel failure. */
    // });
  }

  async setLocalAttributes(client: RtmClient, name: any) {
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent).toString();
    // console.log(isMobile, 'isMobile');
    await client.setLocalUserAttributes({
      name, isMobile
    });
  }

  sendMessageChannel(channel: RtmChannel, mess: string) {

    channel
      .sendMessage({ text: mess })
      .then(() => {
        /* Your code for handling events, such as a channel message-send success. */

        // console.log("test");
      })
      .catch(error => {
        /* Your code for handling events, such as a channel message-send failure. */
      });
  }



  async leaveChannel(client: RtmClient, channel: RtmChannel) {

    if (channel) {
      await channel.leave();
    }
    if (client) {
      await client.logout();
    }
  }


  async invitePeer(calleeId, channelName) {
    const localInvitation = this.userClient.createLocalInvitation(calleeId);
    localInvitation.content = channelName
    localInvitation.send();
  }

  // async addUpdateUserAttribute(client: RtmClient, attribute) {
  //   await client.addOrUpdateLocalUserAttributes(attribute);
  // }

  // setChannelInfo(client: RtmClient, attribute, option, channel) {
  //   const result = client.setChannelAttributes(channel, attribute, option);
  // }

  // addOrUpdateChannelAttributes(client: RtmClient, attribute, option, channel) {
  //   const res = client.addOrUpdateChannelAttributes(channel, attribute, option);
  // }

  // getChannelAttributes(client: RtmClient, channel) {
  //   const res = client.getChannelAttributes(channel)
  // }


  // getRTMUserStatus(client: RtmClient, uidArray) {

  //   client.subscribePeersOnlineStatus(uidArray).then(() => {
  //     console.log('subscribeStatus');
  //   }).catch(err => {
  //     console.log('subscribeStatus failure', err);
  //   });

  // }

  // async getRTMUserOnlineStatus(client: RtmClient, uids) {
  //   const status = await client.queryPeersOnlineStatus(uids)


  // }

}

