import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { LoadingService } from '../services/loading.service';

@Component({
  selector: 'app-spin-all',
  template: `<div class="spinner-border m-5" role="status">
                <span class="sr-only">Loading...</span>
            </div>`,
})

export class SpinAllComponent implements OnInit {

  showSpinner = false;

  constructor(private spinnerService: LoadingService, private cdRef: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.init()
  }

  init() {
    this.spinnerService.getSpinnerObserver().subscribe((status) => {
      this.showSpinner = (status === 'start');
      this.cdRef.detectChanges();
    });
  }

}
