import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { url } from 'src/config/http';

@Injectable({
  providedIn: 'root'
})
export class CountryService {

  constructor(private http: HttpClient) { }

  getCountries() {
    return this.http.get(url + `/countries`);
  }

  getStates(id) {
    return this.http.get(url + `/countries/${id}/states`);
  }

  getCity(country, state) {
    return this.http.get(url + `/countries/${country}/states/${state}/cities`);
  }

}
