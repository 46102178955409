<div class="container">
    <div class="row">
      <div class="card my-5 mx-2">
        <div class="card-title text-center">
          <h1 class="text-center hiw-title my-5">{{"Disclaimer" | translate}}</h1>
        </div>
        <div class="card-body" style="color: #000; text-align: justify;">
          <ul class="desc mb-5">
            <li>{{"VERADoctors is not an insurance product or a prescription fulfillment warehouse." | translate}}</li>
            <li>{{"VERADoctors does not replace the existing primary care physician relationship." | translate}}</li>
            <li>{{"Medical services rendered by your physician are subject to their professional judgment." | translate}}</li>
            <li>{{"VERADoctors operates subject to Turkey regulation and may not be available in certain countries." | translate}}</li>
            <li>{{"VERADoctors does not guarantee that a prescription will be written." | translate}}</li>
            <li>{{"VERADoctors physicians do not prescribe DEA controlled substances, non-therapeutic drugs, and certain
              other drugs which may be harmful because of their potential for abuse." | translate}}</li>
            <li>{{"VERADoctors physicians reserve the right to deny care for potential misuse of services." | translate}}</li>
            <li>{{"VERADoctors phone and video consultations are available 24/7/365, or by scheduled availability." | translate}}</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  
