import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'search',
  pure: false,
})
export class SearchPipe implements PipeTransform {
  transform(value: any, filteredString: string): any {
    if (
      value?.length === 0 ||
      filteredString === '' ||
      filteredString === undefined
    ) {
      return value;
    }
    return value.filter(
      // item => item[name].toLowerCase().indexOf(filteredString.toLowerCase()) > -1
      (item) =>
        `${item?.doctor?.title?.title?.en} ${item?.doctor?.user?.firstName} ${item?.doctor?.user?.lastName}`
          .toLowerCase()
          .indexOf(filteredString.toLowerCase()) > -1 ||
        `${item?.doctor?.title?.title?.de} ${item?.doctor?.user?.firstName} ${item?.doctor?.user?.lastName}`
          .toLowerCase()
          .indexOf(filteredString.toLowerCase()) > -1 ||
        `${item?.doctor?.title?.title?.fr} ${item?.doctor?.user?.firstName} ${item?.doctor?.user?.lastName}`
          .toLowerCase()
          .indexOf(filteredString.toLowerCase()) > -1 ||
        `${item?.doctor?.title?.title?.nl} ${item?.doctor?.user?.firstName} ${item?.doctor?.user?.lastName}`
          .toLowerCase()
          .indexOf(filteredString.toLowerCase()) > -1 ||
        `${item?.doctor?.title?.title?.es} ${item?.doctor?.user?.firstName} ${item?.doctor?.user?.lastName}`
          .toLowerCase()
          .indexOf(filteredString.toLowerCase()) > -1 ||
        `${item?.doctor?.title?.title?.ru} ${item?.doctor?.user?.firstName} ${item?.doctor?.user?.lastName}`
          .toLowerCase()
          .indexOf(filteredString.toLowerCase()) > -1 ||
        `${item?.doctor?.title?.title?.tr} ${item?.doctor?.user?.firstName} ${item?.doctor?.user?.lastName}`
          .toLowerCase()
          .indexOf(filteredString.toLowerCase()) > -1 ||
        `${item?.profession?.name?.en}`
          .toLowerCase()
          .indexOf(filteredString.toLowerCase()) > -1 ||
        `${item?.profession?.name?.de}`
          .toLowerCase()
          .indexOf(filteredString.toLowerCase()) > -1 ||
        `${item?.profession?.name?.es}`
          .toLowerCase()
          .indexOf(filteredString.toLowerCase()) > -1 ||
        `${item?.profession?.name?.nl}`
          .toLowerCase()
          .indexOf(filteredString.toLowerCase()) > -1 ||
        `${item?.profession?.name?.ru}`
          .toLowerCase()
          .indexOf(filteredString.toLowerCase()) > -1 ||
        `${item?.profession?.name?.tr}`
          .toLowerCase()
          .indexOf(filteredString.toLowerCase()) > -1 ||
        `${item?.profession?.name?.fr}`
          .toLowerCase()
          .indexOf(filteredString.toLowerCase()) > -1 ||
        `${item?.createdAt}`
          .toLowerCase()
          .indexOf(filteredString.toLowerCase()) > -1 ||
        `${item?.schedule?.start}`
          .toLowerCase()
          .indexOf(filteredString.toLowerCase()) > -1 ||
        `${item?.subject}`.toLowerCase().indexOf(filteredString.toLowerCase()) >
          -1 ||
        `${item?.type}`.toLowerCase().indexOf(filteredString.toLowerCase()) >
          -1 ||
        `${item?.package?.amount}`
          .toLowerCase()
          .indexOf(filteredString.toLowerCase()) > -1 ||
        `$${item?.package?.amount}`
          .toLowerCase()
          .indexOf(filteredString.toLowerCase()) > -1 ||
        // `${item?.package?.currency}`.toLowerCase().indexOf(filteredString.toLowerCase()) > -1 ||
        `${item?.invoiceNumber}`
          .toLowerCase()
          .indexOf(filteredString.toLowerCase()) > -1 ||
        `${item?.product?.appointment?.doctor?.profession?.name?.en}${item?.product?.appointment?.doctor?.user?.firstName} ${item?.appointment?.doctor?.user?.lastName}`
          .toLowerCase()
          .indexOf(filteredString.toLowerCase()) > -1 ||
        `${item?.product?.membership?.title}`
          .toLowerCase()
          .indexOf(filteredString.toLowerCase()) > -1 ||
        `${item?.paidAmount}`
          .toLowerCase()
          .indexOf(filteredString.toLowerCase()) > -1 ||
        `${item?.createdAt}`
          .toLowerCase()
          .indexOf(filteredString.toLowerCase()) > -1 ||
        `${item?.product?.appointment?.schedule?.start}`
          .toLowerCase()
          .indexOf(filteredString.toLowerCase()) > -1 ||
        `${
          item.isCancelled || item?.product?.appointment?.isCancelled
            ? 'cancelled'
            : 'cancel'
        }`.indexOf(filteredString.toLowerCase()) > -1 ||
        `${
          item.bookedWithMembership ||
          item?.product?.appointment?.bookedWithMembership
            ? 'membership'
            : null
        }`.indexOf(filteredString.toLowerCase()) > -1 ||
        `${item?.patient?.firstName} ${item?.patient?.lastName}`
          .toLowerCase()
          .indexOf(filteredString.toLowerCase()) > -1 ||
        `${item?.invoiceNumber}`
          .toLowerCase()
          .indexOf(filteredString.toLowerCase()) > -1 ||
        `${item?.paidAmount}`
          .toLowerCase()
          .indexOf(filteredString.toLowerCase()) > -1 ||
        `${item?.user?.firstName} ${item?.user?.lastName}`
          .toLowerCase()
          .indexOf(filteredString.toLowerCase()) > -1 ||
        `${item?.doctor?.profession?.name?.en}`
          .toLowerCase()
          .indexOf(filteredString.toLowerCase()) > -1 ||
        `${item?.doctor?.profession?.name?.de}`
          .toLowerCase()
          .indexOf(filteredString.toLowerCase()) > -1 ||
        `${item?.doctor?.profession?.name?.es}`
          .toLowerCase()
          .indexOf(filteredString.toLowerCase()) > -1 ||
        `${item?.doctor?.profession?.name?.fr}`
          .toLowerCase()
          .indexOf(filteredString.toLowerCase()) > -1 ||
        `${item?.doctor?.profession?.name?.nl}`
          .toLowerCase()
          .indexOf(filteredString.toLowerCase()) > -1 ||
        `${item?.doctor?.profession?.name?.ru}`
          .toLowerCase()
          .indexOf(filteredString.toLowerCase()) > -1 ||
        `${item?.doctor?.profession?.name?.tr}`
          .toLowerCase()
          .indexOf(filteredString.toLowerCase()) > -1 ||
        `${item?.patient?.firstName} ${item?.patient?.lastName}`
          .toLowerCase()
          .indexOf(filteredString.toLowerCase()) > -1 ||
        `${item?.review?.subject}`
          .toLowerCase()
          .indexOf(filteredString.toLowerCase()) > -1 ||
        `${item?.review?.text}`
          .toLowerCase()
          .indexOf(filteredString.toLowerCase()) > -1 ||
        `${item?.doctor?.user?.firstName} ${item?.doctor?.user?.lastName}`
          .toLowerCase()
          .indexOf(filteredString.toLowerCase()) > -1 ||
        `${item?.invoiceNumber}`
          .toLowerCase()
          .indexOf(filteredString.toLowerCase()) > -1 ||
        `${item?.user?.firstName} ${item?.user?.lastName}`
          .toLowerCase()
          .indexOf(filteredString.toLowerCase()) > -1 ||
        `${item?.product?.membership?.title}`
          .toLowerCase()
          .indexOf(filteredString.toLowerCase()) > -1
    );
  }
}
