<div class="container">
  <div class="row">
    <div class="card mt-5">
      <div class="card-body px-5" style="color: #000; text-align: justify;">
        <div class="row">
          <div class="col-md-6 col-sm-12 my-auto">
            <img src="assets/images/infoPages/health_professional__MSFYa.jpg" alt="" class="w-100">
          </div>
          <div class="col-md-6 col-sm-12 align-middle mt-3">
            <h1 class="text-center hiw-title">
              {{"See what our Executive Team can do for you" | translate}}
            </h1>
            <div class="desc mt-5">
              <h2>{{"Our esteemed team of experts" | translate}}</h2>
              <p class="mt-3">{{"Over 500 years of experience in care, public government and hospitals, with more than
                50 years in education and technology" | translate}}</p>
            </div>
            <div class="text-center mt-3">
              <button class="btn login-btn w-100 mt-0" (click)="openForm()">{{"Request a meeting" | translate}}</button>
            </div>
          </div>
        </div>
        <div class="row d-flex justify-content-center" *ngIf="isForm">
          <div class="col-sm-12 col-md-8 col-lg-6 desc">
            <form [formGroup]="form">
              <div class="text-center my-5 mb-3">
                <h2>{{"Request Meeting Form" | translate}}</h2>
              </div>
              <div class="form-row">
                <div class="form-group col-md-6 col-sm-12">
                  <input type="text" id="firstName" name="firstName" maxlength="50" formControlName="firstName"
                    class="form-control" placeholder="{{'First Name' | translate}}" pattern="[a-zA-ZıçöğşüÇÖĞŞÜİ ]*"
                    minlength="2" [style.border-color]="form.get('firstName')?.valid ? 'blue' : 'red'">
                </div>
                <div class="form-group col-md-6 col-sm-12">
                  <input type="text" id="lastName" name="lastName" maxlength="50" formControlName="lastName"
                    class="form-control" placeholder="{{'Last Name' | translate}}" pattern="[a-zA-ZıçöğşüÇÖĞŞÜİ ]*" minlength="2"
                    [style.border-color]="form.get('lastName')?.valid ? 'blue' : 'red'">
                </div>
              </div>
              <div class="form-row">
                <div class="form-group col-md-6 col-sm-12">
                  <input type="text" formControlName="phoneNumber" id="phoneNumber" name="phoneNumber"
                    class="form-control" maxlength="15" minlength="10" placeholder="{{'Phone Number' | translate}}"
                    pattern="[0-9]*" [style.border-color]="form.get('phoneNumber')?.valid ? 'blue' : 'red'">
                </div>
                <div class="form-group col-md-6 col-sm-12">
                  <input type="text" id="mail" name="mail" formControlName="mail" class="form-control"
                    placeholder="{{'Email' | translate}}" maxlength="50" email
                    [style.border-color]="form.get('mail')?.valid && form.get('mail')?.touched ? 'blue' : 'red'">
                </div>
              </div>
              <div class="form-row">
                <div class="form-group col-md-6 col-sm-12">
                  <select name="country" id="country" formControlName="country" class="form-control"
                    [(ngModel)]="selectedCountry">
                    <option disabled value="">{{"Country" | translate}}</option>
                    <option [value]="c.name" *ngFor="let c of countries">{{c.name}}</option>
                  </select>
                </div>
                <div class="form-group col-md-6 col-sm-12">
                  <input type="date" id="date" name="date" (change)="onChangeDate($event)" class="form-control">
                </div>
              </div>
              <div class="form-group">
                <textarea class="form-control" name="description" id="description" formControlName="description"
                  cols="30" rows="5" placeholder="{{'About Meeting Details' | translate}}" maxlength="500"></textarea>
              </div>
              <div class="text-center w-100">
                <button class="btn login-btn w-75" (click)="submit()" [disabled]="!form.valid">{{"Submit" |
                  translate}}</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>