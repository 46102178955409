<div class="container mb-5">
  <div class="how-it-works mx-2">
    <h1 class="text-center hiw-title">
      {{"HOW it Works" | translate}}
    </h1>
    <div class="row gy-md-0 gy-4 justify-content-center align-items-center hiw-bar">
      <div class="col-lg-5 col-md-5 col-sm-12 ms-xl-5 text-center">
        <img src="assets/images/infoPages/mobile_login_amico.jpg" alt="mobile-login" class="w-100" style="transform: scaleX(-1);"/>
      </div>
      <div class="col-lg-7 col-md-7 col-sm-12 text-center">
        <div class="red text-center">
          <h3>
            {{"SET UP YOUR ACCOUNT" | translate}} <br>{{"in just a few minutes" | translate}}
          </h3>
        </div>
        <p class="desc mt-3">
          {{"Get started online or through our app, then fill out some brief medical history so we can get to you know better" | translate}}
        </p>
        <div class="row justify-content-center mt-5">
          <div class="col-auto">
            <a href="https://apps.apple.com/mt/app/vera-doctors/id1588552524" target="_blank" class="store-button">
              <span>
                <svg xmlns="http://www.w3.org/2000/svg" width="26.466" height="31.494" viewBox="0 0 26.466 31.494">
                  <path id="Icon_awesome-apple" data-name="Icon awesome-apple" d="M22.409,18.893a6.564,6.564,0,0,1,3.516-5.962,7.555,7.555,0,0,0-5.955-3.136c-2.5-.2-5.224,1.455-6.223,1.455-1.055,0-3.473-1.385-5.372-1.385C4.451,9.928.281,12.994.281,19.23A17.525,17.525,0,0,0,1.294,24.94c.9,2.58,4.148,8.909,7.538,8.8,1.772-.042,3.023-1.259,5.33-1.259,2.236,0,3.4,1.259,5.372,1.259,3.417-.049,6.356-5.8,7.214-8.388a6.97,6.97,0,0,1-4.338-6.462ZM18.429,7.348a6.622,6.622,0,0,0,1.688-5.1A7.456,7.456,0,0,0,15.342,4.7a6.726,6.726,0,0,0-1.8,5.055,5.905,5.905,0,0,0,4.887-2.412Z" transform="translate(-0.281 -2.25)" fill="#fff"/>
                </svg>
              </span>
              <span> <strong class="d-block">{{"App Store" | translate}}</strong></span>
            </a>
          </div>
          <div class="col-auto">
            <a href="https://play.google.com/store/apps/details?id=com.app.veradoctors&hl=en_US&gl=US" target="_blank" class="store-button">
              <span>
                <svg xmlns="http://www.w3.org/2000/svg" width="29.52" height="32.747" viewBox="0 0 29.52 32.747">
                  <path id="Icon_awesome-google-play" data-name="Icon awesome-google-play" d="M20.97,14.989,6.852.832,24.815,11.144,20.97,14.989ZM3.167,0A2.487,2.487,0,0,0,1.779,2.258V30.489a2.487,2.487,0,0,0,1.388,2.258L19.582,16.37Zm27.2,14.432L26.6,12.251l-4.2,4.126L26.6,20.5l3.845-2.181a2.566,2.566,0,0,0-.077-3.889ZM6.852,31.922,24.815,21.61,20.97,17.765Z" transform="translate(-1.779)" fill="#fff"/>
                </svg>
              </span>
              <span> <strong class="d-block">{{"Google Play" | translate}}</strong></span>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="row gy-md-0 gy-4 justify-content-center align-items-center hiw-bar">
      <div class="col-lg-7 col-md-7 col-sm-12 text-center">
        <div class="blue text-center">
          <h3>
            {{"CONNECT YOU WITH" | translate}}<br>{{"our top specialists" | translate}}
          </h3>
        </div>
        <p class="desc mt-3">
          {{"No need to wait for an appointment. Whenever you are near a phone, tablet, or computer, our specialists and specialized nurses are available to help you 24/7" | translate}}
        </p>
      </div>
      <div class="col-lg-5 col-md-5 col-sm-12 ms-xl-5 text-center">
        <img src="assets/images/infoPages/online_doctor_rafiki.jpg" alt="online-doctor" class="w-100" />
      </div>

    </div>

    <div class="row gy-md-0 gy-4 justify-content-center align-items-center hiw-bar">
      <div class="col-lg-5 col-md-5 col-sm-12 ms-xl-5 text-center">
        <img src="assets/images/infoPages/meditation_amico.jpg" alt="meditation" class="w-100" />
      </div>
      <div class="col-lg-7 col-md-7 col-sm-12 text-center">
        <div class="yellow text-center">
          <h3>{{"PUT YOUR MIND" | translate}}<br> {{"at ease" | translate}}</h3>
        </div>
        <p class="desc mt-3">
          {{"Receive live updates on test results, prescription orders, doctor's notes and more. Move forward with confidence, knowing that your Health is our top priority" | translate}}
        </p>
      </div>
    </div>


  </div>
</div>
