import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.css']
})
export class BlogComponent implements OnInit {

  constructor() {
    this.siteCards = [
      {
        image: 'assets/header-blog-photos/saving-planet_168.jpeg',
        header: 'The Role of Telehealth in Saving the Planet - GlobalMed',
        url: 'https://www.globalmed.com/the-role-of-telehealth-in-saving-the-planet/'
      },
      {
        image: 'assets/header-blog-photos/saving-planet_168.jpeg',
        header: 'How to Have Successful Telemedicine Appointments (endocrineweb.com)',
        url: 'https://www.endocrineweb.com/diabetes-telemedicine-clinicians-guide/chapter-2-best-practices-virtual-visits?ap=808&kw=telemedicine%20appointment'
      },
      {
        image: 'assets/header-blog-photos/saving-planet_168.jpeg',
        header: 'Hospital Execs Eye Expanded Virtual Care and Streamlined Platforms in Years Ahead - Amwell',
        url: 'https://business.amwell.com/hospital-survey-virtual-care/'
      },
      {
        image: 'assets/header-blog-photos/saving-planet_168.jpeg',
        header: 'The Future of Healthcare and 8 Trends to Watch at HIMSS 2021 (coxblue.com)',
        url: 'https://www.coxblue.com/the-future-of-healthcare-and-8-trends-to-watch-at-himss-2021/'
      },
      {
        image: 'assets/header-blog-photos/saving-planet_168.jpeg',
        header: 'Nutrition Trends in 2021 - What is the future of nutrition (nutritioapp.com)',
        url: 'https://nutritioapp.com/nutrition-trends-future/'
      },
      {
        image: 'assets/header-blog-photos/saving-planet_168.jpeg',
        header: 'The lowdown on MSG – 123 Nourish Me',
        url: 'https://www.123nourishme.com.au/the-lowdown-on-msg/'
      },
    ]
  }

  siteCards
  ngOnInit(): void {

  }

}
