<div class="container">
    <div class="row">
      <div class="card my-5 mx-2">
        <h1 class="text-center hiw-title my-5">
          {{"Terms of Use" | translate}}
        </h1>
        <div class="card-body desc mb-5" style="color: #000; text-align: justify;">
          <h3 class="pb-3" style="font-weight: 550;">{{"MADDE 1-TARAFLAR" | translate}}</h3>
          <p class="pb-3">
            {{"These terms and conditions of use (“Terms of Use”) govern your use of our online
            interfaces and properties (e.g., websites and mobile applications) owned and controlled by VERADoctors BV,
            including the" | translate}} <a href="https://www.veradoctors.com" target="_blank">
              {{"www.veradoctors.com" | translate}} </a> {{"website (the “Site”) and the services available
            to users through the Site (“Services”). Your compliance with these Terms of Use is a condition to your use of
            the Site. If you do not agree to be bound by the Terms of Use, promptly exit this Site. Please also consult
            our Privacy Policy for a description of our privacy practices and policies, including how we collect and handle
            your personal health information and financial information." | translate}}
          </p>
          <h3 class="pb-3" style="font-weight: 550;">{{"MADDE 2-TANIMLAR" | translate}}</h3>
          <p>
            {{"IF YOU ARE EXPERIENCING A MEDICAL EMERGENCY, YOU SHOULD DIAL THE EMERGENCY NUMBER IMMEDIATELY." |
            translate}} </p>
          <p><span style="font-weight: 550;">{{"2.1." | translate}}</span>
            {{"VERADoctors does not provide any physicians’ or other providers’ services themselves. All of the providers
            are independent of VERADoctors and use the Service as a way to communicate with you. Any information or advice
            received from a provider comes from them alone, and not from VERADoctors. Your interactions with the providers
            via the Service are not intended to take the place of your relationship with your regular health care
            practitioners. Neither VERADoctors nor any of its subsidiaries or affiliates or any third party who may
            promote the Service or provide a link to the Service shall be liable for any professional advice obtained from
            a health care provider via the Service or for any other information obtained on the website. VERADoctors does
            not endorse any specific tests, physicians, medications, products, or procedures that are recommended by
            providers that may use VERADoctors to communicate with you. You acknowledge that your reliance on any
            healthcare providers or information provided by the providers via the Service is solely at your own risk and
            you assume full responsibility for all risk associated herewith." | translate}}
          </p>
          <p><span style="font-weight: 550;">{{"2.2." | translate}}</span>
            {{"VERADoctors does not make any representations or warranties about the training or skill of any healthcare
            providers who provide services via the Service. You are ultimately responsible for choosing your particular
            healthcare provider." | translate}}
          </p>
          <h3>{{"1. Ownership Of The Site And Related Materials" | translate}}</h3>
          <p><span style="font-weight: 550;">{{"2.3." | translate}} </span>{{"All pages within this Site and any material
            made available for download are the property of VERADoctors, or its licensors or suppliers, as applicable." |
            translate}}</p>
          <h3>{{"2. Electronic Communications" | translate}}</h3>
          <p><span style="font-weight: 550;">{{"2.4." | translate}}</span>
            {{"When you use any VERADoctors Service or send emails, text messages, and other communications from your
            desktop or mobile device to us, you are communicating with us electronically. You consent to receive
            communications
            from us electronically. You agree that (a) all agreements and consents can be signed electronically and (b)
            all notices, disclosures, and other communications that we provide to you electronically satisfy any legal
            requirement that such notices and other communications be in writing. Shortcode messaging related information
            is as follows:" | translate}} </p>
          <p><span style="font-weight: 550;">{{"2.5." | translate}}</span>
            {{"A. When you opt-in to the service, we will send you an SMS message to confirm your signup." | translate}}
          </p>
          <p><span style="font-weight: 550;">{{"2.6." | translate}}</span>
            {{"B. After scheduling a consultation on VERADoctors, you will receive SMS messages with regards to your
            consultation status." | translate}}
          </p>
          <p><span style="font-weight: 550;">{{"2.7." | translate}}</span>
            {{"C. You can cancel the SMS service at any time. Just text “STOP” to the shortcode. After you send the SMS
            message “STOP” to us, we will send you an SMS message to confirm that you have been unsubscribed. After this,
            you will no longer receive SMS messages from us. If you want to join again, just sign up as you did the first
            time and we will start sending SMS messages to you again." | translate}}</p>
  
          <p><span style="font-weight: 550;">{{"2.8." | translate}}</span>
            {{"D. If at any time you forget what keywords are supported, just text “HELP” to the shortcode. After you send
            the SMS message “HELP” to us, we will respond with instructions on how to use our service as well as how to
            unsubscribe." | translate}}</p>
          <h3 class="pb-3" style="font-weight: 550;">{{"MADDE 3- KONU VE KAPSAM" | translate}}</h3>
          <p>
            {{"E. If you have any questions regarding privacy, please read our privacy policy" | translate}} </p>
          <h3 class="pb-3" style="font-weight: 550;">{{"MADDE 4- ÜYELİK VE KULLANIM ŞARTLARI" | translate}}</h3>
          <h3>
            {{"3. Site Access, Security and Restrictions; Passwords" | translate}}
          </h3>
          <p><span style="font-weight: 550;">{{"4.1." | translate}}</span>
            {{"If you create a subscriber account for the VERADoctors Site, you agree to complete the registration process
            by providing current, complete, and accurate information as required by VERADoctors. You are responsible for
            all
            activities that occur under your account. In the event access to the Site or a portion, thereof is limited
            requiring a user ID and password (“Protected Areas”), you agree to access Protected Areas using only your user
            ID and password as provided to you by VERADoctors. You agree to protect the confidentiality of your user ID
            and password, and not to share or disclose your user ID or password to any third party. You agree that you are
            fully responsible for all activity occurring under your user ID. Your access to the Site may be revoked by
            VERADoctors at any time with or without cause." | translate}} </p>
          <p><span style="font-weight: 550;">{{"4.2." | translate}}</span>
            {{"You are prohibited from violating or attempting to violate the security of the Site, including, without
            limitation, (a) accessing data not intended for such user or logging onto a server or an account which the
            user is not authorized to access; or (b) attempting to probe, scan or test the vulnerability of a system or
            network or to breach security or authentication measures without proper authorization; or (c) accessing or
            using the Site or any portion thereof without authorization, in violation of these Terms of Use or in
            violation of applicable law. If you are under 18, you may use the VERADoctors Services only with the
            involvement of a parent or guardian." | translate}}
          </p>
          <p><span style="font-weight: 550;">{{"4.3." | translate}}</span>
            {{"You may not use any scraper, crawler, spider, robot, or other automated means of any kind to access or copy
            data on the Site, deep-link to any feature or content on the Site, bypass our robot exclusion headers or other
            measures we may use to prevent or restrict access to the Site." | translate}}
          </p>
          <h3 class="pb-3" style="font-weight: 550;">{{"MADDE 5-KARŞILIKLI HAK VE YÜKÜMLÜLÜKLER" | translate}}</h3>
          <p><span style="font-weight: 550;">{{"5.1. Kullanıcı Hak ve Yükümlülükleri" | translate}}</span>
            {{"Violations of system or network security may result in civil or criminal liability." | translate}}
          </p>
          <h3>{{"4. No Medical Advice or Services" | translate}}</h3>
          <p><span style="font-weight: 550;">{{"5.1.1." | translate}}</span>
            {{"VERADoctors BV provides clinical services for VERADoctors. VERADoctors BV is an independent,
            physician-owned BIG registered medical group based in the Netherlands that provide clinical telehealth
            services. The
            VERADoctors Medical Group provides clinical services via the VERADoctors. platform to customers of VERADoctors
            BV. IF YOU ARE EXPERIENCING A MEDICAL EMERGENCY, YOU SHOULD DIAL THE COUNTRY EMERGENCY NUMBER IMMEDIATELY." |
            translate}}
          </p>
          <p><span style="font-weight: 550;">{{"5.1.2." | translate}}</span>
            {{"THE CONTENT OF THE SITE AND THE SERVICES, INCLUDING WITHOUT LIMITATION, TEXT, COPY, AUDIO, VIDEO,
            PHOTOGRAPHS, ILLUSTRATIONS, GRAPHICS, AND OTHER VISUALS, IS FOR INFORMATIONAL PURPOSES ONLY AND DOES NOT
            CONSTITUTE
            PROFESSIONAL MEDICAL ADVICE, DIAGNOSIS, TREATMENT, OR RECOMMENDATIONS OF ANY KIND. YOU SHOULD ALWAYS SEEK THE
            ADVICE OF YOUR QUALIFIED HEALTH CARE PROFESSIONALS WITH ANY QUESTIONS OR CONCERNS YOU MAY HAVE REGARDING YOUR
            INDIVIDUAL NEEDS AND ANY MEDICAL CONDITIONS. ALL INFORMATION PROVIDED BY VERADOCTORS OR IN CONNECTION WITH ANY
            COMMUNICATIONS SUPPORTED BY VERADOCTORS, INCLUDING BUT NOT LIMITED TO COMMUNICATIONS WITH VERADOCTORS MEDICAL
            EXPERTS IS INTENDED TO BE FOR GENERAL INFORMATIONAL PURPOSES ONLY AND IS IN NO WAY INTENDED TO CREATE A
            PHYSICIAN-PATIENT RELATIONSHIP AS DEFINED BY LAW. THE SITE AND SERVICES ARE NOT A SUBSTITUTE FOR PROFESSIONAL
            MEDICAL DIAGNOSIS OR TREATMENT. RELIANCE ON ANY INFORMATION APPEARING ON THE SITE, WHETHER PROVIDED BY
            VERADOCTORS, ITS CONTENT PROVIDERS, MEDICAL EXPERTS, CLIENTS, VISITORS TO THE SITE, OR OTHERS, IS SOLELY AT
            YOUR OWN RISK. WHILE VERADOCTORS FACILITATES YOUR SELECTION OF, COMMUNICATIONS WITH AND OTHER INTERACTIONS
            WITH PHYSICIANS, VERADOCTORS DOES NOT PROVIDE MEDICAL SERVICES AND THE DOCTOR-PATIENT RELATIONSHIP IS BETWEEN
            YOU AND THE HEALTHCARE PROVIDER YOU SELECT. ALL HEALTHCARE PROVIDERS IDENTIFIED OR ACCESSIBLE ON THE SITE ARE
            INDEPENDENT PROVIDERS AND ARE NOT EMPLOYED BY OR AFFILIATED WITH VERADOCTORS." | translate}}</p>
          <h3>{{"5. License And Access" | translate}}</h3>
          <p><span style="font-weight: 550;">{{"5.1.3." | translate}}</span>
            {{"Subject to your compliance with these Terms of Use and your payment of any applicable fees, VERADoctors or
            its content providers grant you a limited, non-exclusive, non-transferable, non-sublicensable license to
            access
            and make personal and non-commercial use of the VERADoctors Services. This license does not include any resale
            or commercial use of any VERADoctors Service, or its contents; any collection and use of any service listings,
            descriptions, or prices; any derivative use of any VERADoctors Service or its contents; any downloading,
            copying, or other use of account information for the benefit of any third party; or any use of data mining,
            robots, or similar data gathering and extraction tools. All rights not expressly granted to you in these Terms
            of Use are reserved and retained by VERADoctors or its licensors, suppliers, publishers, rightsholders, or
            other content providers. No VERADoctors Service, nor any part of any VERADoctors Service, may be reproduced,
            duplicated, copied, sold, resold, visited, or otherwise exploited for any commercial purpose without express
            written consent of VERADoctors. You may use the VERADoctors Services only as permitted by law. The licenses
            granted by VERADoctors terminate if you do not comply with these Terms of Use. The Contents of the Site,
            including without limitation the files, documents, text, photographs, images, audio, and video, and any
            materials accessed through or made available for use or download through this Site (“Content”) may not be
            copied, distributed, modified, reproduced, published or used, in whole or in part, except for purposes
            authorized or approved in writing by VERADoctors." | translate}}
          </p>
          <h3>{{"6. Accuracy and Integrity of Information" | translate}}</h3>
          <p><span style="font-weight: 550;">{{"5.1.4." | translate}}</span>
            {{"Although VERADoctors attempts to ensure the integrity and accuracy of the Site, it makes no
            representations, warranties, or guarantees whatsoever as to the correctness or accuracy of the Site and
            Content thereon. It is
            possible that the Site could include typographical errors, inaccuracies, or other errors, and that
            unauthorized additions, deletions, and alterations could be made to the Site by third parties. In the event
            that an inaccuracy arises, please inform VERADoctors so that it can be corrected. Information contained on the
            Site may be changed or updated without notice. Additionally, VERADoctors shall have no responsibility or
            liability for information or Content posted to the Site from any non-VERADoctors affiliated third party." |
            translate}} </p>
          <h3>
            {{"7. Links to Other Sites" | translate}}
          </h3>
          <p><span style="font-weight: 550;">{{"5.1.5." | translate}}</span>
            {{"VERADoctors makes no representations whatsoever about any other website that you may access through this
            Site. When you access a non-VERADoctors site, please understand that it is independent of VERADoctors and that
            VERADoctors has no control over the content on that website. In addition, a link to a non-VERADoctors website
            does not mean that Psi MedCrae endorses or accepts any responsibility for the Content, or the use, of the
            linked site. It is up to you to take precautions to ensure that whatever you select for your use or download
            is free of such items as viruses, worms, Trojan horses, and other items of a destructive nature. If you decide
            to access any of the third-party sites linked to this Site, you do this entirely at your own risk." |
            translate}}
          </p>
          <h3>{{"8. User Information" | translate}}</h3>
          <p><span style="font-weight: 550;">{{"5.1.6." | translate}}</span>
            {{"If you submit, upload, post or transmit any health information, medical history, conditions, problems,
            symptoms, personal information, consent forms, agreements, requests, comments, ideas, suggestions,
            information, files, videos, images or other materials to us or our Site (“User Information”), you agree not to
            provide any User Information that (1) is false, inaccurate, defamatory, abusive, libelous, unlawful, obscene,
            threatening, harassing, fraudulent, pornographic, or harmful, or that could encourage criminal or unethical
            behavior, (2) violates or infringes the privacy, copyright, trademark, trade dress, trade secrets or
            intellectual property rights of any person or entity, or (3) contains or transmits a virus or any other
            harmful component. You agree not to contact other site users through unsolicited e-mail, telephone calls,
            mailings or any other method of communication. You represent and warrant to VERADoctors and its Providers that
            you have the legal right and authorization to provide all User Information to VERADoctors and its Providers
            for use as set forth herein and required by VERADoctors and the Provider." | translate}} </p>
          <h3>{{"9. Claims of Copyright Infringement" | translate}}</h3>
          <p><span style="font-weight: 550;">{{"5.1.7." | translate}}</span>
            {{"We disclaim any responsibility or liability for copyrighted materials posted on our site. If you believe
            that your work has been copied in a manner that constitutes copyright infringement, please follow the
            procedures
            set forth below. VERADoctors respects the intellectual property rights of others and expects its users to do
            the same. In accordance with the Digital Millennium Copyright Act (“DMCA”), we will respond promptly to
            notices of alleged infringement that are reported." | translate}}
          </p>
          <h3>{{"10. Disclaimer of Warranties" | translate}}</h3>
          <p><span style="font-weight: 550;">{{"5.1.8." | translate}}</span>
            {{"VERADOCTORS DOES NOT WARRANT THAT ACCESS TO OR USE OF THE SITE WILL BE UNINTERRUPTED OR ERROR-FREE OR THAT
            DEFECTS IN THE SITE WILL BE CORRECTED. THIS SITE, INCLUDING ANY CONTENT OR INFORMATION CONTAINED WITHIN IT OR
            ANY SITE-RELATED SERVICE, IS PROVIDED “AS IS,” WITH ALL FAULTS, WITH NO REPRESENTATIONS OR WARRANTIES OF ANY
            KIND, EITHER EXPRESSED OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY,
            FITNESS FOR A PARTICULAR PURPOSE, QUALITY OF INFORMATION, QUIET ENJOYMENT, AND TITLE/NON-INFRINGEMENT.
            VERADOCTORS DOES NOT WARRANT THE ACCURACY, COMPLETENESS, OR TIMELINESS OF THE INFORMATION OBTAINED THROUGH THE
            SITE." | translate}}
          </p>
          <p><span style="font-weight: 550;">{{"5.1.9." | translate}}</span>
            {{"YOU ASSUME TOTAL RESPONSIBILITY AND RISK FOR YOUR USE OF THIS SITE, SITE-RELATED SERVICES, AND LINKED
            WEBSITES. VERADOCTORS DOES NOT WARRANT THAT FILES AVAILABLE FOR DOWNLOAD WILL BE FREE OF VIRUSES, WORMS,
            TROJAN HORSES, OR OTHER DESTRUCTIVE PROGRAMMING. YOU ARE RESPONSIBLE FOR IMPLEMENTING PROCEDURES SUFFICIENT TO
            SATISFY YOUR NEEDS FOR DATA BACKUP AND SECURITY." | translate}} </p>
          <h3>{{"11. Dispute Resolution" | translate}}</h3>
          <p><span style="font-weight: 550;">{{"5.2. VERADoctors’un Hak ve Yükümlülükleri" | translate}}</span>
          </p>
          <p><span style="font-weight: 550;">{{"5.2.1." | translate}}</span>
            {{"VERADoctors will try to work in good faith to resolve any issue you have with the Site, including services
            ordered or purchased through the Site, if you bring that issue to the attention of our customer service
            department. However, we realize that there may be rare cases where we may not be able to resolve an issue to a
            customer’s satisfaction. In the event we cannot resolve a dispute between us, you agree that all matters
            related to any use or access of the Site, the Services or any other VERADoctors site will be governed by the
            laws of The Netherlands, without regard to its conflicts of laws rules. You hereby waive any objections to
            such jurisdiction or venue. Any claims or controversies arising out of this Agreement, and/or the related use
            and access of the Site, the Services, or any other VERADoctors site, shall be exclusively in the Netherlands."
            | translate}}
          </p>
          <h3>{{"12. Force Majeure" | translate}}</h3>
          <p><span style="font-weight: 550;">{{"5.2.2." | translate}}</span>
            {{"We will not be deemed to be in breach of these terms or liable for any breach of these terms or our privacy
            policy due to any event or occurrence beyond our reasonable control, including without limitation, acts of
            God, terrorism, war, invasion, failures of any public networks, electrical shortages, earthquakes or floods,
            civil disorder, strikes, fire or another disaster." | translate}}
          </p>
          <h3>{{"13. Cancellation Policy" | translate}}</h3>
          <p>
            {{"Cancellation requests are free or charge when you cancel your service up to 24 hours in advance" |
            translate}}
          </p>
          <p>
            {{"Your deposit will be added as a credit to your account." | translate}}
          </p>
          <p>
            {{"If you cancel your service within 24 hours, no refund will be made." | translate}}
          </p>
          <h3>{{"14. Indemnity" | translate}}</h3>
          <p><span style="font-weight: 550;">{{"5.2.3." | translate}}</span>
            {{"You agree to defend, indemnify and hold VERADoctors harmless from and against all third party claims,
            damages, and expenses (including reasonable attorneys’ fees) against or incurred by VERADoctors arising out of
            your
            breach of these Terms of Use or violation of applicable law, or access by anyone accessing the Site using your
            user ID and password." | translate}}
          </p>
          <h3>{{"15. Right to Modify Revisions" | translate}}</h3>
          <p><span style="font-weight: 550;">{{"5.2.4." | translate}}</span>
            {{"General VERADoctors reserves the right to modify these Terms of Use at any time, effective upon posting.
            Any use of this website after such changes will be deemed an acceptance of those changes. You agree to review
            the Terms of Use each time you access this website so that you may be aware of any changes to these Terms.
            VERADoctors reserves the right, in its sole discretion, to terminate your access to all or part of this Site,
            with or without cause, and with or without notice. In the event that any of the Terms of Use are held by a
            court or other tribunal of competent jurisdiction to be unenforceable, such provisions shall be limited or
            eliminated to the minimum extent necessary so that these Terms of Use shall otherwise remain in full force and
            effect. These Terms of Use constitute the entire agreement between VERADoctors and you pertaining to the
            subject matter hereof. In its sole discretion, VERADoctors may from time to time revise these Terms of Use by
            updating this posting. You should, therefore, periodically visit this page to review the current Terms of Use,
            so you are aware of any such revisions to which you are bound. Certain provisions of these Terms of Use may be
            superseded by expressly designated legal notices or terms located on particular pages within this Site." |
            translate}}
          </p>
          <p><span style="font-weight: 550;">{{"5.2.5." | translate}}</span>
            {{"VERADoctors, işbu platform ve uzantısında mevcut her tür hizmet, ürün, platformu
            kullanma koşulları ile platformda sunulan bilgileri önceden bir ihtara gerek olmaksızın
            değiştirme, yeniden organize etme, platformu durdurma hakkını saklı tutar. Değişiklikler,
            platformda yayım anında yürürlüğe girer. Kullanıcılar, platformun kullanımı ya da platforma
            giriş ile bu 5 değişiklikleri de kabul etmiş sayılır. Değişiklikler, VERADoctors tarafından
            belirlenen mecra üzerinden kullanıcılara duyurulur." | translate}}
          </p>
          <p><span style="font-weight: 550;">{{"5.2.6." | translate}}</span>
            {{"VERADoctors platform kapsamında verdiği hizmetlerin sürekli aktif ve erişilebilir
            olacağına dair hiçbir garanti vermemektedir. VERADoctors özellikle yargı ve sair yetkili
            resmi merci karar ve uygulamaları, mücbir sebepler, üçüncü kişilerin sebep olduğu
            durumlar, Internet bağlantı hizmeti sağlayan kuruluşlardan kaynaklanan aksaklıklar ve
            gecikmeler ve benzeri dış etkenler, yanlış kullanım, teknik arıza ve sair aksaklıklar, tamir
            çalışmaları veya diğer yönlendirmeler sonucu meydana gelen aksaklıklar konusunda hiçbir
            şekilde sorumluluk kabul etmez." | translate}}
          </p>
          <p><span style="font-weight: 550;">{{"5.2.7." | translate}}</span>
            {{"VERADoctors gerektiğinde, ağın işletim güvenliğinin tehlikede olması, ağa erişimin
            devamlılığı, ağda, yazılımda veya kayıtlı dosyalarda meydana gelebilecek arızaların önüne
            geçebilmek, muhtemel aksaklıkları engellemek veya etkisini azaltmak adına ve gerekli
            gördüğü diğer durumlarda platforma erişimi sınırlandırabilir veya durdurabilir." | translate}}
          </p>
          <h3 class="pb-3" style="font-weight: 550;">{{"MADDE 6- HİZMETLER" | translate}}</h3>
          <p>
            {{"VERADoctors, !VERADoctors” vasıtasıyla kullanıcılara işbu sözleşme ile kapsamı ve
            içeriği belirlenen hizmetleri sağlayacaktır. Söz konusu standart hizmetler ücretli olarak
            sağlanacaktır. VERADoctors tarafından bu platform ile kullanıcılara, kullanıcıların
            birbirleriyle iletişim kurabileceği, yazılı ve görsel içerikleri kullanıcı portallarında
            paylaşabileceği, kullanıcıların, VERADoctors tarafından platform kapsamında satışa
            sunulan ürünleri satın alabileceği mecraların hazırlanması ve işbu sözleşme ile belirlendiği
            şekil ve koşullarla kullanıcılara iletilmesi hizmetlerini sunacaktır. VERADoctors, sunduğu
            hizmetlerin içeriğinde istediği zaman değişiklik yapma hakkını saklı tutar." | translate}}
          </p>
          <p><span style="font-weight: 550;">{{"Online Görüntülü Görüşme Hizmeti" | translate}}</span>
          </p>
          <p>
            {{"Online görüntülü görüşme ile kullanıcılar sağlık profesyonelleri ile görüşebilir, danışabilir
            veya sağlık profesyonellerinden ikinci görüş alabilirler. VERADoctors herhangi bir sağlık
            hizmeti sağlayıcısını özellikle tavsiye etmemekte ve desteklememektedir. Bu hizmet, acil
            olan tıbbi sorunlar için tasarlanmamıştır, acil olmayan tıbbi sorunları için tasarlanmıştır. Acil
            bir tıbbi durum varsa bu hizmeti kullanmamalısınız. Acil ve hayati tehlike arz eden her
            hangi bir tıbbi durum için derhal 112'yi aramalısınız. Online görüntülü görüşme hizmeti,
            kullanıcıların hastalıkları, teşhis ve tedavi süreçleri ile ilgili sorularını sağlık
            profesyonellerine danışabilecekleri ve onlardan ilgili konularda danışmanlık alabileceği bir
            hizmettir. İlgili hizmet kapsamında, sağlık profesyonellerinin müsaitliği doğrultusunda canlı
            ve görüntülü danışmanlık hizmeti kapsamında sadece danışmanlık verilir, tanı ve teşhis
            konulmaz, tedavi önerisinde bulunulmaz ve reçete düzenlenmez. Bu hizmet kapsamında
            yapılan görüşmeler kesinlikle yüz yüze muayenenin bir alternatifi değildir. VERADoctors
            Online Görüntülü Görüşme hizmeti, bir Sağlık Profesyonelinden yüz yüze muayene ile
            alınan tıbbi hizmetten faklılık göstermektedir. VERADoctors platformunu kullanan Uzman
            Sağlık Profesyonelleri sizleri şahsen muayene ederek fiziki durumunuzu değerlendirebilme
            imkanına sahip değillerdir. Sağlık Profesyonelleri değerlendirmelerini sizden gelen bilgiler
            üzerinden yaparlar. Bu nedenle fiziki muayene esnasında görebilecekleri ve onların tanı ve
            düşüncelerini etkileyebilecek bazı kritik noktaları değerlendiremeyebilirler. VERADoctors
            platformundaki Sağlık Profesyonelleri bu riskleri en aza indirgeyebilmek ve durumu tam
            olarak anlamak adına ihtiyaç duydukları bilgiler için sizlerle sürekli irtibat halinde olsalar da
            bahsedilen risk her zaman mevcuttur." | translate}}
          </p>
          <p><span style="font-weight: 550;">{{"Hizmet Detayı" | translate}}</span></p>
          <p>
            {{"VERADoctors Online Görüntülü Görüşme hizmeti doktorunuzla yapacağınız yüz yüze
            muayenenin bir alternatifi değildir. VERADoctors Görüntülü Görüşme hizmeti fiziki
            muayenenin önemini vurgular ve tavsiye eder. VERADoctors Online Görüntülü Görüşme
            hizmetlerinden yararlanmaya karar verdiğinizde hizmetin kapsamını, sınırlarını ve risklerini
            kabul etmiş olursunuz. VERADoctors Online Görüntülü Görüşme hizmetlerinden
            yararlanarak uzaktan istişare ile tıbbi görüş aldığınızda bu sözleşmedeki koşulları bilerek
            kabul etmiş olursunuz. Kullanıcıların alacağı tıbbi tavsiyeler sınırlıdır ve VERADoctors
            platformundaki Sağlık Profesyonellerine iletilen bilgi ve veriler doğrultusunda geçerlidir.
            VERADoctors Online Görüntülü Görüşme hizmetinden yararlanırken yukarıdaki durumların
            varlığının bilginiz dahilinde olduğunu ve riskleri kabul ettiğinizi ayrıca VERADoctors Online
            Görüntülü Görüşme hizmetinin sağlık durumunuzla ilgili herhangi bir sonucu veya tedaviyi
            sözlü ve yazılı olarak garanti etmediğini onaylamış olursunuz. VERADoctors Online
            Görüntülü Görüşme hizmeti üyelik hesabınızda bulunan ve tarafınızca beyan edilmiş olan
            üyelik hesabınızda bulunan ve tarafınızca doldurulmuş olan formlardaki her türlü bilginin
            güncelliği sizin sorumluluğunuzdadır. Sağlık Profesyonellerinin her türlü
            değerlendirmelerini bu bilgilerin güncelliği esasına göre yapmaktadırlar." | translate}}
          </p>
          <p><span style="font-weight: 550;">{{"Sorumluluk Sınırı" | translate}}</span></p>
          <p>
            {{"VERADoctors Online Görüntülü Görüşme hizmeti aracılığıyla başka bir şahıs veya
            firmadan alınan hizmetlerle ilgili olarak VERADoctors sorumlu tutulamaz. VERADoctors,
            etik gereklilikler doğrultusunda ilgili sorunların oluşmaması ve oluştuğu durumda sorunun
            çözümü için gerekli çabayı harcamakla birlikte hizmetten doğacak sorun, dolaylı veya
            doğrudan hiçbir zarar, şikayet ve hatalarla ilgili yasal sorumluluk üstlenmez." | translate}}
          </p>
          <p><span style="font-weight: 550;">{{"İptal ve Değişiklik Koşulları" | translate}}</span></p>
          <p>
            {{"Sağlık profesyonelleri programını sana göre düzenliyor. Herhangi bir saat değişikliği, tarih
            değişikliği ve iptal durumunda onların mağdur olmaması için belirlemiş olduğumuz iptal ve
            değişiklik koşullarını aşağıda bulabilirsin." | translate}}
          </p>
          <p><span style="font-weight: 550;">{{"Hizmetten 24 Saat Öncesine Kadar" | translate}}</span></p>
          <p>
            {{"İptal isteği ücretsiz gerçekleştirilir ve görüşme bedeli tarafınıza kredi olarak eklenir." | translate}}
          </p>
          <p><span style="font-weight: 550;">{{"Hizmete 24 Saatten Az Süre Kaldığında" | translate}}</span></p>
          <p>
            {{"İptal durumunda ise iade gerçekleştirilmez." | translate}}
          </p>
          <h3 class="pb-3" style="font-weight: 550;">{{"MADDE 7- GİZLİLİK" | translate}}</h3>
          <p><span style="font-weight: 550;">{{"7.1." | translate}}</span>
            {{"Platform üzerindeki çeşitli formların doldurulması ve oylamaların yapılması suretiyle
            kullanıcıların kendileriyle ilgili bir takım kişisel bilgileri (adı/soyadı, telefon, adres veya e-posta
            adresleri gibi) VERADoctors'a vermeleri gerekmektedir. Kişisel bilgiler, gerektiğinde
            kullanıcıyla temas kurmak için de kullanılacaktır. Kullanıcı işbu sözleşmeyi imzalamakla bu
            kullanıma onay vermiştir." | translate}}
          </p>
          <p><span style="font-weight: 550;">{{"7.2." | translate}}</span>
            {{"Kullanıcıdan istenen kişisel bilgiler, kullanıcıya “Hizmet” verilebilmesi için gerekli olup,
            bu kişisel bilgiler VERADoctors tarafından veya yetkilendirdiği üçüncü parti firmalar
            tarafından saklanır. VERADoctors platformu VERADoctors tarafından sağlanmaktadır.
            Platform içerisindeki bazı servisler yetkilendirilmiş üçüncü parti firma aracılığı ile
            sağlanmakta olup, Platformdan faydalanmak isteyen kullanıcılar, hizmetlerin sunumu için
            gereken bilgilerinin söz konusu üçüncü parti firma/lar ile paylaşılacağını kabul ederler." | translate}}
          </p>
          <p><span style="font-weight: 550;">{{"7.3." | translate}}</span>
            {{"VERADoctors platformuna üye olanlar, işbu Kullanım Koşulları’nı ve Gizlilik
            Koşulları’nı kabul etmiş bulunmakta ve burada belirtilen hükümlerin kendilerini bağladığını
            kabul ve beyan etmiş sayılırlar." | translate}}
          </p>
          <p><span style="font-weight: 550;">{{"7.4." | translate}}</span>
            {{"VERADoctors işbu sözleşmede aksi belirtilmedikçe kişisel bilgilerden herhangi birini
            VERADoctors’un işbirliği içinde olmadığı üçüncü kişilere açıklamayacaktır. Aşağıda
            belirtilen sınırlı durumlarda Albert Health, işbu sözleşme hükümleri dışında kullanıcılara ait
            bilgileri üçüncü kişilere açıklayabilir. Bu durum sınırlı sayıda olmak üzere;" | translate}}
          </p>
          <p><span style="font-weight: 550;">{{"7.4.1." | translate}}</span>
            {{"Yetkili hukuki otoriteler tarafından çıkarılan ve yürürlükte olan kanun, kanun
            hükmünde kararname, yönetmelik, vb. gibi hukuk kurallarının getirdiği zorunluluklara
            uyulmasının," | translate}}
          </p>
          <p><span style="font-weight: 550;">{{"7.4.2." | translate}}</span>
            {{"Yetkili idari ve adli otorite tarafından usulüne göre yürütülen bir araştırma veya
            soruşturmanın yürütümü amacıyla üyelerle ilgili bilgi talep edilmesinin," | translate}}
          </p>
          <p><span style="font-weight: 550;">{{"7.4.3." | translate}}</span>
            {{"VERADoctors 7.1. hükmü saklı tutulmak koşulu ile, kişisel bilgileri kesinlikle özel ve
            gizli tutmayı, bunu bir sır saklama yükümü olarak addetmeyi ve gizliliğin sağlanması ve
            sürdürülmesi, gizli bilginin tamamının veya herhangi bir kısmının kamu alanına girmesini
            veya yetkisiz kullanımını veya üçüncü bir kişiye ifşasını önlemek için gerekli tüm tedbirleri
            almayı ve gerekli özeni göstermeyi beyan ve taahhüt etmektedir. VERADoctors’un gerekli
            bilgi güvenliği önlemlerini almasına karşın platforma ve sisteme yapılan saldırılar
            sonucunda gizli bilgilerin zarar görmesi veya üçüncü kişilerin eline geçmesi durumunda
            VERADoctors’un herhangi bir sorumluluğu olmayacaktır." | translate}}
          </p>
          <h3 class="pb-3" style="font-weight: 550;">{{"MADDE 8- UYGULANACAK HUKUK VE YETKİLİ MAHKEME" | translate}}</h3>
          <p>
            {{"İşbu Sözleşme'nin uygulanmasında ve yorumlanmasında Türkiye Cumhuriyeti Kanunları
            uygulanacaktır. İşbu Sözleşme'den dolayı doğan veya doğabilecek her türlü ihtilafın
            hallinde İstanbul Anadolu Mahkemeleri ve İcra Daireleri yetkilidir." | translate}}
          </p>
          <h3 class="pb-3" style="font-weight: 550;">{{"MADDE 9- FİKRİ MÜLKİYET HAKLARI" | translate}}</h3>
          <p><span style="font-weight: 550;">{{"9.1." | translate}}</span>
            {{"Platform’un (genel görünüm, tasarım, metin, imge, logo, ikon, yazılı, elektronik, grafik
            veya makinede okunabilir şekilde sunulan teknik verileri, VERADoctors markası,
            uygulanan iş metodu ve iş modeli, yazılım kodu ve diğer kodlar da dahil ve fakat bunlarla
            sınırlı olmamak kaydıyla) tüm unsurları VERADoctors’a aittir ve/veya VERADoctors
            tarafından üçüncü bir kişiden alınan telif hakkı altında kullanılmaktadır. Fikir ve Sanat
            Eserleri Kanunu kapsamında korunan VERADoctors’a ait tüm bu unsurlar önceden izin
            alınmadan ve kaynak gösterilmeden değiştirilemez, kopyalanamaz, çoğaltılamaz, başka
            bir lisana çevrilemez, yeniden yayımlanamaz, yeniden satılamaz, paylaşılamaz,
            dağıtılamaz, sergilenemez, Kullanıcı Sözleşmesi kapsamı dışında kullanılamaz veya
            bunlardan türemiş çalışmalar yapılamaz veya hazırlanamaz, aksi şekilde davranılması
            halinde, sorumlu kişi/kişiler VERADoctors’un uğradığı zararı ve/veya lisans verenler de
            dahil üçüncü kişilerin uğradıkları zararlardan dolayı VERADoctors’tan talep edilen tazminat
            miktarını, mahkeme masrafları ve avukatlık ücreti de dahil olmak üzere karşılamakla
            yükümlü olacaklardır." | translate}}
          </p>
          <p><span style="font-weight: 550;">{{"9.2." | translate}}</span>
            {{"Kullanıcıların, platformda yayınlanmak üzere sunduğu içeriğin kendilerine ait olduğu
            veya Fikir ve Sanat Eserleri Kanunu çerçevesinde kullanma haklarının olduğu kabul edilir.
            Kullanıcılar, kendilerine ait olmayan içeriği ancak kanunda belirtilen usule uygun olarak ve
            atıfta bulunmak şartıyla yayınlayabilir. Aksi halde, 3. Kişiler tarafından talep edilen
            tazminatlar dahil, VERADoctors’un uğrayacağı zararlardan içeriği yükleyen üye sorumlu
            olacaktır." | translate}}
          </p>
          <h3 class="pb-3" style="font-weight: 550;">{{"MADDE 10- SÖZLEŞME DEĞİŞİKLİKLERİ" | translate}}</h3>
          <p>
            {{"VERADoctors günün koşullarına ve ilgili mevzuatlardaki değişikliklere bağlı olarak, işbu
            Kullanıcı Sözleşmesi’ni veya herhangi bir hükmünü, uygun göreceği herhangi bir
            zamanda, platformda ilan etmek suretiyle değiştirebilir. İşbu Kullanıcı Sözleşmesi’nin
            değişen hükümleri, ilan edildikleri tarihte veya önceden belirtilen geçerlilik tarihi olması
            halinde ise belirtilen tarihinde geçerlilik kazanacak; geri kalan hükümler, aynen yürürlükte
            kalarak hüküm ve sonuçlarını doğurmaya devam edecektir. İşbu Kullanıcı Sözleşmesi,
            kullanıcıların tek taraflı beyanları ile değiştirilemez." | translate}}
          </p>
          <h3 class="pb-3" style="font-weight: 550;">{{"MADDE 11- MÜCBİR SEBEPLER" | translate}}</h3>
          <p><span style="font-weight: 550;">{{"11.1." | translate}}</span>
            {{"Mücbir sebep terimi, doğal afet, isyan, savaş, grev, VERADoctors’un gerekli bilgi
            güvenliği önlemleri almasına karşın Platform, Portal ve sisteme yapılan saldırılar da dahil
            ve fakat bunlarla sınırlı olmamak kaydıyla VERADoctor’un makul kontrolü haricinde
            gelişen ve VERADoctors’un gerekli özeni göstermesine rağmen önleyemediği
            kaçınılamayacak olaylar olarak yorumlanacaktır." | translate}}
          </p>
          <p><span style="font-weight: 550;">{{"11.2." | translate}}</span>
            {{"Kullanıcıların, platformda yayınlanmak üzere sunduğu içeriğin kendilerine ait olduğu
            veya Fikir ve Sanat Eserleri Kanunu çerçevesinde kullanma haklarının olduğu kabul edilir.
            Kullanıcılar, kendilerine ait olmayan içeriği ancak kanunda belirtilen usule uygun olarak ve
            atıfta bulunmak şartıyla yayınlayabilir. Aksi halde, 3. Kişiler tarafından talep edilen
            tazminatlar dahil, VERADoctors'un uğrayacağı zararlardan içeriği yükleyen üye sorumlu
            olacaktır." | translate}}
          </p>
          <h3 class="pb-3" style="font-weight: 550;">{{"MADDE 12- KAYITLARIN GEÇERLİLİĞİ" | translate}}</h3>
          <p>
            {{"Kullanıcı, işbu Sözleşme'den doğabilecek ihtilaflarda VERADoctors'un kendi veri
            tabanında, sunucularında tuttuğu elektronik ve sistem kayıtlarının, ticari kayıtlarının, defter
            kayıtlarının, mikrofilm, mikro fiş ve bilgisayar kayıtlarının muteber bağlayıcı, kesin ve
            münhasır delil teşkil edeceğini ve bu maddenin HMK 193. madde anlamında delil
            sözleşmesi niteliğinde olduğunu kabul, beyan ve taahhüt etmektedir." | translate}}
          </p>
          <h3 class="pb-3" style="font-weight: 550;">{{"MADDE 13- SÖZLEŞMENİN FESHİ" | translate}}</h3>
          <p>
            {{"İşbu Kullanıcı Sözleşmesi, kullanıcı platforma üye olduğu sürece yürürlükte kalacak ve
            taraflar arası hüküm ve sonuçlarını doğurmaya devam edecek; kullanıcının üyelik
            süresinin dolması veya geçici veya kalıcı olarak üyeliğinin durdurulması hallerinde sona
            ermiş sayılacaktır. Platform kullanıcılarının işbu Kullanıcı Sözleşmesi'ni ve/veya Platform
            içinde yer alan kullanıma, üyeliğe ve Hizmet'lere ilişkin benzeri kuralları ihlal etmeleri
            durumunda ve özellikle aşağıda sayılan hallerde, VERADoctors Kullanıcı Sözleşmesi'ni
            tek taraflı olarak feshedebilecek ve kullanıcılar, fesih sebebiyle, VERADoctors’un uğradığı
            tüm zararları tazmin etmekle yükümlü olacaktır; i- Kullanıcının, herhangi bir yöntem
            kullanarak, sistemin işleyişini manipüle edecek davranışlar ile gerçek dışı işlemlerde
            bulunması, ii- Kullanıcının kendisi için oluşturulmuş kullanıcı profilini başkasına devretmesi
            veya kullanıma açması, iii- Kullanıcının, üçüncü kişilerin haklarına tecavüz eden ve/veya
            etme tehlikesi bulunan fillerde bulunması." | translate}}
          </p>
          <h3 class="pb-3" style="font-weight: 550;">{{"MADDE 14- TEBLİGAT ADRESLERİ" | translate}}</h3>
          <p><span style="font-weight: 550;">{{"14.1." | translate}}</span>
            {{"VERADoctors platformu üyelerden peşinen posta adreslerini istemektedir. Ancak
            kullanıcıların VERADoctors bildirdiği elektronik posta adresi, bu sözleşme ile ilgili olarak
            yapılacak her türlü bildirim için yasal adresin isteneceği elektronik posta olarak kabul
            edilmektedir." | translate}}
          </p>
          <p><span style="font-weight: 550;">{{"14.2." | translate}}</span>
            {{"Taraflar, mevcut elektronik postalarındaki değişiklikleri yazılı olarak diğer tarafa 3 (üç)
            gün içinde bildirmedikçe, eski elektronik postalara yapılacak isteklerin geçerli olacağını ve
            kendilerine yapılmış sayılacağını kabul etmiştir." | translate}}
          </p>
          <p><span style="font-weight: 550;">{{"14.3." | translate}}</span>
            {{"Yine VERADoctors'un kullanıcıların kayıtlı elektronik posta adresini kullanarak
            yapacağı her türlü bildirimin elektronik postanın VERADoctors tarafından yollanmasından
            1 (bir) gün sonra üyelere ulaştığı kabul edilecektir." | translate}}
          </p>
          <h3 class="pb-3" style="font-weight: 550;">{{"MADDE 15- YÜRÜRLÜLÜK" | translate}}</h3>
          <p><span style="font-weight: 550;">{{"15.1." | translate}}</span>
            {{"Kullanıcı, işbu sözleşmede yer alan maddelerin tümünü okuduğunu, anladığını,
            kabul ettiğini ve kendisiyle ilgili olarak verdiği bilgilerin doğruluğunu onayladığını beyan,
            kabul ve taahhüt etmiştir." | translate}}
          </p>
          <p><span style="font-weight: 550;">{{"15.2." | translate}}</span>
            {{"İşbu Sözleşme, 16 (onaltı) maddeden oluşmakta olup kullanıcının elektronik olarak
            onay verilmesi ile karşılıklı olarak kabul edilerek yürürlüğe girmiştir." | translate}}
          </p>
          <p>
            {{"Yukarıdaki bilgileri eksiksiz olarak okudum ve anladım. VERADoctors Online Sağlık
            Hizmetlerinden nasıl yararlanabileceğim, başvuru, kabul süreci ile işleyiş hakkında
            bilgilendirildim. Verilecek hizmetleri kabul ediyorum." | translate}}
          </p>
        </div>
      </div>
    </div>
  </div>
