import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { share } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class localStorageService {
  public onSubjectName = new Subject<any>();
  public onSubjectRole = new Subject<any>();
  public onSubjectImage = new Subject<any>();

  constructor() {}

  saveImageLocalStorage(imageUrl: string): void {
    localStorage.setItem('image', imageUrl);
  }

  clearLocalStorage(): void {
    const languageValue = localStorage.getItem('language');
    const slanguageValue = localStorage.getItem('slanguage');

    localStorage.clear();

    if (languageValue !== null) {
      localStorage.setItem('language', languageValue);
      localStorage.setItem('slanguage', slanguageValue);
    }
  }
}
