<div class="container">
  <div class="container">
    <div class="card my-5 mx-2 w-100">
      <div class="card-header text-center">
        <h1>{{"TRAVEL" | translate}}</h1>
        <h2>{{"with VERADoctors" | translate}}</h2>
      </div>
      <div class="card-body px-5 col-md-12 col-sm-12 w-100 text-center" style="color: #000; text-align: justify;">
        <p class="desc">
          {{"Getting sick is never fun, and it is even worse when you are on the road. It’s a hassle to find care in an unfamiliar place, which can add unnecessary stress to your vacation, family reunion or business trip. Telemedicine offers a way to bring easy access to quality care options along with you, wherever you travel." | translate}}
        </p>
        <div class="row mt-3 text-left">
          <div>
            <h3 class="middle-header my-3">{{"WHAT IS TELEMEDICINE?" | translate}}</h3>
          <p class="desc">
            {{"It’s a way to make sure your healthcare is covered, even while you’re traveling outside your home city or country. VERADoctors is equipped with doctors who can diagnose and treat patient symptoms, as well as prescribe medications for a wide array of medical issues." | translate}}
          </p>
          </div>
          <div>
            <h3 class="middle-header my-3">{{"OUR TRAVEL MEMBERSHIP" | translate}}</h3>
            <p class="desc">{{"Feeling sick on your trip?" | translate}}</p>
            <p class="desc">{{"Let VERADoctors take care of you by subscribing to our Travel plan before your trip." | translate}}</p>
            <p class="desc">{{"Gain access to all that we have to offer at a low fixed-rate cost per day." | translate}}</p>
            <br>
            <p class="desc">{{"We are a HIPAA and GDPR compliant healthcare company, with a goal to help travelers avoid unnecessary and expensive visits during their vacation or business trip." | translate}}</p>
          </div>

          <div class="row text-left button-up">
            <div class="blue-print" [routerLink]="'/membership'"><h3 class="text-white">{{"Travel Membership" | translate}}</h3>
            </div>
          </div>
          <br>
        </div>
      </div>
    </div>
  </div>
</div>


