<div class="container">
  <div class="row w-100">
    <div class="card mt-5 w-100">
      <div class="card-body px-5" style="color: #000; text-align: justify;">
        <div class="text-center mb-5">
          <h4 style="font-weight: 900;">{{"Blog" | translate}}</h4>
          <h5 style="font-weight: 600;">{{"The future of healthcare, telemedicine, and nutrition" | translate}}</h5>
        </div>
        <div class="col-md-8 col-sm-12 d-flex flex-column mx-auto my-1" *ngFor="let i of siteCards">
          <div class="card bg-light text-dark d-flex flex-row" style="border-radius: 10px; border: 1px solid #fff;">
            <div>
              <img [src]="i.image" alt="" class="m-1" style="width: 100px; height: 100px; border-radius: 10px; border: 1px solid #fff;">
            </div>
            <div class="card-body py-auto">
              <p class="my-auto">
                <a href="{{i.url}}" target="_blank">
                  {{i.header}}
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
