import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { url } from 'src/config/http';


@Injectable({
  providedIn: 'root'
})
export class SendMailService {

  constructor(private http: HttpClient) { }

  requestAMeeting(form){
    const options = {
      observe: 'response' as 'response',
    };
    return this.http.post(url + `/utilities/send-email`, form, options);
  }
}
